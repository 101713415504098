import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  Box,
  Card,
  CardHeader,
  CardContent,
  FormControlLabel,
  CircularProgress,
  RadioGroup,
  Radio,
  Switch,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { UserApi, Group, RoleType } from '../../api/UserApi';
import { GroupApi } from '../../api/GroupApi';
import { useAuthContext } from '../../context/AuthContext';
import { useNotificationContext } from '../../context/NotificationContext';
import ActionConfirmationModal, {
  ActionModalProps,
} from '../../components/ActionConfirmationModal';

const StyledSwitch = styled(Switch)({
  '& .MuiSwitch-switchBase': {
    color: 'red',
    '& + .MuiSwitch-track': {
      backgroundColor: '#ff8b8b',
    },
    '&.Mui-checked': {
      color: 'green',
      '& + .MuiSwitch-track': {
        backgroundColor: 'lightgreen',
      },
    },
  },
});

const rockerEmployeeRoles: Array<RoleType> = [
  RoleType.RockerAgent,
  RoleType.RockerAdmin,
  RoleType.SuperAdmin,
];

const Agent = () => {
  const [user, setUser] = useState<any | null>(null);
  const [groups, setGroups] = useState<Group[]>([]);
  const [superAdminModal, setSuperAdminModal] = useState<ActionModalProps>({
    text: '',
  });
  const [showSuperAdminModal, setShowSuperAdminModal] =
    useState<Boolean>(false);
  useEffect(() => {
    if (!showSuperAdminModal) {
      setSuperAdminModal({ text: '' });
    }
  }, [showSuperAdminModal]);
  const { userId } = useParams<{ userId: string }>();
  const { setNotification } = useNotificationContext();
  const userApi = new UserApi();
  const groupApi = new GroupApi();

  const {
    user: { merchants, isMerchantAdmin, isSuperAdmin },
    merchantId,
  } = useAuthContext();

  const fetchUser = async () => {
    const {
      groups,
      ownMerchantId,
      isSuperAdmin,
      properRoles,
      inactive,
      ...rest
    } = await userApi.getUser(userId);

    setUser({
      ...rest,
      merchant: merchants.find(({ id }) => id === ownMerchantId)?.name,
      groupId: groups[0] || '',
      isSuperAdmin: Boolean(
        properRoles.find(({ $type }) => $type === RoleType.SuperAdmin),
      ),
      isRockerEmployee: Boolean(
        properRoles.find(({ $type }) =>
          (rockerEmployeeRoles as string[]).includes($type),
        ),
      ),
      active: !inactive,
    });
  };

  const fetchGroups = async () => {
    if (isMerchantAdmin) {
      const groups = await userApi.getGroupsForMerchant();
      setGroups(groups);
    } else {
      const groups = await userApi.getGroups();
      setGroups(groups);
    }
  };

  const handleSuperAdminPermission = async (grantSuperAdmin: Boolean) => {
    const role = {
      merchantId,
      $type: RoleType.SuperAdmin,
    };
    try {
      setUser({ ...user, isSuperAdmin: grantSuperAdmin });
      if (grantSuperAdmin) {
        await userApi.setUserRole(userId, role);
      } else {
        await userApi.deleteUserRole(userId, role);
      }
    } catch (e) {
      setUser({ ...user, isSuperAdmin: !grantSuperAdmin });
      setNotification({
        severity: 'error',
        message: 'Something went wrong. please try again or contact support',
      });
    }
  };

  const handleAgentActivationChange = async (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    try {
      setUser({ ...user, active: event.target.checked });
      if (event.target.checked) {
        isSuperAdmin
          ? await userApi.activateUser(userId)
          : await userApi.activateUserForMerchant(userId);
      } else {
        isSuperAdmin
          ? await userApi.inactivateUser(userId)
          : await userApi.inactivateUserForMerchant(userId);
      }
    } catch (e) {
      setUser({ ...user, active: !event.target.checked });
      setNotification({
        severity: 'error',
        message: 'Something went wrong. please try again or contact support',
      });
    }
  };

  useEffect(() => {
    fetchGroups();
    if (userId) fetchUser();
  }, [userId]);

  const handleGroupChange = async (e: any) => {
    const { value } = e.target;
    const oldGroup = user.groupId;
    const newGroup = value;
    try {
      if (isMerchantAdmin) {
        await groupApi.putGroupMemberForMerchant(newGroup, userId);
        if (oldGroup) {
          await groupApi.deleteGroupMemberForMerchant(oldGroup, userId);
        }
      } else {
        await groupApi.putGroupMember(newGroup, userId);
        if (oldGroup) {
          await groupApi.deleteGroupMember(oldGroup, userId);
        }
      }
      fetchUser(); // Fetch the new group that the user is member of
    } catch (e) {
      setNotification({
        severity: 'error',
        message: 'Something went wrong. please try again or contact support',
      });
    }
  };

  return (
    <Box sx={{ maxWidth: 400 }}>
      {!user ? (
        <CircularProgress />
      ) : (
        <>
          <Card>
            <CardContent>
              <div>
                <b>Email:</b> {user.email}
              </div>
              <div>
                <b>Merchant:</b> {user.merchant}
              </div>
            </CardContent>
          </Card>
          <Card style={{ marginTop: 25 }}>
            {user.active && (
              <CardContent>
                <CardHeader title="Group:*" sx={{ pl: 0 }} />
                <RadioGroup defaultValue={user?.groupId}>
                  {groups.map(({ id, name }) => (
                    <FormControlLabel
                      key={name}
                      control={
                        <Radio
                          color="primary"
                          value={id}
                          name="groupId"
                          onChange={handleGroupChange}
                        />
                      }
                      label={name}
                    />
                  ))}
                </RadioGroup>
              </CardContent>
            )}
          </Card>
          {isSuperAdmin && user.isRockerEmployee && (
            <Card style={{ marginTop: 25 }}>
              <CardContent>
                <b>SuperAdmin:</b>
                <StyledSwitch
                  color="primary"
                  checked={user.isSuperAdmin}
                  onChange={() => {
                    setSuperAdminModal({
                      text: `${user.isSuperAdmin ? 'Revoke' : 'Grant'}  ${
                        RoleType.SuperAdmin
                      } role for the user`,
                      action: async () => {
                        await handleSuperAdminPermission(!user.isSuperAdmin);
                        setShowSuperAdminModal(false);
                      },
                    });
                    setShowSuperAdminModal(true);
                  }}
                />
              </CardContent>
            </Card>
          )}
          {superAdminModal.text && (
            <ActionConfirmationModal
              {...superAdminModal}
              close={() => setShowSuperAdminModal(false)}
            />
          )}
          <Card style={{ marginTop: 25 }}>
            <CardContent>
              <b>Active:</b>
              <StyledSwitch
                color="primary"
                checked={user.active}
                onChange={handleAgentActivationChange}
              />
            </CardContent>
          </Card>
        </>
      )}
    </Box>
  );
};

export default Agent;

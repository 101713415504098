import { OfferApi } from '../../../api/OfferApi';
import AuditLogs from '../../../components/AuditLogs';

const OfferLogs = ({ offerId }: { offerId: string }) => {
  const offerApi = new OfferApi();

  return (
    <AuditLogs
      fetchLogs={(page, rowsPerPage) => offerApi.getOfferLogs(offerId)}
      rowsPerPage={100}
    />
  );
};

export default OfferLogs;

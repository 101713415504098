import SwaggerUI from 'swagger-ui-react';
import 'swagger-ui-react/swagger-ui.css';
import { getAuth } from 'firebase/auth';
import { getCookie } from '../utils/cookies';

const requestInterceptor = async (request: any): Promise<any> => {
  // TODO: Copy-pasta from the client. We can probably use the same interceptor if we export it.
  const auth = getAuth();
  const token = await auth.currentUser?.getIdToken();
  const merchantId = getCookie('merchantId');
  request.headers.Authorization = `Bearer ${token}`;
  request.headers['X-MerchantId'] = merchantId || 'pending';

  return request;
};

type RockerPayApi = 'merchant' | 'frontend' | 'back-office';

const MerchantApiDocs = (props: { api: RockerPayApi }) => {
  // TODO: Add request interceptor that add the authorization token to the request
  return (
    <SwaggerUI
      url={`${process.env.REACT_APP_BASE_URL}/v1/back-office/api-docs/${props.api}`}
      requestInterceptor={requestInterceptor}
    />
  );
};
export default MerchantApiDocs;

import { FormGroup, FormControlLabel, Checkbox } from '@mui/material';
import { GridToolbarContainer } from '@mui/x-data-grid';
import { useEffect, useState } from 'react';
import { Payout } from '../../../api/PayoutApi';
import PaymentPayouts from '../../payment/components/PaymentPayouts';

type Props = {
  payouts: Payout[];
  loading: boolean;
};

const OfferPayouts = (props: Props) => {
  const [showFailed, setShowFailed] = useState(
    JSON.parse(localStorage.getItem('showFailed') || 'false'),
  );

  useEffect(() => {
    localStorage.setItem('showFailed', JSON.stringify(showFailed));
  }, [showFailed]);

  function CustomToolbar() {
    return (
      <GridToolbarContainer sx={{ padding: 1 }}>
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox
                checked={showFailed}
                onChange={(event) => setShowFailed(event.target.checked)}
              />
            }
            label="Show failed payouts"
          />
        </FormGroup>
      </GridToolbarContainer>
    );
  }

  const payouts = showFailed
    ? props.payouts
    : props.payouts.filter((p) => ['PENDING', 'COMPLETED'].includes(p.status));

  return (
    <PaymentPayouts
      loading={props.loading}
      payouts={payouts}
      components={{
        Toolbar: CustomToolbar,
      }}
    />
  );
};

export default OfferPayouts;

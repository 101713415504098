import { Link } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import { ChevronLeft as ChevronLeftIcon } from '@mui/icons-material';
import PageHeader from '../../components/PageHeader';
import GroupInfo from './components/GroupInfo';
import GroupMembers from './components/GroupMembers';
import GroupMerchants from './components/GroupMerchants';

const StyledLink = styled(Link)({
  display: 'flex',
  alignItems: 'center',
});

const Group = () => (
  <>
    <PageHeader>
      <StyledLink to={`/groups`}>
        <ChevronLeftIcon sx={{ fontSize: 28 }} />
        <span>Return to Groups overview</span>
      </StyledLink>
    </PageHeader>
    <GroupInfo />
    <GroupMerchants />
    <GroupMembers />
  </>
);

export default Group;

import { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import {
  IconButton,
  Checkbox,
  FormControlLabel,
  Skeleton,
} from '@mui/material';
import { Block as BlockIcon } from '@mui/icons-material';
import { CustomerApi, Customer } from '../../../api/CustomerApi';
import formatDate from '../../../utils/format-date';
import { useAuthContext } from '../../../context/AuthContext';
import ActionConfirmationModal, {
  ActionModalProps,
} from '../../../components/ActionConfirmationModal';
import { useNotificationContext } from '../../../context/NotificationContext';

type BlockEntries = Array<{
  blockId: string;
  merchantId: string | null;
  blocked: boolean;
  blockedAt: string;
}>;

const ButtonContentWrapper = styled('div')(
  ({ disabled }: { disabled?: boolean }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '88px',
    color: disabled ? '#8C8DBA' : '#595388',
  }),
);

const CustomerBlocking = ({ customer }: { customer: Customer }) => {
  const [blockEntries, setBlockEntries] = useState<BlockEntries>([]);
  const [blockEntriesLoading, setBlockEntriesLoading] = useState(true);
  const [actionModal, setActionModal] = useState<ActionModalProps>({
    text: '',
  });
  const { user, merchantId } = useAuthContext();
  const { setNotification } = useNotificationContext();
  const customerApi = new CustomerApi();

  const fetchBlockEntries = async () => {
    const resp = await customerApi.getUserBlockEntries(
      customer.nationalIdNumber,
    );

    let blockEntries: BlockEntries = [
      { blockId: '1', merchantId: null, blocked: false, blockedAt: '' },
      {
        blockId: '2',
        merchantId: merchantId,
        blocked: false,
        blockedAt: '',
      },
    ];

    blockEntries = blockEntries.map((b) => {
      const blockEntry = resp.blockEntries.find(
        (bb) => bb.merchantId === b.merchantId,
      );
      if (!blockEntry) return b;
      return {
        ...b,
        blockId: blockEntry.blockId,
        blocked: true,
        blockedAt: formatDate(blockEntry.blockedAt),
      };
    });

    setBlockEntries(blockEntries);
    setBlockEntriesLoading(false);
  };

  useEffect(() => {
    if (!customer.nationalIdNumber) return setBlockEntriesLoading(false);
    fetchBlockEntries();
  }, []);

  if (blockEntriesLoading)
    return <Skeleton variant="rectangular" width={65} height={75} />;

  const getUpdatedEntries = (
    id: string,
    updatedEntry = {},
    entries: BlockEntries,
  ) =>
    entries.map((entry) =>
      entry.blockId === id
        ? {
            ...entry,
            ...updatedEntry,
          }
        : entry,
    );

  const handleGlobalBlock = async (blockId: string, block: boolean) => {
    try {
      if (block) {
        const resp = await customerApi.blockUserGlobal(
          customer.nationalIdNumber,
        );
        return {
          blockId: resp.blockId,
          blocked: block,
          blockedAt: formatDate(resp.blockedAt),
        };
      } else {
        await customerApi.removeGlobalBlockEntry(blockId);
        return {
          blocked: block,
        };
      }
    } catch (e) {
      setNotification({
        severity: 'error',
        message: 'Something went wrong. please try again or contact support',
      });
    }
  };

  const handleMerchantBlock = async (blockId: string, block: boolean) => {
    try {
      if (block) {
        const resp = await customerApi.blockUserMerchant(
          customer.nationalIdNumber,
        );
        return {
          blockId: resp.blockId,
          blocked: block,
          blockedAt: formatDate(resp.blockedAt),
        };
      } else {
        await customerApi.removeBlockEntry(blockId);
        return {
          blocked: block,
        };
      }
    } catch (e) {
      setNotification({
        severity: 'error',
        message: 'Something went wrong. please try again or contact support',
      });
    }
  };

  const handleBlocking = async (e: React.SyntheticEvent) => {
    try {
      const target = e.target as typeof e.target & {
        merchant: { checked: boolean; value: string };
        global: { checked: boolean; value: string };
      };
      const { merchant, global } = target;
      const merchantBlock = blockEntries.find(
        ({ blockId }) => blockId === merchant.value,
      );
      const globalBlock = blockEntries.find(
        ({ blockId }) => blockId === global.value,
      );

      let entries: BlockEntries = [...blockEntries];
      if (merchantBlock && merchantBlock.blocked !== merchant.checked) {
        const updatedMerchantEntry = await handleMerchantBlock(
          merchant.value,
          merchant.checked,
        );
        entries = getUpdatedEntries(
          merchant.value,
          updatedMerchantEntry,
          entries,
        );
      }
      if (globalBlock && globalBlock.blocked !== global.checked) {
        const updatedGlobalEntry = await handleGlobalBlock(
          global.value,
          global.checked,
        );
        entries = getUpdatedEntries(global.value, updatedGlobalEntry, entries);
      }
      setBlockEntries(entries);
      setActionModal({ text: '' });
    } catch (e) {
      setActionModal({ text: '' });
      setNotification({
        severity: 'error',
        message: 'Something went wrong. please try again or contact support',
      });
    }
  };

  return (
    <>
      <IconButton
        size="large"
        onClick={() =>
          setActionModal({
            text: `Block user ‘${customer.firstName} ${customer.lastName}’?`,
          })
        }
      >
        <ButtonContentWrapper>
          <BlockIcon sx={{ fontSize: 40 }} />
          <span style={{ fontSize: 10 }}>Block</span>
        </ButtonContentWrapper>
      </IconButton>
      {actionModal.text && (
        <ActionConfirmationModal
          {...actionModal}
          action={handleBlocking}
          close={() => setActionModal({ text: '' })}
        >
          <>
            {blockEntries.length > 0
              ? blockEntries.map(({ blockId, merchantId, blocked }, i) => (
                  <FormControlLabel
                    disabled={!blockId && !user.isSuperAdmin}
                    key={`${blockId}-${i}`}
                    value={blockId}
                    name={merchantId ? 'merchant' : 'global'}
                    control={<Checkbox defaultChecked={blocked} />}
                    label={
                      merchantId ? 'Block Merchant level' : 'Block Global level'
                    }
                  />
                ))
              : null}
          </>
        </ActionConfirmationModal>
      )}
    </>
  );
};

export default CustomerBlocking;

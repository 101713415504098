import { createContext, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { CircularProgress } from '@mui/material';
import { Offer as OfferModel, OfferApi } from '../../api/OfferApi';
import { Payout, PayoutApi } from '../../api/PayoutApi';
import { Payment as PaymentModel, PaymentApi } from '../../api/PaymentApi';
import OfferDetails from './components/OfferDetails';
import OfferTabs from './components/OfferTabs';
import OfferComments from './components/OfferComments';
import OfferLogs from './components/OfferLogs';
import OfferHeader from './components/OfferHeader';
import { useQuery } from 'react-query';
import { RichPaymentShippingResponse, ShippingApi } from '../../api/ShippingApi';
import { MerchantApi } from '../../api/MerchantApi';

const Offer = () => {
  const { offerId } = useParams<{ offerId: string }>();
  const offerApi = new OfferApi();

  const { data: offer, refetch } = useQuery(['offer', offerId], () =>
    offerApi.getOffer(offerId),
  );

  const paymentApi = new PaymentApi();
  const payoutApi = new PayoutApi();
  const shippingApi = new ShippingApi();
  const merchantApi = new MerchantApi();

  const {
    data: merchantShippingMethodsData,
  } = useQuery(
    ['merchantShippingMethods', offer?.merchantId],
    () => merchantApi.getMerchantShippingMethods(offer?.merchantId || ''),
    { enabled: !!offer, }
  );
  const isShippingEnabled = merchantShippingMethodsData && merchantShippingMethodsData.shippingMethods.length > 0;

  const { data, isLoading: loading } = useQuery(
    ['paymentsWithPayouts', offerId],
    async () => {
      const fetchPayments = await paymentApi?.getPaymentList(
        0,
        undefined,
        undefined,
        undefined,
        offerId,
        true,
      );
      const paymentIds = fetchPayments.payments.map((payment) => payment.id);
      const payoutPromises = paymentIds.map(async (pId) =>
        payoutApi.getPayoutsForPayment(pId),
      );

      const payouts = await Promise.all(payoutPromises);
      const flattenedPayouts = payouts.flat().filter((payout) => !!payout);

      const paymentsWithPayouts = fetchPayments.payments.map((payment) => ({
        ...payment,
        payouts: flattenedPayouts.filter(
          (payout) => payout.paymentId === payment.id,
        ),
      }));

      const shippings = (isShippingEnabled) ? await Promise.all(
        paymentIds.map(async (pId) =>{
          try {
            return Array(await shippingApi.getShipping(pId));
          } catch (error: any) {
            if (error.response && error.response.status === 404) {
              return [];
            } else {
              // Rethrow other errors
              throw error;
            }
          }}
        )
      ) : [] ;
      const flattenedShippings = shippings.flat().filter((shipping) => !!shipping);

      return { paymentsWithPayouts, payouts: flattenedPayouts, shippings: flattenedShippings };
    },
    { enabled: !!merchantShippingMethodsData, }
  );

  return (
    <>
      {!offer && <CircularProgress />}
      {offer && (
        <OfferContext.Provider
          value={{
            offer,
            paymentsWithPayouts: data?.paymentsWithPayouts,
            payouts: data?.payouts,
            shippings: data?.shippings,
            loading,
            refetchOffer: refetch,
          }}
        >
          <OfferHeader offer={offer} fetchOffer={refetch} />
          <OfferDetails offer={offer} />
          <OfferTabs offer={offer} isShippingEnabled={isShippingEnabled || false} />
          <OfferComments offerId={offerId} />
          <OfferLogs offerId={offerId} />
        </OfferContext.Provider>
      )}
    </>
  );
};

export default Offer;

const OfferContext = createContext<{
  loading: boolean;
  offer?: OfferModel;
  paymentsWithPayouts?: (PaymentModel & {
    payouts: Payout[];
  })[];
  payouts?: Payout[];
  shippings?: RichPaymentShippingResponse[];
  refetchOffer: () => any;
}>({
  offer: undefined,
  paymentsWithPayouts: undefined,
  payouts: undefined,
  shippings: undefined,
  loading: true,
  refetchOffer: async () => {},
});

export const useOfferContext = () => {
  const context = useContext(OfferContext);
  if (!context) {
    throw new Error('useOfferContext must be used within a OfferContext');
  }
  return context;
};

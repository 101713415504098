import {
  AuditLog,
  MoneyMinor,
  PageInfo,
  Sorting,
  sortingParam,
  UserInfo,
} from "./shared";
import { createClient } from "./client";
import { Affiliate } from "./AffiliateApi";

export class OfferApi {
  client;

  constructor() {
    this.client = createClient();
  }

  async getOfferList({
    page,
    sorting,
    query,
    sellerId,
    buyerId,
    status,
    allMerchantsSearch,
    stuckOffersSearch,
  }: {
    page: number;
    sorting: Sorting;
    query?: string;
    sellerId?: string;
    buyerId?: string;
    status?: OfferStatus;
    allMerchantsSearch?: boolean;
    stuckOffersSearch?: boolean;
  }): Promise<{
    offers: Offer[];
    pageInfo: PageInfo;
  }> {
    const queryParams: { [key: string]: string } = {
      page: page.toString(),
      pageSize: "100",
      sorting: sortingParam(sorting),
      allMerchants: (allMerchantsSearch || false).toString(),
      stuck: (stuckOffersSearch || false).toString(),
    };

    if (query) {
      queryParams.query = query;
    }

    if (sellerId) {
      queryParams.seller = sellerId;
    }

    if (buyerId) {
      queryParams.buyer = buyerId;
    }

    if (status) {
      queryParams.status = status;
    }

    const resp = await this.client.get(
      `/v1/back-office/offers?${new URLSearchParams(queryParams)}`,
    );

    return resp.data;
  }

  async getOffer(offerId: string): Promise<Offer> {
    const resp = await this.client.get(`/v1/back-office/offers/${offerId}`);
    return resp.data as Offer;
  }

  async getOfferComments(offerId: string): Promise<{ comments: Comment[] }> {
    const resp = await this.client.get(
      `/v1/back-office/offers/${offerId}/comments`,
    );
    return resp.data;
  }

  async postOfferComment(offerId: string, comment: string): Promise<Comment> {
    const resp = await this.client.post(
      `/v1/back-office/offers/${offerId}/comments`,
      { text: comment },
    );
    return resp.data;
  }

  async deleteOffer(offerId: string): Promise<Offer> {
    const resp = await this.client.delete(`/v1/back-office/offers/${offerId}`);
    return resp.data;
  }

  async getBidList(page: number, offerId: string): Promise<Bid[]> {
    const queryParams: { [key: string]: string } = {
      page: page.toString(),
      pageSize: "100",
    };

    const resp = await this.client.get(
      `/v1/back-office/offers/${offerId}/bids?` +
        new URLSearchParams(queryParams),
    );
    return resp.data.bids as Bid[];
  }

  async getOfferLogs(
    offerId: string,
  ): Promise<{ logs: AuditLog[]; pageInfo: PageInfo }> {
    const resp = await this.client.get(
      `/v1/back-office/offers/${offerId}/audit-log`,
    );
    return resp.data;
  }

  async createOffer(request: CreateOfferRequest): Promise<Offer> {
    const resp = await this.client.post(`/v1/back-office/admin/offers`, request);
    return resp.data;
  }

  async updateOffer(offerId: string, request: UpdateOfferRequest): Promise<Offer> {
    const resp = await this.client.put(`/v1/back-office/admin/offers/${offerId}`, request);
    return resp.data;
  }
}

export type PayoutSpec = 'CONFIRMED_PAYOUT' | 'AUTO_PAYOUT' | 'NO_PAYOUT';
export interface ServiceFeeItemInRequest {
  name: string;
  value: MoneyMinor;
}

export interface CreateOfferRequest {
  title: string;
  description?: string;
  escrowValue: MoneyMinor;
  serviceFeeItems: ServiceFeeItemInRequest[];
  images: string[];
  sellerId: string;
  payoutSpec: PayoutSpec,
  productType?: string;
  externalData: { [key: string]: string };
  customPayoutReference?: string;
}

export interface UpdateOfferRequest {
  title: string;
  description?: string;
  escrowValue: MoneyMinor;
  serviceFeeItems: ServiceFeeItemInRequest[];
  images: string[];
}

type F = CreateOfferRequest | UpdateOfferRequest

export interface Offer {
  id: string;
  merchantId: string;
  merchantName?: string;
  title: string;
  status: string;
  description?: string;
  escrowValue: MoneyMinor;
  price: MoneyMinor;
  serviceFee: { actual: MoneyMinor };
  serviceFeeItems: { [key: string]: MoneyMinor };
  images: string[];
  seller: UserInfo;
  buyer?: UserInfo;
  createdAt: string;
  updatedAt: string;
  externalData: { [key: string]: string };
  serviceFeeRefundable: boolean;
  productType?: string;
  manualCode?: string;
  offerUrl: string;
  affiliate?: Affiliate;
}

export type OfferUpdateStatus = Partial<Pick<Offer, "id" | "status">>;

export interface Bid {
  id: string;
  merchantId: string;
  offerId: string;
  buyerId: string;
  price: MoneyMinor;
  status: string;
  createdAt: string;
  updatedAt: string;
}

export type Comment = {
  author: { id: string; email: string };
  createdAt: string;
  id: string;
  merchantId: string;
  offerId: string;
  text: string;
};

export type OfferStatus =
  | "AVAILABLE"
  | "BOOKED"
  | "IN_PAYOUT"
  | "WAITING_ON_RENEW"
  | "FINISHED_SOLD"
  | "FINISHED_DELETED";

import {
  List,
  Grid,
  Card,
  CardHeader,
  CardContent,
} from '@mui/material';
import {ShippingMethod } from '../../../api/MerchantApi';
import formatDate from '../../../utils/format-date';
import formatDuration from '../../../utils/format-duration';

const ShippingMethodRow = ({
  shippingMethod
}: {
    shippingMethod: ShippingMethod;
}) => (
  <Card
    sx={{ p: 2, mb: 2, boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px' }}
  >
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
      }}
    >
      <b style={{ textDecoration: 'underline', fontSize: 14 }}>
        {shippingMethod.shippingMethod}
      </b>
    </div>
    <Grid container spacing={0.5}>
      <Grid item xs={6} md={3} lg={2}>
        <b>Rejection period</b>
      </Grid>
      <Grid item xs={6} md={3} lg={4}>
        <span>{formatDuration(shippingMethod.shipmentRejectionPeriod)}</span>
      </Grid>
      <Grid item xs={6} md={3} lg={2}>
        <b>List order</b>
      </Grid>
      <Grid item xs={6} md={3} lg={4}>
        <span>{shippingMethod.listOrder}</span>
      </Grid>
      <Grid item xs={6} md={3} lg={2}>
        <b>Created at</b>
      </Grid>
      <Grid item xs={6} md={3} lg={4}>
        <span>{formatDate(shippingMethod.createdAt)}</span>
      </Grid>
      <Grid item xs={6} md={3} lg={2}>
        <b>Updated at</b>
      </Grid>
      <Grid item xs={6} md={3} lg={4}>
        <span>{formatDate(shippingMethod.updatedAt)}</span>
      </Grid>
    </Grid>
  </Card>
);

const MerchantShippingMethods = ({
  shippingMethods,
}: {
  shippingMethods: ShippingMethod[];
}) => {
  return (
    <>
      <Card style={{ marginTop: 25 }}>
        <CardHeader
          title="Shipping methods:"
          titleTypographyProps={{ fontSize: 16 }}
        />
        <CardContent>
          {shippingMethods.map((method, i) => (
            <List key={method.shippingMethod} component="div" disablePadding>
              <ShippingMethodRow
                shippingMethod={method}
              />
            </List>
          ))}
        </CardContent>
      </Card>
    </>
  );
};

export default MerchantShippingMethods;

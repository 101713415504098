import { Link } from 'react-router-dom';
import { Reply as ReplyIcon, Link as LinkIcon } from '@mui/icons-material';
import { styled } from '@mui/material/styles';
import { ChevronLeft as ChevronLeftIcon } from '@mui/icons-material';
import { Button, IconButton, TextField, Tooltip, Typography } from '@mui/material';
import { useState } from 'react';
import CustomerBlockingButton from './CustomerBlockingButton';
import GoodwillPayoutButton from './GoodwillPayoutButton';
import { BlockedUser, Customer, CustomerApi, PersonalIdentificationStandard } from '../../../api/CustomerApi';
import { useNotificationContext } from '../../../context/NotificationContext';
import ChangeDefaultPayoutMethodButton from './ChangeDefaultPayoutMethodButton';
import AddIcon from '@mui/icons-material/Add';
import ActionConfirmationModal, {
  ActionModalProps,
} from '../../../components/ActionConfirmationModal';
import { MerchantApi } from '../../../api/MerchantApi';
import { useAuthContext } from '../../../context/AuthContext';
import { useQuery } from 'react-query';
import OfferModal from '../../offer/components/OfferModal';

type Props = {
  customer: Customer;
  blockedUser: undefined | BlockedUser;
  onCustomerUpdate: (customer: Customer) => void;
};

/**
 * These ModalProps should probably be called RefundModalConfirmationProps,
 * and .textField should probably be called "showCommentInputField", as that
 * is its only use case.
 */
type ModalProps = ActionModalProps & { textField?: boolean };

const CustomerHeader = ({ customer, blockedUser, onCustomerUpdate }: Props) => {
  const { setNotification } = useNotificationContext();
  const authCtx = useAuthContext();
  const merchantApi = new MerchantApi();
  const customerApi = new CustomerApi();
  const [createOfferModal, setCreateOfferModal] = useState(false);

  const { data: merchantConfigData, isLoading: isMerchantConfigLoading } =
    useQuery(['merchantConfig', customer.merchantId], () =>
      merchantApi.getMerchantConfig(customer.merchantId),
    );

  // This is basically a yes/no confirmation modal.
  const [actionModal, setActionModal] = useState<ModalProps>({
    text: '',
  });

  const onDefaultPayoutMethodChange = (payoutMethod?: string) => {
    customer.defaultPayoutMethod = payoutMethod;
    onCustomerUpdate(customer);
  };

  const sendPayoutLink = async () => {
    try {
      await customerApi.sendPayoutLink(customer.id);
      setNotification({
        message: 'Payout link sent',
        severity: 'success',
      });
    } catch (error) {
      setNotification({
        message: 'Error sending payout link',
        severity: 'error',
      });
    }
    setActionModal({ text: '' });
  };

  const customerName = () => {
    switch (customer.userType) {
      case 'AUTHENTICATED_USER':
        return `${customer.firstName} ${customer.lastName}`;
      case 'COMPANY_USER':
        return customer.companyName;
      case 'FOREIGN_USER':
        return customer.email;
    }
  };

  function flag(params:PersonalIdentificationStandard) {
    switch (params) {
      case 'FINNISH_HETU':
        return '🇫🇮';
      case 'SWEDISH_PERSONAL_IDENTITY_NUMBER':
        return '🇸🇪'
    }
  }

  const customerIdNumber = () => {
    switch (customer.userType) {
      case 'AUTHENTICATED_USER':
        return `${flag(customer.personalIdentificationNumber.standard)} ${customer.personalIdentificationNumber.value}`;
      case 'COMPANY_USER':
        return `Reg.Number: ${customer.registrationNumber}`;
      case 'FOREIGN_USER':
        return `Foreign ID: ${customer.foreignUserId}`;
    }
  };

  return (
    <>
      {actionModal.text && (
        <ActionConfirmationModal
          {...actionModal}
          close={() => setActionModal({ text: '' })}
        >
          {actionModal.textField && (
            <TextField
              id="comment-text"
              name="comment"
              fullWidth
              placeholder="Add a comment..."
              variant="outlined"
              sx={{ marginTop: 2 }}
            />
          )}
        </ActionConfirmationModal>
      )}
      <StyledLink to={`/customers`}>
        <ChevronLeftIcon sx={{ fontSize: 28 }} />
        <span>Return to Customers overview</span>
      </StyledLink>
      <Wrapper>
        <TitleWrapper>
          <Typography variant="h4" fontSize={24} fontWeight={700} mr={2}>
            {customerName()}
          </Typography>
          <Typography variant="h6" fontSize={14} fontWeight={400} mt={0.5}>
            {customerIdNumber()}
          </Typography>
          {blockedUser &&
            (blockedUser.isBlocked || blockedUser.isBlockedGlobally) && (
              <Tooltip title="Blocked customer">
                <Typography
                  variant="h6"
                  fontSize={20}
                  fontWeight={400}
                  mt={0.5}
                  ml={0.5}
                >
                  ⛔️
                </Typography>
              </Tooltip>
            )}
        </TitleWrapper>
        {authCtx.can("CreateEntity") && merchantConfigData?.backofficeCreateEntityPermission?.createOfferAllowed &&
          <Button
            variant="contained"
            startIcon={<AddIcon />}
            onClick={() => setCreateOfferModal(true)}
          >
            Create offer
          </Button>}
        <ButtonsWrapper>
          <GoodwillPayoutButton customer={customer} />
          <IconButton
            size="large"
            onClick={() =>
              setActionModal({
                text: 'Send link select payout methods',
                action: sendPayoutLink,
              })
            }
          >
            <ButtonContentWrapper>
              <StyledReplyIcon />
              <LinkIcon sx={{ fontSize: 40, marginBottom: '-8px' }} />
              <span style={{ fontSize: 10 }}>
                Send link select payout methods
              </span>
            </ButtonContentWrapper>
          </IconButton>
          <ChangeDefaultPayoutMethodButton
            customer={customer}
            onDefaultPayoutMethodChange={onDefaultPayoutMethodChange}
          />
          <CustomerBlockingButton customer={customer} />
        </ButtonsWrapper>
        {createOfferModal &&
          <OfferModal
          initialData={{
            title: '',
            escrowValue: {
              amount: 0,
              currency: 'SEK',
              unit: 'MINOR'
            },
            serviceFeeItems: [],
            images: [],
            sellerId: customer.id,
            payoutSpec: 'CONFIRMED_PAYOUT',
            externalData: {}
          }}
            title={`Create new offer for ${customer.firstName} ${customer.lastName}`}
            onSuccess={(o) => {}}
            close={() => setCreateOfferModal(false)} />}
      </Wrapper>
    </>
  );
};

const StyledLink = styled(Link)({ display: 'flex', alignItems: 'center' });

const StyledReplyIcon = styled(ReplyIcon)({
  position: 'absolute',
  fontSize: '25px',
  right: '28px',
  top: '4px',
});
const Wrapper = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginBottom: '24px',
});
const ButtonsWrapper = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
});

const TitleWrapper = styled('div')({
  display: 'flex',
  alignItems: 'center',
});

const ButtonContentWrapper = styled('div')(
  ({ disabled }: { disabled?: boolean }) => ({
    display: 'flex',
    alignItems: 'center',
    width: '88px',
    flexDirection: 'column',
    color: disabled ? '#8C8DBA' : '#595388',
  }),
);

export default CustomerHeader;

import { createClient } from './client';
import { PageInfo } from './shared';
import { CreateAgentRequest, CreateUserRequest } from './UserApi';

export class InviteApi {
  client;

  constructor() {
    this.client = createClient();
  }

  async getInvitations(): Promise<{
    invites: Array<Invite>;
    pageInfo: PageInfo;
  }> {
    const resp = await this.client.get(`/v1/back-office/admin/invites`);
    return resp.data;
  }

  async getInvitationsForMerchant(): Promise<{
    invites: Array<Invite>;
    pageInfo: PageInfo;
  }> {
    const resp = await this.client.get(`/v1/back-office/admin/merchant/invites`);
    return resp.data;
  }

  async createInvitation(
    payload: CreateUserRequest,
  ): Promise<{ email: string; token: string }> {
    const resp = await this.client.post(
      `/v1/back-office/admin/invites`,
      payload,
    );
    return resp.data;
  }

  async deleteMerchantAdminInvitation(id: string): Promise<{}> {
    const resp = await this.client.delete(`/v1/back-office/admin/merchant/agents/invites/${id}`);
    return resp.data;
  }

  async consumeInvitation(inviteToken: string) {
    const resp = await this.client.post(
      `/v1/back-office/invites/${inviteToken}/consume`,
    );
    return resp.data;
  }
}

export type Invite = {
  id: string;
  createdAt: string;
  expiresAt: string;
  status: string;
  createdByUser: string;
  createdByEmail: string;
  createdForEmail: string;
  consumedByEmail: string;
  consumedByUser: string;
  consumedAt: string;
  customClaims: any;
};

import {
  GridToolbarContainer,
  GridToolbarFilterButton,
  GridToolbarDensitySelector,
  GridToolbarColumnsButton,
} from '@mui/x-data-grid';

type Props = { filter?: boolean; columns?: boolean; density?: boolean };

const GridToolbar = ({ filter, columns, density }: Props) => (
  <GridToolbarContainer>
    {filter && <GridToolbarFilterButton />}
    {columns && <GridToolbarColumnsButton />}
    {density && <GridToolbarDensitySelector />}
  </GridToolbarContainer>
);

export default GridToolbar;

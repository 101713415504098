import { Card, CardHeader, CardContent, Grid } from '@mui/material';
import { BackofficeCreateEntityPermission } from '../../../api/MerchantApi';
import formatDate from '../../../utils/format-date';
import BlockIcon from '@mui/icons-material/Block';
import CheckIcon from '@mui/icons-material/Check';


const MerchantBackofficeCreateEntityPermission = ({ permission }: { permission?: BackofficeCreateEntityPermission }) => (
  <Card style={{ marginTop: 25 }}>
    <CardHeader title="Backoffice create entity permission:" titleTypographyProps={{ fontSize: 16 }} />
    <CardContent>
      {permission && (
        <Grid container spacing={0.5}>
          <Grid item xs={3}>
            <b>Create offer</b>
          </Grid>
          <Grid item xs={9}>
            <span>{permission.createOfferAllowed ? <CheckIcon /> : <BlockIcon />}</span>
          </Grid>
          <Grid item xs={3}>
            <b>Create user</b>
          </Grid>
          <Grid item xs={9}>
            <span>{permission.createUserAllowed ? <CheckIcon /> : <BlockIcon />}</span>
          </Grid>
          <Grid item xs={3}>
            <b>Created at</b>
          </Grid>
          <Grid item xs={9}>
            <span>{formatDate(permission.createdAt)}</span>
          </Grid>
          <Grid item xs={3}>
            <b>Updated at</b>
          </Grid>
          <Grid item xs={9}>
            <span>{formatDate(permission.updatedAt)}</span>
          </Grid>
        </Grid>
      )}
    </CardContent>
  </Card>
);

export default MerchantBackofficeCreateEntityPermission;

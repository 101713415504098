import { useMemo } from 'react';
import { DataGrid, DataGridProps, GridColDef } from '@mui/x-data-grid';
import { Tooltip, Typography } from '@mui/material';
import { Payout } from '../../../api/PayoutApi';
import { useAuthContext } from '../../../context/AuthContext';
import formatDate from '../../../utils/format-date';
import formatMoney from '../../../utils/format-money';
import PayoutActions from './PayoutActions';

const getColumns = ({
  showPayoutAccountId,
}: {
  showPayoutAccountId: boolean;
}): Array<GridColDef> => [
    {
      field: 'actions',
      headerName: 'Actions',
      width: 150,
      disableColumnMenu: true,
      filterable: false,
      renderCell: (p) => <PayoutActions payout={p.row} />,
    },
    {
      field: 'id',
      headerName: 'Payout ID',
      width: 150,
      sortable: false,
      disableColumnMenu: true,
      renderCell: ({ row }) => (
        <Tooltip title={row.id} placement="top">
          <p>{row.id}</p>
        </Tooltip>
      ),
    },
    {
      field: 'amount',
      headerName: 'Amount',
      width: 150,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (p) => formatMoney(p.row.amount),
    },
    {
      field: 'status',
      headerName: 'Status',
      width: 150,
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: 'payoutMethod',
      headerName: 'Method',
      width: 150,
      sortable: false,
      disableColumnMenu: true,
    },
    {
      hide: !showPayoutAccountId,
      field: 'payoutAccount',
      headerName: 'Payout Account',
      width: 180,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (p) => {
        if (!p.row.payoutAccount) return null;
        const items = (Object.entries(p.row.payoutAccount) || []).map(
          ([k, v]) => `${k}: ${v}`,
        );
        return (
          <Tooltip
            title={
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                {items.map((item) => (
                  <span>{item}</span>
                ))}
              </div>
            }
          >
            <div style={{ width: 200 }}>
              {items.map((item, i) => (
                <span>{i === 0 ? item : `, ${item}`}</span>
              ))}
            </div>
          </Tooltip>
        );
      },
    },
    {
      field: 'errorMessage',
      headerName: 'Error Message',
      width: 200,
      disableColumnMenu: true,
      filterable: false,
      renderCell: (p) => (
        <Tooltip title={p.row.errorMessage}>
          <Typography color="error">{p.row.errorMessage}</Typography>
        </Tooltip>
      ),
    },
    {
      field: 'errorCode',
      headerName: 'Error Code',
      width: 200,
      disableColumnMenu: true,
      filterable: false,
      renderCell: (p) => (
        <Tooltip title={p.row.errorCode}>
          <Typography color="error">{p.row.errorCode}</Typography>
        </Tooltip>
      ),
    },
    {
      field: 'providerErrorCode',
      headerName: 'Provider Error Code',
      width: 200,
      disableColumnMenu: true,
      filterable: false,
      renderCell: (p) => (
        <Tooltip title={p.row.providerErrorCode}>
          <Typography color="error">{p.row.providerErrorCode}</Typography>
        </Tooltip>
      ),
    },
    {
      field: 'createdAt',
      headerName: 'Created',
      width: 150,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (p) => formatDate(p.row.createdAt),
    },
    {
      field: 'updatedAt',
      headerName: 'Updated',
      width: 150,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (p) => formatDate(p.row.updatedAt),
    },
  ];

type Props = Omit<DataGridProps, 'columns' | 'rows'> & {
  payouts?: Payout[];
  loading: boolean;
};

const PaymentPayouts = ({ payouts, loading, ...props }: Props) => {
  const {
    user: { isSuperAdmin, isMerchantAdmin },
  } = useAuthContext();
  const columns = useMemo(
    () => getColumns({ showPayoutAccountId: isSuperAdmin || isMerchantAdmin }),
    [],
  );

  return (
    <DataGrid
      initialState={{
        sorting: {
          sortModel: [{ field: 'updatedAt', sort: 'desc' }],
        },
      }}
      {...props}
      rows={payouts ?? []}
      loading={loading}
      hideFooter
      autoHeight
      columns={columns}
      disableSelectionOnClick={true}
    />
  );
};

export default PaymentPayouts;

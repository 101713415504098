import { Link } from 'react-router-dom';
import { useState } from 'react';
import { styled } from '@mui/material/styles';
import { ChevronLeft as ChevronLeftIcon } from '@mui/icons-material';
import { Button } from '@mui/material';
import PageHeader from '../../../components/PageHeader';
import NotificationConfigModal from './NotificationConfigModal';
import { useAuthContext } from '../../../context/AuthContext';

const StyledLink = styled(Link)({
  display: 'flex',
  alignItems: 'center',
});

const MerchantHeader = () => {
  const [configModal, setConfigModal] = useState(false);
  const { user: { isSuperAdmin } } = useAuthContext();

  return (
    <>
      <PageHeader>
        <StyledLink to={`/merchants`}>
          <ChevronLeftIcon sx={{ fontSize: 28 }} />
          <span>Return to Merchants overview</span>
        </StyledLink>
        {isSuperAdmin && <Button
          variant="contained"
          size="small"
          onClick={() => setConfigModal(true)}
        >
          Notification settings
        </Button>}
        {configModal && (
          <NotificationConfigModal close={() => setConfigModal(false)} />
        )}
      </PageHeader>
    </>
  );
};

export default MerchantHeader;

import { useState, useMemo } from 'react';
import { Button, Card, CardContent, CardHeader } from '@mui/material';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { GroupApi } from '../../../api/GroupApi';
import ModifyGroupMerchants from './ModifyGroupMerchants';

const GroupMerchants = () => {
  const [modifyMerchants, setModifyMerchants] = useState(false);
  const { groupId } = useParams<{ groupId: string }>();
  const groupApi = new GroupApi();

  const { data, isLoading, isRefetching } = useQuery(
    ['groupMerchants', groupId],
    () => groupApi.getGroupMerchants(groupId),
  );

  const merchantIds = useMemo(
    () => data?.merchants.map((m) => m.id),
    [groupId, isLoading, isRefetching],
  );

  return (
    <>
      <Card style={{ marginTop: 25 }}>
        <CardHeader title="Merchants" />
        <CardContent>
          {data?.merchants.map((merchant) => merchant.name).join(', ')}
          <hr
            style={{
              height: 1,
              background: 'rgba(224, 224, 224, 1)',
              border: 'none',
            }}
          />
          <Button
            variant="contained"
            size="small"
            onClick={() => setModifyMerchants(true)}
          >
            Modify Merchants
          </Button>
        </CardContent>
      </Card>
      {modifyMerchants && (
        <ModifyGroupMerchants
          currentMerchantIds={merchantIds ?? []}
          close={() => setModifyMerchants(false)}
        />
      )}
    </>
  );
};

export default GroupMerchants;

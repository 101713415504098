import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { styled } from '@mui/material/styles';
import {
  IconButton,
  Box,
  TextField,
  InputAdornment,
} from '@mui/material';
import { Payment as PaymentIcon, PriorityHigh as PriorityHighIcon } from '@mui/icons-material';
import { Customer } from '../../../api/CustomerApi';
import { GoodwillPayoutRequest, GoodwillPayoutApi } from '../../../api/GoodwillPayoutApi';
import { useAuthContext } from '../../../context/AuthContext';
import ActionConfirmationModal, {
  ActionModalProps,
} from '../../../components/ActionConfirmationModal';
import { useNotificationContext } from '../../../context/NotificationContext';
import { GoodwillPayoutMethod, MerchantApi } from '../../../api/MerchantApi';
import { validatePhoneNumber } from '../../../utils/validate-phone-number';
import formatMoney from '../../../utils/format-money';


const NumberField = styled(TextField)({
  '& input[type=number]': {
    '-moz-appearance': 'textfield',
    '&::-webkit-outer-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
    '&::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
  },
});

const StyledPriorityHighIcon = styled(PriorityHighIcon)({
  position: 'absolute',
  fontSize: '25px',
  color: '#ff0000',
  right: '23px',
  top: '4px',
});


const ButtonContentWrapper = styled('div')(
  ({ disabled }: { disabled?: boolean }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '88px',
    color: disabled ? '#8C8DBA' : '#595388',
  }),
);

const GoodwillPayout = ({ customer }: { customer: Customer }) => {
  const [actionModal, setActionModal] = useState<ActionModalProps>({
    text: '',
  });
  const [confirmModal, setConfirmModal] = useState<ActionModalProps>({
    text: '',
  });
  const authContext = useAuthContext();
  const { setNotification } = useNotificationContext();
  const goodwillPayoutApi = new GoodwillPayoutApi();
  const merchantApi = new MerchantApi();

  const [formData, setFormData] = useState<GoodwillPayoutRequest>({
    userId: customer.id,
    amount: { amount: 0, currency: 'SEK', unit: 'MINOR' },
    phone: customer.phone,
    reason: '',
    comment: ''
  });
  const {
    data: merchantGoodwillPayoutMethodsData,
  } = useQuery(
    ['merchantGoodwillPayoutMethods', customer.merchantId, 'SEK'],
    () => merchantApi.getMerchantGoodwillPayoutMethods(customer.merchantId, 'SEK'),
  );

  const isSwishEnabled = (array: GoodwillPayoutMethod[] | undefined) => {
    return array?.some(item => item.enabled && item.payoutMethod === 'SWISH') || false;
  };

  const maxAmount = merchantGoodwillPayoutMethodsData?.payoutMethods?.find(
    (item) => item.payoutMethod === 'SWISH'
  )?.maxAmount;

  const hasSwishEnabled = isSwishEnabled(merchantGoodwillPayoutMethodsData?.payoutMethods);
  const isSwedishAuthenticatedUser = customer.personalIdentificationNumber?.standard === 'SWEDISH_PERSONAL_IDENTITY_NUMBER'

  const isButtonEnabled = isSwedishAuthenticatedUser && hasSwishEnabled && authContext.can('MakeGoodwillPayout')

  const [isAmountNegative, setIsAmountNegative] = useState(false);
  const [isMaxAmountExceeded, setIsMaxAmountExceeded] = useState(false);
  const [isInvalidPhoneNumber, setIsInvalidPhoneNumber] = useState(false);
  const [isFormValid, setIsFormValid] = useState(false);

  const handleGoodwillPayout = async (e: React.SyntheticEvent) => {
    try {
      const response = await goodwillPayoutApi.initiateSwishGoodwillPayout(formData);
      // Clear form data after submission if needed
      setFormData({
        userId: customer.id,
        amount: { amount: 0, currency: 'SEK', unit: 'MINOR' },
        phone: customer.phone,
        reason: '',
        comment: ''
      });
      setActionModal({ text: '' });
      setConfirmModal({ text: '' });
      setNotification({
        severity: 'success',
        message: 'Goodwill payout has been initiated',
      });
    } catch (error: any) {
      setActionModal({ text: '' });
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        setNotification({
          severity: 'error',
          message: error.response.data.message + '(' + error.response.data.errorCode +')',
        });
      } else {
        setNotification({
          severity: 'error',
          message: 'Something went wrong. please try again or contact support',
        });
      }
    }
  };

  useEffect(() => {
    setIsFormValid(formData.amount.amount > 0 && !isMaxAmountExceeded && formData.reason !== '' && formData.phone !== '' && !isInvalidPhoneNumber);
  }, [formData]);

  const handleInputChange = (e: any) => {
    const { id, value } = e.target;
    setFormData({ ...formData, [id]: value });
  };

  const validatePhoneInput = (e: any) => {
    const { value } = e.target;
    const isValid = validatePhoneNumber(value);

    setIsInvalidPhoneNumber(isValid !== true);
  };

  const handleMoneyInputChange = (e: any) => {
    const { value } = e.target;
    if (maxAmount) {
      if (value > Number(maxAmount.amount) / 100) {
        setIsMaxAmountExceeded(true);
      } else {
        setIsMaxAmountExceeded(false);
      }
    }
    (value < 0) ? setIsAmountNegative(true) : setIsAmountNegative(false);
    setFormData({ ...formData, amount: { amount: value * 100, currency: 'SEK', unit: 'MINOR' } });
  };

  const handleCloseModal = () => {
      setActionModal({ text: '' })
      setFormData({
        userId: customer.id,
        amount: { amount: 0, currency: 'SEK', unit: 'MINOR' },
        phone: customer.phone,
        reason: '',
        comment: ''
      });
      setIsInvalidPhoneNumber(false);
  };

  return (
    <>
      {isButtonEnabled && <IconButton
        size="large"
        onClick={() =>
          setActionModal({
            text: `Goodwill payout to ‘${customer.firstName} ${customer.lastName}’?`,
            description: 'Actual payout is made via swish payouts',
          })
        }
      >
        <ButtonContentWrapper>
          <StyledPriorityHighIcon />
          <PaymentIcon sx={{ fontSize: 40 }} />
          <span style={{ fontSize: 10 }}>Goodwill payout</span>
        </ButtonContentWrapper>
      </IconButton>}
      {confirmModal.text && <ActionConfirmationModal
        {...confirmModal}
        action={handleGoodwillPayout}
        close={() => setConfirmModal({ text: '' })}
      />}
      {actionModal.text && (
        <ActionConfirmationModal
          {...actionModal}
          action={() => setConfirmModal({
            text: `Are you sure you want to make a goodwill payout of ${formatMoney(formData.amount)} to ‘${customer.firstName} ${customer.lastName}’?`,
          }) }
          actionDisabled={!isFormValid}
          close={handleCloseModal}
        >
          <Box sx={{ px: 1 }}>
            <NumberField
              id='amount'
              label="Payout amount"
              type='number'
              required
              placeholder='0'
              onChange={handleMoneyInputChange}
              sx={{ my: 1 }}
              fullWidth
              InputProps={{
                endAdornment: <InputAdornment position="end">kr</InputAdornment>,
              }}
              error={isMaxAmountExceeded || isAmountNegative}
              helperText={(isMaxAmountExceeded && maxAmount) ? `Utbetalning kan inte vara större än ${formatMoney(maxAmount)}` : ''}
            />
            <TextField
              id="phone"
              label="Phone"
              type="tel"
              required
              error={isInvalidPhoneNumber}
              value={formData.phone}
              onChange={(e: any) => {
                handleInputChange(e);
                validatePhoneInput(e);
              } }
              onBlur={validatePhoneInput}
              sx={{ my: 1 }}
              fullWidth
              helperText={isInvalidPhoneNumber ? 'Ange ett giltigt mobilnummer' : ''}
            />
            <TextField
              id="reason"
              label="Reason"
              required
              value={formData.reason}
              onChange={handleInputChange}
              helperText="Will be visible for receiver"
              sx={{ my: 1 }}
              fullWidth
              multiline
            />
            <TextField
              id="comment"
              label="Comment"
              value={formData.comment}
              onChange={handleInputChange}
              helperText="Internal comment"
              sx={{ my: 1 }}
              fullWidth
              multiline
            />
          </Box>
        </ActionConfirmationModal>
      )}
    </>
  );
};

export default GoodwillPayout;

import { AuditLog, MoneyMinor, OfferInfo, PageInfo, UserInfo } from './shared';
import { createClient } from './client';

export class PaymentApi {
  client;

  constructor() {
    this.client = createClient();
  }

  async getPayment(paymentId: string): Promise<Payment> {
    const resp = await this.client.get(`/v1/back-office/payments/${paymentId}`);
    return resp.data as Payment;
  }

  async getPaymentList(
    page: number,
    query?: string,
    buyerId?: string,
    sellerId?: string,
    offerId?: string,
    includeCoreviewState?: boolean,
  ): Promise<{
    payments: Payment[];
    pageInfo: PageInfo;
  }> {
    const queryParams: { [key: string]: string } = {
      page: page.toString(),
      pageSize: '100',
      sorting: 'createdAt.desc',
    };

    if (query) {
      queryParams.query = query;
    }

    if (buyerId) {
      queryParams.buyerId = buyerId;
    }

    if (sellerId) {
      queryParams.sellerId = sellerId;
    }

    if (offerId) {
      queryParams.offerId = offerId;
    }

    if (includeCoreviewState !== undefined) {
      queryParams.includeCoreviewState = includeCoreviewState.toString();
    }

    const resp = await this.client.get(
      `/v1/back-office/payments?` + new URLSearchParams(queryParams),
    );

    return resp.data;
  }

  async getRefunds(paymentId: string): Promise<PaymentRefunds> {
    const resp = await this.client.get<PaymentRefunds>(
      `/v1/back-office/payments/${paymentId}/refunds`,
    );
    return resp.data;
  }

  async refundPayment(paymentId: string, forceFull: boolean): Promise<Payment> {
    const resp = await this.client.post(
      `/v1/back-office/payments/${paymentId}/refund?service-fee-refundable=${forceFull}`,
      null,
    );
    return resp.data as Payment;
  }

  async selectiveRefundPayment(
    paymentId: string,
    feeTypesToRefund: Set<string>,
    flagAsExternallyRefunded: boolean,
    comment?: string,
    customPayoutRef?: string,
  ): Promise<Payment> {
    const resp = await this.client.post(
      `/v1/back-office/payments/${paymentId}/selective-refund${
        flagAsExternallyRefunded ? '?external-refund-method=EXTERNAL' : ''
      }`,

      {
        comment: comment,
        customPayoutReference: customPayoutRef,
        refundFeeStrategy: {
          toRefund: {
            toInclude: Array.from(feeTypesToRefund),
            $type: 'IncludeFees',
          },
        },
      },
    );
    return resp.data as Payment;
  }

  async partialRefundPayment(paymentId: string): Promise<Payment> {
    const resp = await this.client.post(
      `/v1/back-office/payments/${paymentId}/partial-refund`,
      null,
    );
    return resp.data as Payment;
  }

  async getPaymentLogs(paymentId: string): Promise<AuditLog[]> {
    const resp = await this.client.get(
      `/v1/back-office/payments/${paymentId}/audit-log`,
    );
    return resp.data.logs as AuditLog[];
  }

  async createManualPayment(body: ManualPaymentRequest): Promise<any> {
    const resp = await this.client.post<any>(`/v1/back-office/payments`, body);
    return resp.data;
  }

  async cancelPayment(paymentId: string): Promise<Payment> {
    const resp = await this.client.put<Payment>(
      `/v1/back-office/payments/${paymentId}/cancel`,
    );
    return resp.data;
  }

  async blockPayout(paymentId: string, isBlocked: boolean): Promise<Payment> {
    const resp = await this.client.put(
      `/v1/back-office/payments/${paymentId}/set-block-payout`,
      { isBlocked },
    );
    return resp.data;
  }

  async updatePaymentPausedState(
    paymentId: string,
    newState: PaymentPaused,
    comment?: string,
  ): Promise<Payment> {
    const resp = await this.client.post(
      `/v1/back-office/payments/${paymentId}/pause-state/${newState}${
        comment ? `?comment=${comment}` : ''
      }`,
    );
    return resp.data;
  }

  async forcePayout(paymentId: string, forcePayoutMethod?: string) {
    const resp = await this.client.post(
      `/v1/back-office/payments/${paymentId}/force-payout`,
      { forcePayoutMethod },
    );
    return resp.data;
  }

  async resendBuyerReceipt(paymentId: string) {
    const resp = await this.client.post(
      `/v1/back-office/payments/${paymentId}/resend-buyer-receipt`,
    );
    return resp.data;
  }

  async flagAsSucceeded(paymentId: string) {
    const resp = await this.client.post(
      `/v1/back-office/payments/${paymentId}/force-succeed`,
    );
    return resp.data;
  }

  async flagAsFailed(paymentId: string) {
    const resp = await this.client.post(
      `/v1/back-office/payments/${paymentId}/force-fail`,
    );
    return resp.data;
  }

  async grantPayoutConsent(paymentId: string) {
    const resp = await this.client.put(
      `/v1/back-office/payments/${paymentId}/payout-consent`,
    );
    return resp.data;
  }

  async revertPayoutConsent(paymentId: string) {
    const resp = await this.client.delete(
      `/v1/back-office/payments/${paymentId}/payout-consent`,
    );
    return resp.data;
  }
}

export type PaymentPaused = 'NOT_PAUSED' | 'PAUSED';

export interface Payment {
  id: string;
  merchantId: string;
  seller: UserInfo;
  offer: OfferInfo;
  bidId: string;
  buyer: UserInfo;
  amount: MoneyMinor;
  paymentMethod: string;
  reference: String;
  status: string;
  payoutConsent: string;
  createdAt: string;
  updatedAt: string;
  pauseState: PaymentPaused;
  errorMessage?: string;
  buyerCoreviewAccountId?: string;
  sellerCoreviewAccountId?: string;
  serviceFeeRefundable: boolean;
  errorCode?: string;
  providerErrorCode?: string;
}

export interface PaymentRefunds {
  paymentId: string;
  refundedAmount: MoneyMinor;
  refunds: Refund[];
}

interface Refund {
  id: string;
  paymentId: string;
  refundMethod: string;
  amount: {
    amount: number;
    currency: string;
    unit: string;
  };
  status: string;
  createdAt: Date;
  updatedAt: Date;
}

export interface ManualPaymentRequest {
  offerId: string;
  buyerId: string;
  paymentMethod: string;
  paymentMethodData?: object;
  delegatePayerAlias?: string;
  delegatePayerSsn?: string;
}

import { Box, Card, Link, Tab, Tabs } from '@mui/material';
import { useState } from 'react';
import { useQuery } from 'react-query';
import { Customer } from '../../../api/CustomerApi';
import { useAuthContext } from '../../../context/AuthContext';
import CustomerPayments from './CustomerPayments';
import InvoiceList from './InvoiceList';
import LoanApplicationList from './LoanApplicationList';
import OfferList from './OfferList';
import PayoutAccountList from './PayoutAccountList';
import { MerchantApi } from '../../../api/MerchantApi';
import GoodwillPayoutList from './GoodwillPayoutList';

const CustomerTabs = ({ customer }: { customer: Customer }) => {
  const merchantApi = new MerchantApi();
  
  const authContext = useAuthContext();
  const [tab, setTab] = useState<string>('seller');
  const {
    data: merchantGoodwillPayoutMethodsData,
  } = useQuery(
    ['merchantGoodwillPayoutMethods', customer.merchantId, 'SEK'],
    () => merchantApi.getMerchantGoodwillPayoutMethods(customer.merchantId, 'SEK'),
    );
  const isGoodwillPayoutsEnabled = merchantGoodwillPayoutMethodsData && merchantGoodwillPayoutMethodsData.payoutMethods?.some(item => item.enabled)



  return (
    <Card style={{ marginTop: 25 }}>
      <Tabs value={tab} onChange={(e, val) => setTab(val)}>
        <Tab label="Sales" value="seller" />
        <Tab label="Purchases" value="buyer" />
        <Tab label="Failed Payments" value="failed-payments" />
        <Tab label="Payout accounts" value="payout-accounts" />
        {customer.userType === "AUTHENTICATED_USER" && authContext.can('ViewInvoice') && <Tab label="Loan applications" value="loan-applications" />}
        {customer.userType === "AUTHENTICATED_USER" && authContext.can('ViewLoanApplication') && <Tab label="Invoices" value="invoices" />}
        {authContext.can('GetGoodwillPayouts') && isGoodwillPayoutsEnabled && <Tab label="Goodwill payouts" value="goodwill-payouts" />}
      </Tabs>
      {tab === 'seller' && (
        <OfferList filterOn="seller" customerId={customer.id} />
      )}
      {tab === 'buyer' && (
        <OfferList filterOn="buyer" customerId={customer.id} />
      )}
      {tab === 'invoices' && (
        <>
          <InvoiceList customerId={customer.id} />
          <Box sx={{ margin: 2 }}>
            <Link
              href={`https://admin.prod.rockerapi.dev/invoice/search?ssn=${customer.nationalIdNumber}`}
            >
              Show all customer invoices
            </Link>
          </Box>
          
        </>
      )}
      {tab === 'failed-payments' && (
        <CustomerPayments
          buyerId={customer.id}
          customerPaymentType={'buyer'}
          filter={(p) => !['FINISHED_SOLD', 'SETTLED', 'REFUNDED'].includes(p.status)}
        ></CustomerPayments>
      )}
      {tab === 'payout-accounts' && (
        <PayoutAccountList customer={customer} />
      )}
      {tab === 'loan-applications' &&  (
        <LoanApplicationList customerId={customer.id} />
      )}
      {tab === 'goodwill-payouts' && (
        <GoodwillPayoutList customer={customer} />
      )}
    </Card>
  );
};

export default CustomerTabs;

import { useState } from 'react';
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  Typography,
  TextField,
} from '@mui/material';
import { useNotificationContext } from '../context/NotificationContext';
import Modal from './Modal';
import { PayoutAccount } from '../api/PayoutApi';
import { Customer, CustomerApi } from '../api/CustomerApi';
import {LoadingButton} from "@mui/lab";

type Props = {
  customer: Customer;
  onSuccess: (payoutAccount: PayoutAccount) => void;
  close: () => void;
};

const errorMessages = {};
type ErrorMessageKey = keyof typeof errorMessages;

const AddSwishAccountModal = ({ customer, onSuccess, close }: Props) => {

  const [swishAccount, setSwishAccount] = useState<any>({
    nationalIdNumber: customer.nationalIdNumber,
    phoneNumber: customer.phone,
  });
  const [loading, setLoading] = useState<boolean>(false);
  const { setNotification } = useNotificationContext();
  const customerApi = new CustomerApi();

  const updateValue = (e: any) => {
    const { name, value } = e.target;
    setSwishAccount((u: any) => ({ ...u, [name]: value }));
  };

  const addSwishAccount = async (e: any) => {
    e.preventDefault();
    setLoading(true);

    if (!e.target.reportValidity()) {
      setLoading(false);
      return;
    }

    try {
      const payoutAccount = await customerApi.addSwishAccount(
        customer.id,
        swishAccount.nationalIdNumber,
        swishAccount.phoneNumber
      );

      setNotification({
        severity: 'success',
        message: 'Swish account added successfully',
      });
      onSuccess(payoutAccount);
      close();
    } catch (e: any) {
      const errorCode = e.response?.data?.errorCode as ErrorMessageKey;
      const message = errorMessages[errorCode] ?? 'Failed to add Swish account';

      setNotification({
        severity: 'error',
        message,
      });
    }
    setLoading(false);
  };

  return (
    <Modal onClose={close}>
      <form onSubmit={addSwishAccount}>
        <Typography component="h2" fontSize={23}>
          Add Swish account:
        </Typography>
        <TextField
          id="national-id-number"
          label="SSN"
          type="text"
          name="nationalIdNumber"
          inputProps={{
            autocomplete: 'off',
            inputMode: 'numeric',
          }}
          required
          fullWidth
          margin="dense"
          onChange={updateValue}
          value={swishAccount.nationalIdNumber}
        />
        <TextField
          id="phone-number"
          label="Phone number"
          type="text"
          name="phoneNumber"
          inputProps={{
            autocomplete: 'off',
            inputMode: 'numeric',
          }}
          required
          fullWidth
          margin="dense"
          onChange={updateValue}
          value={swishAccount.phoneNumber}
        />

        <LoadingButton
          type="submit"
          variant="contained"
          color="primary"
          loading={loading}
          fullWidth
          sx={{ mt: 2 }}
        >
          Add Swish account
        </LoadingButton>
        <Button variant="outlined" fullWidth sx={{ mt: 1 }} onClick={close}>
          Cancel
        </Button>
      </form>
    </Modal>
  );
};

export default AddSwishAccountModal;

import { Card, CardHeader, CardContent, Grid } from '@mui/material';
import { MerchantCurrency } from '../../../api/MerchantApi';
import formatMoney from '../../../utils/format-money';

const MerchantServiceFeeInfo = ({ merchantCurrency }: { merchantCurrency: MerchantCurrency }) => (
  <Card>
    <CardHeader title="Service Fee:" titleTypographyProps={{ fontSize: 16 }} />
    <CardContent>
      <Grid container spacing={0.5}>
        <Grid item xs={3}>
          <b>Min amount</b>
        </Grid>
        <Grid item xs={9}>
          {merchantCurrency.serviceFeeMinPrice  && <span>{formatMoney(merchantCurrency.serviceFeeMinPrice)}</span>}
        </Grid>
        <Grid item xs={3}>
          <b>Max amount</b>
        </Grid>
        <Grid item xs={9}>
          {merchantCurrency.serviceFeeMinPrice  && <span>{formatMoney(merchantCurrency.serviceFeeMinPrice)}</span>}
        </Grid>
      </Grid>
    </CardContent>
  </Card>
);

export default MerchantServiceFeeInfo;

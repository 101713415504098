import { FormGroup, FormControlLabel, Checkbox } from '@mui/material';
import { GridToolbarContainer } from '@mui/x-data-grid';
import { useEffect, useState } from 'react';
import PaymentGrid from '../../../components/PaymentGrid';
import type { PaymentWithPayouts } from './OfferTabs';

type Props = {
  offerId?: string;
  payments: PaymentWithPayouts[];
  loading: boolean;
};

const OfferPayments = (props: Props) => {
  const [showFailed, setShowFailed] = useState(
    JSON.parse(localStorage.getItem('showFailed') || 'false'),
  );

  useEffect(() => {
    localStorage.setItem('showFailed', JSON.stringify(showFailed));
  }, [showFailed]);

  function CustomToolbar() {
    return (
      <GridToolbarContainer sx={{ padding: 1 }}>
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox
                checked={showFailed}
                onChange={(event) => setShowFailed(event.target.checked)}
              />
            }
            label="Show failed payments"
          />
        </FormGroup>
      </GridToolbarContainer>
    );
  }

  const payments = showFailed
    ? props.payments
    : props.payments.filter((p) =>
        ['INIT', 'SETTLED', 'REFUNDED'].includes(p.status),
      );

  return (
    <PaymentGrid
      customerPaymentType="seller"
      loading={props.loading}
      rows={payments}
      hideFooter={true}
      components={{
        Toolbar: CustomToolbar,
      }}
      noBorder
    />
  );
};

export default OfferPayments;

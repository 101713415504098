import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { Button, Checkbox, FormControlLabel, FormGroup, TextField, Typography } from '@mui/material';
import { useQueryClient, useMutation } from 'react-query';
import Modal from '../../../components/Modal';
import { useAuthContext } from '../../../context/AuthContext';
import { useNotificationContext } from '../../../context/NotificationContext';
import { LoadingButton } from "@mui/lab";
import { AffiliateApi, UpsertAffiliateRequest } from '../../../api/AffiliateApi';


type Props = {
  affiliate: UpsertAffiliateRequest & {id?: string};
  close: () => void;
};

const EditAffiliateModal = ({ affiliate, close }: Props) => {
  const affiliateApi = new AffiliateApi();
  const [data, setData] = useState(affiliate);
  const { merchantId } = useParams<{ merchantId: string }>();
  const { user } = useAuthContext();
  const { setNotification } = useNotificationContext();
  const queryClient = useQueryClient();

  const affiliateMutation = useMutation(
    (data: UpsertAffiliateRequest & { id?: string }) => data.id ? affiliateApi.updateAffiliate(data.id, data) : affiliateApi.createAffiliate(data),
    {
      onError: () =>
        setNotification({
          severity: 'error',
          message: 'Something went wrong. please try again or contact support',
        }),
      onSettled: () => {
        queryClient.invalidateQueries(['merchantAffiliates', merchantId]);
        close();
      },
    },
  );

  const save = async (evt: any) => {
    evt.preventDefault();
    try {
      affiliateMutation.mutate(data);
    } catch (e) {
      setNotification({
        severity: 'error',
        message: 'Something went wrong. please try again or contact support',
      });
    }
  };

  const updateValue = (e: any) => {
    const { name, value } = e.target;
    setData((d: UpsertAffiliateRequest) => ({ ...d, [name]: value }));
  };

  const handleEnabledChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { checked, name } = event.target;
    setData((d: UpsertAffiliateRequest) => ({ ...d, [name]: checked }));
  };


  return (
    <Modal onClose={close}>
      <Typography component="h2" fontSize={23}>{data.id ? `Edit affiliate` : 'Add affiliate'}</Typography>
      <div style={{ margin: '16px 0px' }}>
        <div>
          <b>Merchant: </b>
          <span>
            {
              user.merchants?.find((m) => m.id === merchantId)
                ?.name
            }
          </span>
        </div>
        {data.id && <div>
          <b>Affiliate: </b>
          <span>{affiliate.name}</span>
        </div>}
        {!data.id && <div>
          <b>New Affiliate</b>
        </div>}
      </div>
      <form onSubmit={save}>
        <TextField
          id="name"
          label="Name"
          type="text"
          name="name"
          fullWidth
          required
          margin="dense"
          onChange={updateValue}
          value={data.name}
        />
        <TextField
          id="code"
          label="Code"
          type="text"
          name="code"
          fullWidth
          required
          margin="dense"
          onChange={updateValue}
          value={data.code}
        />
        <TextField
          id="website"
          label="Website"
          type="text"
          name="website"
          fullWidth
          margin="dense"
          onChange={updateValue}
          value={data.website}
        />
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox
                checked={data.enabled}
                name='enabled'
                onChange={handleEnabledChange}
              />
            }
            label="Enabled"
          />
        </FormGroup>
        <div style={{ marginTop: 12 }}>
          <LoadingButton
            type="submit"
            variant="contained"
            color="primary"
            sx={{ mr: 1, width: '150px' }}
            loading={affiliateMutation.isLoading}
          >
            Save
          </LoadingButton>
          <Button variant="outlined" sx={{ width: '150px' }} onClick={close}>
            Cancel
          </Button>
        </div>
      </form>
    </Modal>
  );
};

export default EditAffiliateModal;

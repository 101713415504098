import { createClient } from './client';

export class InvoiceApi {
  client;

  constructor() {
    this.client = createClient();
  }

  async getCustomerInvoices(customerId: string): Promise<Invoice[]> {
    const resp = await this.client.get(
      `/v1/back-office/customers/${customerId}/invoices`,
    );
    return resp.data;
  }
}

export type Invoice = {
  invoiceNr: string;
  personNr: string;
  firstName: string;
  lastName: string;
  accountId: string;
  product: string;
  securized: string;
  invoiceDate: string;
  isFirstInvoice: boolean;
  total: number;
  sent: string;
  sentTo: string;
  pushStatus: string;
  smsStatus: string;
};

import { useState, useEffect } from 'react';
import { PaymentApi, Payment } from '../../api/PaymentApi';
import SearchBar from '../../components/SearchBar';
import PaginationController from '../../components/PaginationController';
import PaymentGrid from '../../components/PaymentGrid';
import GridToolbar from '../../components/GridToolbar';
import { Typography } from '@mui/material';

const PaymentList = () => {
  const paymentApi = new PaymentApi();
  const [payments, setPayments] = useState<Payment[]>([]);
  const [existsMore, setExistsMore] = useState(false);
  const [page, setPage] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  const fetchPaymentList = async (q?: string) => {
    setIsLoading(true);
    const resp = await paymentApi.getPaymentList(page, q);
    setPayments(resp.payments);
    setExistsMore(resp.pageInfo.existsMore);
    setIsLoading(false);
  };

  useEffect(() => {
    fetchPaymentList();
  }, [page]);

  return (
    <>
      <Typography variant="h5" mb={2}>
        Payments
      </Typography>
      <SearchBar onSearch={fetchPaymentList}></SearchBar>
      <PaymentGrid
        height={800}
        topMargin
        loading={isLoading}
        rows={payments}
        components={{
          Toolbar: () => <GridToolbar columns density />,
          Pagination: () => (
            <PaginationController
              loading={isLoading}
              page={page}
              rowsCount={payments.length}
              existsMore={existsMore}
              decrease={() => setPage((p) => p - 1)}
              increase={() => setPage((p) => p + 1)}
            />
          ),
        }}
      />
    </>
  );
};

export default PaymentList;

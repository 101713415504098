import {
  Card,
  TableContainer,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material';
import { Link } from 'react-router-dom';
import { useAuthContext } from '../../../context/AuthContext';

const MerchantList = () => {
  const { user } = useAuthContext();

  return (
    <Card style={{ marginTop: 25 }}>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>Name</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {user.merchants.map((merchant) => (
              <TableRow key={merchant.id}>
                <TableCell width="40%" scope="row">
                  <Link to={`/merchants/${merchant.id}`}>{merchant.id}</Link>
                </TableCell>
                <TableCell scope="row">{merchant.name}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Card>
  );
};

export default MerchantList;

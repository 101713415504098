import { useState } from 'react';
import { styled } from '@mui/material/styles';
import {
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
} from '@mui/material';
import { Settings } from '@mui/icons-material';
import { CustomerApi, Customer } from '../../../api/CustomerApi';
import ActionConfirmationModal, {
  ActionModalProps,
} from '../../../components/ActionConfirmationModal';
import { useNotificationContext } from '../../../context/NotificationContext';
import payoutMethods from '../../../constants/payoutMethods';

const ButtonContentWrapper = styled('div')(
  ({ disabled }: { disabled?: boolean }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    color: disabled ? '#8C8DBA' : '#595388',
    width: '88px',
  }),
);

type Props = {
  customer: Customer;
  onDefaultPayoutMethodChange: (defaultPayoutMethod: string) => void;
};

const ChangeDefaultPayoutMethodButton = ({
  customer,
  onDefaultPayoutMethodChange,
}: Props) => {
  const [method, setMethod] = useState<string>(
    customer.defaultPayoutMethod || '',
  );
  const [actionModal, setActionModal] = useState<ActionModalProps>({
    text: '',
  });
  const { setNotification } = useNotificationContext();
  const customerApi = new CustomerApi();

  const updateValue = (e: any) => {
    const { value } = e.target;
    setMethod(() => value);
  };

  const changeDefaultPayoutMethod = async () => {
    try {
      if (method) {
        await customerApi.setDefaultPayoutMethod(customer.id, method);
      } else {
        await customerApi.deleteDefaultPayoutMethod(customer.id);
      }

      onDefaultPayoutMethodChange(method);

      setNotification({
        severity: 'success',
        message: 'Default payout method has been updated successfully',
      });
      setActionModal({ text: '' });
    } catch (error: any) {
      setNotification({
        severity: 'error',
        message: `Cannot update default payout method: ${error.message}`,
      });
    }
  };

  return (
    <>
      <IconButton
        size="large"
        onClick={() =>
          setActionModal({
            text: `Change default payout method`,
          })
        }
      >
        <ButtonContentWrapper>
          <Settings sx={{ fontSize: 40 }} />
          <span style={{ fontSize: 10 }}>Change default payout method</span>
        </ButtonContentWrapper>
      </IconButton>
      {actionModal.text && (
        <ActionConfirmationModal
          {...actionModal}
          action={changeDefaultPayoutMethod}
          close={() => setActionModal({ text: '' })}
        >
          <FormControl fullWidth>
            <Select
              name="method"
              value={method}
              label="Method *"
              onChange={updateValue}
              displayEmpty
            >
              <MenuItem value="">None</MenuItem>
              {Object.entries(payoutMethods).map(([key, value]) => (
                <MenuItem value={key}>{value}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </ActionConfirmationModal>
      )}
    </>
  );
};

export default ChangeDefaultPayoutMethodButton;

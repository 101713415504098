import { PhoneNumberUtil } from 'google-libphonenumber';

const defaultRegion = 'SE';

const phoneUtil = PhoneNumberUtil.getInstance();

const validatePhoneNumber = (
  candidateValue: string,
  errorMessage = 'Ange ett giltigt mobilnummer',
): boolean | string => {
  try {
    const number = phoneUtil.parse(candidateValue, defaultRegion);
    return phoneUtil.isValidNumber(number) || errorMessage;
  } catch {
    return errorMessage;
  }
};

export { validatePhoneNumber };

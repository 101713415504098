import {
  Card,
  CardContent,
  CardHeader,
  TableContainer,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Skeleton,
} from '@mui/material';
import { useQuery } from 'react-query';
import { GroupApi } from '../../../api/GroupApi';
import { useParams } from 'react-router-dom';
import formatDate from '../../../utils/format-date';

const GroupMembers = () => {
  const groupApi = new GroupApi();
  const { groupId } = useParams<{ groupId: string }>();

  const {
    data: groupMembersData,
    isLoading: isGroupMembersLoading,
    isFetching: isGroupMembersFetching,
  } = useQuery(['groupMembers', groupId], () =>
    groupApi.getGroupMembers(groupId),
  );

  if (isGroupMembersLoading || isGroupMembersFetching)
    return <Skeleton variant="rectangular" width={'100%'} height={150} />;

  return (
    <Card style={{ marginTop: 25 }}>
      <CardHeader title="Members" />
      <CardContent>
        <TableContainer style={{ maxHeight: 600 }}>
          <Table stickyHeader aria-label="users">
            <TableHead>
              <TableRow>
                <TableCell variant="head">Email</TableCell>
                <TableCell variant="head">Created at</TableCell>
                <TableCell variant="head">Inactive</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {groupMembersData?.map((member) => (
                <TableRow key={member.id}>
                  <TableCell>{member.email}</TableCell>
                  <TableCell>{formatDate(member.created_at)}</TableCell>
                  <TableCell>{member.inactive ? 'true' : 'false'}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </CardContent>
    </Card>
  );
};

export default GroupMembers;

import { Box, Checkbox, FormControlLabel, Typography } from '@mui/material';
import { GridFilterModel, GridSortModel } from '@mui/x-data-grid';
import isEqual from 'lodash/isEqual';
import { useEffect, useState } from 'react';
import { Offer, OfferApi, OfferStatus } from '../../api/OfferApi';
import { Sorting } from '../../api/shared';
import GridToolbar from '../../components/GridToolbar';
import OfferGrid from '../../components/OfferGrid';
import PaginationController from '../../components/PaginationController';
import SearchBar from '../../components/SearchBar';
import { useAuthContext } from '../../context/AuthContext';
import { useQuery } from 'react-query';
import { MerchantApi } from '../../api/MerchantApi';
import { useLocation } from 'react-router-dom';

const OfferList = () => {
  const authCtx = useAuthContext();
  const offerApi = new OfferApi();
  const merchantApi = new MerchantApi();
  const [offers, setOffers] = useState<Offer[]>([]);
  const [existsMore, setExistsMore] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState(0);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const initialQuery = queryParams.get('q') || '';

  const [query, setQuery] = useState(initialQuery);
  const [sorting, setSorting] = useState<GridSortModel>([
    { field: 'updatedAt', sort: 'desc' },
  ]);

  const [filterModel, setFilterModel] = useState<GridFilterModel>({
    items: [],
  });
  const [allMerchantsSearch, setAllMerchantsSearch] = useState<boolean>(
    JSON.parse(localStorage.getItem('allMerchantsSearch') || 'false'),
  );
  const [stuckOffersSearch, setStuckOffersSearch] = useState<boolean>(
    JSON.parse(localStorage.getItem('stuckOffersSearch') || 'false'),
  );

  const { data: merchantConfigData, isLoading: isMerchantConfigLoading } =
    useQuery(['merchantConfig', authCtx.merchantId], () =>
      merchantApi.getMerchantConfig(authCtx.merchantId),
    );

  useEffect(() => {
    localStorage.setItem(
      'allMerchantsSearch',
      JSON.stringify(allMerchantsSearch),
    );
  }, [allMerchantsSearch]);

  useEffect(() => {
    localStorage.setItem(
      'stuckOffersSearch',
      JSON.stringify(stuckOffersSearch),
    );
  }, [stuckOffersSearch]);

  const fetchOffers = async (
    s: Sorting,
    query?: string,
    status?: OfferStatus,
  ) => {
    setIsLoading(true);
    const resp = await offerApi.getOfferList({
      page,
      sorting: s,
      query,
      status,
      allMerchantsSearch,
      stuckOffersSearch,
    });
    setOffers(resp.offers);
    setExistsMore(resp.pageInfo.existsMore);
    setIsLoading(false);
  };

  useEffect(() => {
    setQuery(initialQuery); // Initialize the query with the URL parameter
  }, [initialQuery]);

  useEffect(() => {
    const s: Sorting = {
      field: sorting.length > 0 ? sorting[0].field : 'createdAt',
      order: sorting.length > 0 && sorting[0].sort ? sorting[0].sort : 'desc',
    };

    const statusFilter = filterModel.items.find(
      (i) => i.columnField === 'status',
    );
    fetchOffers(s, query, statusFilter?.value);
  }, [
    page,
    query,
    initialQuery,
    sorting,
    filterModel,
    allMerchantsSearch,
    stuckOffersSearch,
  ]);

  const handleSortModelChange = (newModel: GridSortModel) => {
    isEqual(sorting, newModel) || setSorting(newModel);
  };

  return (
    <div>
      <Typography variant="h5" mb={2}>
        Offers
      </Typography>
      <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
        <SearchBar onSearch={setQuery} initialQuery={initialQuery} />
        <Box sx={{ flex: 1 }}>
        {authCtx.can('GenericRockerAgentOperation') && (
          <>
            <FormControlLabel
              id="all-merchants-search-checkbox"
              name="all-merchants-search-checkbox"
              label="search all merchants"
              control={
                <Checkbox
                  checked={allMerchantsSearch}
                  onChange={(event) =>
                    setAllMerchantsSearch(event.target.checked)
                  }
                />
              }
            />
            <FormControlLabel
              id="stuck-offers-search-checkbox"
              name="stuck-offers-search-checkbox"
              label="show only stuck offers"
              control={
                <Checkbox
                  checked={stuckOffersSearch}
                  onChange={(event) =>
                    setStuckOffersSearch(event.target.checked)
                  }
                />
              }
            />
          </>
          )}
        </Box>
      </Box>
      <OfferGrid
        rows={offers}
        includeMerchant={allMerchantsSearch}
        style={{
          marginTop: 20,
          height: 800,
          width: '100%',
          background: '#fff',
        }}
        loading={isLoading}
        sortingMode="server"
        showSellerInfo={true}
        onSortModelChange={handleSortModelChange}
        onFilterModelChange={setFilterModel}
        sortModel={sorting}
        filterMode="server"
        components={{
          Toolbar: () => <GridToolbar filter columns density />,
          Pagination: () => (
            <PaginationController
              loading={isLoading}
              page={page}
              rowsCount={offers.length}
              existsMore={existsMore}
              decrease={() => setPage((p) => p - 1)}
              increase={() => setPage((p) => p + 1)}
            />
          ),
        }}
      />
    </div>
  );
};

export default OfferList;

import {
  Box,
  Card,
  CardContent,
  CardHeader,
  IconButton,
  List,
  TablePagination,
} from '@mui/material';
import { TablePaginationActionsProps } from '@mui/material/TablePagination/TablePaginationActions';
import {
  Download as DownloadIcon,
  NavigateBefore as NavigateBeforeIcon,
  NavigateNext as NavigateNextIcon,
} from '@mui/icons-material';
import LogEntry from './LogEntry';
import { useEffect, useState } from 'react';
import { AuditLog, PageInfo } from '../api/shared';
import formatDate from '../utils/format-date';

import pdfMake from 'pdfmake/build/pdfmake.js';
import pdfFonts from 'pdfmake/build/vfs_fonts.js';
import { TDocumentDefinitions } from 'pdfmake/interfaces';
import { useAuthContext } from '../context/AuthContext';

const AuditLogs = ({
  fetchLogs,
  rowsPerPage,
}: {
  fetchLogs: (
    page: number,
    rowsPerPage: number,
  ) => Promise<{ logs: AuditLog[]; pageInfo: PageInfo }>;
  rowsPerPage: number;
}) => {
  const [page, setPage] = useState(0);
  const [logs, setLogs] = useState<AuditLog[]>([]);
  const [existsMore, setExistsMore] = useState<boolean>(false);

  const {
    user: { isMerchantAdmin, isSuperAdmin },
  } = useAuthContext();

  useEffect(() => {
    const update = async () => {
      const response = await fetchLogs(page, rowsPerPage);
      setLogs(response.logs);
      setExistsMore(response.pageInfo.existsMore);
    };
    update().catch(console.error);
  }, [page]);

  function TablePaginationActions(props: TablePaginationActionsProps) {
    const { page, onPageChange } = props;

    const handleBackButtonClick = (
      event: React.MouseEvent<HTMLButtonElement>,
    ) => {
      onPageChange(event, page - 1);
    };

    const handleNextButtonClick = (
      event: React.MouseEvent<HTMLButtonElement>,
    ) => {
      const newPage = page + 1;
      onPageChange(event, newPage);
    };

    return (
      <Box sx={{ flexShrink: 0, ml: 2.5 }}>
        <IconButton
          onClick={handleBackButtonClick}
          disabled={page === 0}
          aria-label="previous page"
        >
          <NavigateBeforeIcon />
        </IconButton>
        <span>{page + 1}</span>
        <IconButton
          onClick={handleNextButtonClick}
          disabled={!existsMore}
          aria-label="next page"
        >
          <NavigateNextIcon />
        </IconButton>
      </Box>
    );
  }

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    page: number,
  ) => {
    setPage(page);
  };

  const handleDownloadPdf = (
    event: React.MouseEvent<HTMLButtonElement> | null,
  ) => {
    pdfMake.vfs = pdfFonts.pdfMake.vfs;
    const rows = logs.map((log) => [log.type, (log.author?.email ? `${log.author.email} ` : '') + '' + (log.author?.userIdType ? log.author?.userIdType : 'SYSTEM_JOB'), formatDate(log.time)])
    rows.unshift(['Event', 'User', 'Time'])
    const document: TDocumentDefinitions = {
      pageSize: 'A4',
      pageMargins: [20, 20],
      defaultStyle: { fontSize: 8 },
      content: {
        layout: 'lightHorizontalLines',
        table: {
          headerRows: 1,
          widths: ['*', 'auto', 100],
          body: rows
        }
      }
    };
    const now = new Date();
    const filename = `audit-logs-${formatDate(now.toISOString())}`
    pdfMake.createPdf(document).download(filename);
  };

  return (
    <Card style={{ marginTop: 25 }}>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <CardHeader title="Audit logs" />
        {(isMerchantAdmin || isSuperAdmin) && (
          <IconButton onClick={handleDownloadPdf}>
            <DownloadIcon />
          </IconButton>
        )}
      </div>
      <CardContent>
        {!logs || logs.length < 1 ? (
          <p>No logs</p>
        ) : (
          <List
            sx={{
              width: '100%',
              bgcolor: 'background.paper',
              maxHeight: 500,
              overflowY: 'scroll',
            }}
          >
            {logs.map((log) => (
              <LogEntry
                traceLogsExplorerUrl={log.traceLogsExplorerUrl}
                key={log.eventId}
                title={log.type}
                author={log.author}
                time={log.time}
                data={log.data}
              />
            ))}
          </List>
        )}
        <TablePagination
          rowsPerPageOptions={[100]}
          labelDisplayedRows={() => ''}
          component="div"
          count={logs.length}
          page={page}
          onPageChange={handleChangePage}
          rowsPerPage={rowsPerPage}
          ActionsComponent={TablePaginationActions}
        />
      </CardContent>
    </Card>
  );
};

export default AuditLogs;

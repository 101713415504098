import React, { createContext, useContext, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useQueryClient } from 'react-query';
import { initializeApp } from 'firebase/app';
import {
  getAuth,
  onAuthStateChanged,
  signOut,
  User,
  ParsedToken,
} from 'firebase/auth';
import { setCookie, removeCookie, getCookie } from '../utils/cookies';
import { Permissions, UserApi } from '../api/UserApi';
import { InviteApi } from '../api/InviteApi';
import useQuery from '../hooks/useQuery';
import LoginPage from '../scopes/Login';
import { useNotificationContext } from './NotificationContext';
import * as Sentry from "@sentry/react";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGEING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
};

const firebaseApp = initializeApp(firebaseConfig);

type AuthContextUser = User & {
  claims: ParsedToken & { merchant: string };
  merchants: Array<{ id: string; name: string }>;
  permittedOperations: Permissions;
  isSuperAdmin: boolean;
  isMerchantAdmin: boolean;
};

type AuthContextType = {
  merchantId: string;
  user: AuthContextUser;
  isAuthenticated: any;
  handleMerchantId: (id: string) => void;
  logout: () => void;
  can: (permissionName: string) => boolean;
};

const AuthContext = createContext({} as AuthContextType);

const AuthContextProvider: React.FC = ({ children }) => {
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState<any>();
  const [merchantId, setMerchantId] = useState(getCookie('merchantId'));
  const { setNotification } = useNotificationContext();
  const history = useHistory();
  const queryClient = useQueryClient();
  let query = useQuery();
  const userApi = new UserApi();
  const inviteApi = new InviteApi();

  const handleMerchantId = (id: string) => {
    setMerchantId(id);
    setCookie('merchantId', id);
    queryClient.invalidateQueries();
  };

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(
      getAuth(),
      async (user) => {
        if (user) {
          try {
            let claims: any;
            const tokenRes = await user.getIdTokenResult();
            claims = tokenRes.claims;

            const inviteToken = query.get('inviteToken');
            if (!claims?.merchant && inviteToken) {
              await inviteApi.consumeInvitation(inviteToken);
              await user.getIdToken(true);
              const tokenRes = await user.getIdTokenResult();
              claims = tokenRes.claims;
            }

            if (!claims?.merchant) {
              setLoading(false);
              setNotification({
                severity: 'error',
                message:
                  'Something went wrong. please try again or contact support',
              });
              logout();
              return;
            }

            if (!getCookie('merchantId') || !merchantId) {
              const merchantId = claims.merchant || '';
              setCookie('merchantId', merchantId);
              setMerchantId(merchantId);
            }

            let permittedOperations;
            let isSuperAdmin = false;
            let isMerchantAdmin = false
            try {
              const resp = await userApi.getUserPermittedOperations();
              permittedOperations = resp.permittedOperations;
              if (
                permittedOperations?.GenericSuperAdminOperation &&
                permittedOperations?.GenericSuperAdminOperation[0]?.$type ===
                'Global'
              )
                isSuperAdmin = true;
              else if (
                permittedOperations?.GenericMerchantAdminOperation &&
                permittedOperations?.GenericMerchantAdminOperation.some(item => item.merchantId === claims.merchant)
              )
                isMerchantAdmin = true;
            } catch (e) {
              Sentry.captureException(e);
              console.log(e);
            }

            let merchants: { id: string; name: string }[] = [];
            try {
              const resp = await userApi.getUserMerchants();
              merchants = resp.merchants;
            } catch (e) {
              Sentry.captureException(e);
              console.log(e);
            }

            setUser({
              ...user,
              claims,
              merchants,
              permittedOperations,
              isSuperAdmin,
              isMerchantAdmin,
            });
          } catch (e) {
            setNotification({
              severity: 'error',
              message:
                'Something went wrong. please try again or contact support',
            });
          }
        }
        setLoading(false);
      },
      (e) => {
        Sentry.captureException(e);
        console.log(e);
        setNotification({
          severity: 'error',
          message: 'Something went wrong. please try again or contact support',
        });
      },
    );
    return () => unsubscribe();
  }, []);

  const can = (permissionName: string) => {
    const allPermissions = user?.permittedOperations[permissionName] || [];
    const applicablePermissions = allPermissions.filter(
      (p: any) => p.$type === 'Global' || p.merchantId === merchantId,
    );

    return user?.isSuperAdmin || applicablePermissions.length > 0;
  };

  const logout = () => {
    signOut(getAuth());
    removeCookie('merchantId');
    setUser(undefined);
    history.push('/');
  };

  const state = {
    merchantId,
    user,
    isAuthenticated: user != null,
  };
  const methods = { can, handleMerchantId, logout };

  if (loading) return null;
  return (
    <AuthContext.Provider value={{ ...state, ...methods }}>
      {!user || loading ? <LoginPage /> : children}
    </AuthContext.Provider>
  );
};

const useAuthContext = () => useContext(AuthContext);

export { firebaseApp, AuthContextProvider, useAuthContext };
export default AuthContext;

import { useMemo, useState } from 'react';
import {
  FormControl,
  Button,
  Typography,
  TextField,
  InputAdornment,
  FormGroup,
  Box,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import {LoadingButton} from "@mui/lab";
import { useNotificationContext } from '../../../context/NotificationContext';
import Modal from '../../../components/Modal';
import { CreateOfferRequest, Offer, OfferApi, ServiceFeeItemInRequest, UpdateOfferRequest } from '../../../api/OfferApi';

type Props = {
  offerId?: string;
  initialData: OfferRequest;
  title: string;
  onSuccess: (offer: Offer) => void;
  close: () => void;
};

const NumberField = styled(TextField)({
  '& input[type=number]': {
    '-moz-appearance': 'textfield',
    '&::-webkit-outer-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
    '&::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
  },
});

type OfferRequest = CreateOfferRequest | UpdateOfferRequest

const errorMessages = {};
type ErrorMessageKey = keyof typeof errorMessages;

const OfferModal = ({offerId, initialData, title, onSuccess, close }: Props) => {
  const offerApi = new OfferApi();

  const [offerRequest, setOfferRequest] = useState<OfferRequest>(initialData);
  const [loading, setLoading] = useState<boolean>(false);
  const { setNotification } = useNotificationContext();
  const [isAmountNegative, setIsAmountNegative] = useState({ //keys match TextField name value
    'DELIVERY_FEE': false,
    'ESCROW_FEE': false,
    'SHIPPING_FEE': false,
    'sellerPrice': false,
    'buyerPrice': false
  });

  const createOffer = async (e: any) => {
    e.preventDefault();
    setLoading(true);

    if (!e.target.reportValidity()) {
      setLoading(false);
      return;
    }

    try {
      const updatedServiceFeeItems = offerRequest.serviceFeeItems.filter(
        feeItem => feeItem.value.amount > 0
      );

      const updatedCreateOfferRequest: OfferRequest = {
        ...offerRequest,
        serviceFeeItems: updatedServiceFeeItems,
      };
      const offer = offerId ? await offerApi.updateOffer(offerId, updatedCreateOfferRequest as UpdateOfferRequest) : await offerApi.createOffer(updatedCreateOfferRequest as CreateOfferRequest);
      
      setNotification({
        severity: 'success',
        message: `Offer ${offerId?'updated':'created'} successfully`,
      });
      onSuccess(offer);
      close();
    } catch (e: any) {
      const errorCode = e.response?.data?.errorCode as ErrorMessageKey;
      const message = errorMessages[errorCode] ?? `Failed to ${offerId?'update':'create'} offer`;

      setNotification({
        severity: 'error',
        message,
      });
    }
    setLoading(false);
  };

  const updateValue = (e: any) => {
    const { name, value } = e.target;
    setOfferRequest((u: OfferRequest) => ({ ...u, [name]: value }));
  };

  const handleSellerPriceChange = (e: any) => {
    const { name, value } = e.target;
    setIsAmountNegative({ ...isAmountNegative, [name]: value < 0 })
    setOfferRequest((u: OfferRequest) => ({ ...u, escrowValue: { amount: value * 100, currency: 'SEK', unit: 'MINOR' } }));
  };

  const handleFeeInputChange = (e: any) => {
    const { name, value } = e.target;
    setIsAmountNegative({ ...isAmountNegative, [name]: value < 0 })
    
    const feeItem: ServiceFeeItemInRequest =
      { name: name,
        value: {
          amount: value * 100,
          currency: 'SEK',
          unit: 'MINOR'
        }
      }
    const index = offerRequest.serviceFeeItems.findIndex(item => item.name === name);

    // If an item with the same name exists, replace it; otherwise, append the new fee item
    if (index !== -1) {
      // Replace the existing item at the found index with the new feeItem
      setOfferRequest((u: OfferRequest) => ({
        ...u,
        serviceFeeItems: [
          ...u.serviceFeeItems.slice(0, index), // Keep items before the replaced one
          feeItem, // Replace with the new feeItem
          ...u.serviceFeeItems.slice(index + 1), // Keep items after the replaced one
        ],
      }));
    } else {
      // Append the new feeItem to serviceFeeItems
      setOfferRequest((u: OfferRequest) => ({
        ...u,
        serviceFeeItems: [...u.serviceFeeItems, feeItem],
      }));
    }
  };

  function calculateTotalPrice(): number {
    let totalPrice = offerRequest.escrowValue.amount;

    for (const feeItem of offerRequest.serviceFeeItems) {
      totalPrice += feeItem.value.amount;
    }

    return totalPrice / 100;
  }

  const fees = useMemo(() => {
    const shippingFee = offerRequest.serviceFeeItems.find(item => item.name === 'SHIPPING_FEE')?.value.amount ?? 0;
    const deliveryFee = offerRequest.serviceFeeItems.find(item => item.name === 'DELIVERY_FEE')?.value.amount ?? 0;
    const salesCommission = offerRequest.serviceFeeItems.find(item => item.name === 'ESCROW_FEE')?.value.amount ?? 0;

    return {
      shippingFee: shippingFee > 0 ?  shippingFee / 100 : undefined,
      deliveryFee: deliveryFee > 0 ? deliveryFee / 100 : undefined,
      salesCommission: salesCommission > 0 ? salesCommission / 100 : undefined,
    };
  }, [offerRequest.serviceFeeItems]);

  return (
    <Modal onClose={close} style={{ padding: '20px 30px' }}>
      <form onSubmit={createOffer}>
        <Box sx={{gap: 2, display: 'flex', flexDirection: 'column'}}>
          <Typography component="h2" fontSize={23}>{title}:</Typography>
          <TextField
            id="title"
            label="Title"
            type="text"
            name="title"
            required
            fullWidth
            sx={{margin: 0}}
            margin="dense"
            onChange={updateValue}
            value={offerRequest.title}
          />
          <TextField
            id="description"
            label="Description"
            type="text"
            name="description"
            fullWidth
            sx={{ margin: 0 }}
            margin="dense"
            onChange={updateValue}
            value={offerRequest.description}
          />
          <NumberField
            id='seller_amount'
            label="Seller price"
            type='number'
            required
            name='sellerPrice'
            onChange={handleSellerPriceChange}
            value={offerRequest.escrowValue.amount > 0 ? offerRequest.escrowValue.amount / 100 : undefined}
            placeholder='0'
            fullWidth
            InputProps={{
              endAdornment: <InputAdornment position="end">kr</InputAdornment>,
            }}
            InputLabelProps={{ shrink: true }}
            error={isAmountNegative.sellerPrice}
          />
          <NumberField
            id='buyer_amount'
            label="Buyer price"
            type='number'
            required
            name='buyerPrice'
            disabled
            value={offerRequest.escrowValue.amount > 0 ? calculateTotalPrice() : undefined}
            placeholder='0'
            fullWidth
            InputProps={{
              endAdornment: <InputAdornment position="end">kr</InputAdornment>,
            }}
            InputLabelProps={{ shrink: true }}
            error={isAmountNegative.buyerPrice}
          />
          <FormControl fullWidth margin="normal">
            <FormGroup sx={{ gap: 2, display: 'flex', flexDirection: 'column' }}>
              <Typography component="h4" fontSize={17}> Fees </Typography>
              <NumberField
                id='sales_commission'
                label="Sales commission"
                type='number'
                placeholder='0'
                onChange={handleFeeInputChange}
                name='ESCROW_FEE'
                value={fees.salesCommission}
                fullWidth
                InputProps={{
                  endAdornment: <InputAdornment position="end">kr</InputAdornment>,
                }}
                InputLabelProps={{ shrink: true }}
                error={isAmountNegative.ESCROW_FEE}
              />
              <NumberField
                id='delivery_fee'
                label="Delivery fee"
                type='number'
                placeholder='0'
                onChange={handleFeeInputChange}
                name='DELIVERY_FEE'
                value={fees.deliveryFee}
                fullWidth
                InputProps={{
                  endAdornment: <InputAdornment position="end">kr</InputAdornment>,
                }}
                InputLabelProps={{ shrink: true }}
                error={isAmountNegative.DELIVERY_FEE}
              />
              <NumberField
                id='shipping_fee'
                label="Shipping fee"
                type='number'
                placeholder='0'
                onChange={handleFeeInputChange}
                name='SHIPPING_FEE'
                value={fees.shippingFee}
                fullWidth
                InputProps={{
                  endAdornment: <InputAdornment position="end">kr</InputAdornment>,
                }}
                InputLabelProps={{ shrink: true }}
                error={isAmountNegative.SHIPPING_FEE}
                  />
            </FormGroup>
          </FormControl>
        </Box>

        <LoadingButton
          type="submit"
          variant="contained"
          color="primary"
          loading={loading}
          fullWidth
          sx={{ mt: 2 }}
        >
          {offerId ? 'Update' : 'Create'} offer
        </LoadingButton>
        <Button variant="outlined" fullWidth sx={{ mt: 1 }} onClick={close}>
          Cancel
          </Button>
      </form>
    </Modal>
  );
};

export default OfferModal;

import { useState, useEffect } from 'react';
import {
  Box,
  Collapse,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import formatDate from '../../../utils/format-date';
import { CustomerApi, LoanApplication } from '../../../api/CustomerApi';
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import formatMoney from '../../../utils/format-money';
import formatDuration from '../../../utils/format-duration';

type Props = {
  customerId: string;
};

function Row(props: { application: LoanApplication }) {
  const { application } = props;
  const [open, setOpen] = useState(false);

  return (
    <>
      <TableRow key={application.id}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
          </IconButton>
        </TableCell>
        <TableCell>{application.id}</TableCell>
        <TableCell>{application.status}</TableCell>
        <TableCell>{application.score ? application.score.decision.$type : "In progress"}</TableCell>
        <TableCell>{application.quotation && formatMoney(application.quotation.amount)}</TableCell>
        <TableCell align="right">{formatDate(application.createdAt)}</TableCell>
        <TableCell align="right">{application.signature && formatDate(application.signature.signedAt)}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Typography variant="h6" gutterBottom component="div">
                Credit score
              </Typography>
              {application.score ? (
                <>
                    <p>ID: {application.score.id}</p>
                    <p>Decision: {application.score.decision.$type}</p>
                    {application.score.decision.reason && <p>Reason: {application.score.decision.reason}</p>}
                    {application.score.decision.creditLimit && <p>Credit limit: { formatMoney(application.score.decision.creditLimit)}</p>}
                    {application.score.decision.probabilityOfDefault && <p>Probability of default: {application.score.decision.probabilityOfDefault}</p>}
                </>
              ) : (
                "Application has not been scored yet"
              )}

              <Typography variant="h6" gutterBottom component="div">
                Quotation
              </Typography>
              {application.quotation ? (
                <>
                    <p>Amount: {formatMoney(application.quotation.amount)}</p>
                    <p>Amortization period: {application.quotation.amortizationPeriodMonths} months</p>
                    <p>Nominal interest rate: {application.quotation.nominalInterestRate}%</p>
                    <p>Effective interest rate: {application.quotation.effectiveInterestRate}%</p>
                    <p>Administration fee: {formatMoney(application.quotation.administrationFee)}</p>
                    <p>Setup fee: {formatMoney(application.quotation.setupFee)}</p>
                    <p>Monthly payment: {formatMoney(application.quotation.monthlyPay)}</p>
                    <p>Total to pay: {formatMoney(application.quotation.totalToPay)}</p>
                    <p>Grace period: {formatDuration(application.quotation.gracePeriod)}</p>
                    <p>Quoted at: {formatDate(application.quotation.createdAt)}</p>
                </>
              ) : "Application has not been quoted yet"}

              <Typography variant="h6" gutterBottom component="div">
                Signature
              </Typography>
              {application.signature ? (
                <>
                    <p>IP address: {application.signature.ipAddress}</p>
                    <p>Signed at: {formatDate(application.signature.signedAt)}</p>
                </>
              ) : "Application has not been signed yet"}
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}

const LoanApplicationList = (props: Props) => {
  const [loanApplications, setLoanApplications] = useState<LoanApplication[]>(
    [],
  );
  const customerApi = new CustomerApi();

  const fetchInvoices = async () => {
    const loanApplications = await customerApi.getLoanApplications(
      props.customerId,
    );
    setLoanApplications(loanApplications.loanApplications);
  };

  useEffect(() => {
    fetchInvoices();
  }, [props.customerId]);

  return (
    <TableContainer component={Paper}>
      <Table aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell />
            <TableCell>ID</TableCell>
            <TableCell>Status</TableCell>
            <TableCell>Score</TableCell>
            <TableCell>Amount</TableCell>
            <TableCell align="right">Created at</TableCell>
            <TableCell align="right">Signed at</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {loanApplications.map((application) => (
            <Row key={application.id} application={application} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default LoanApplicationList;

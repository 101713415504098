import { Button, ButtonProps, CircularProgress } from '@mui/material';

type ButtonWithSpinnerProps = ButtonProps & {
  loading: boolean;
};

export default function ButtonWithSpinner(props: ButtonWithSpinnerProps) {
  const { loading, ...buttonProps } = props;

  return (
    <div>
      <Button {...buttonProps} disabled={loading}>
        {props.children}
      </Button>
      {loading && <CircularProgress size={24} />}
    </div>
  );
}

import {
  AuditLog,
  MoneyMinor,
  PageInfo,
  Sorting,
  sortingParam,
} from './shared';
import { createClient } from './client';
import { PayoutAccount } from './PayoutApi';

export class CustomerApi {
  client;

  constructor() {
    this.client = createClient();
  }

  async getCustomer(customerId: string): Promise<Customer> {
    const resp = await this.client.get(
      `/v1/back-office/customers/${customerId}`,
    );
    return resp.data;
  }

  async setDefaultPayoutMethod(
    customerId: string,
    payoutMethod: string,
  ): Promise<void> {
    return this.client.post(
      `/v1/back-office/customers/${customerId}/default-payout-method`,
      { payoutMethod: payoutMethod },
    );
  }

  async deleteDefaultPayoutMethod(customerId: string): Promise<void> {
    return this.client.delete(
      `/v1/back-office/customers/${customerId}/default-payout-method`,
    );
  }

  async getCustomerLogs(
    customerId: string,
    page: number,
    fetchSize: number,
  ): Promise<{ logs: AuditLog[]; pageInfo: PageInfo }> {
    const queryParams: { [key: string]: string } = {
      page: page.toString(),
      pageSize: fetchSize.toString(),
    };
    const resp = await this.client.get(
      `/v1/back-office/customers/${customerId}/audit-log?${new URLSearchParams(
        queryParams,
      )}`,
    );
    return resp.data;
  }

  async updateCustomer(
    customerId: string,
    customerUpdate: CustomerUpdate,
  ): Promise<Customer> {
    const resp = await this.client.patch(
      `/v1/back-office/customers/${customerId}`,
      customerUpdate,
    );
    return resp.data;
  }

  async createCustomer(request: CreateCustomerRequest): Promise<Customer> {
    const resp = await this.client.post(
      `/v1/back-office/admin/users`,
      request,
    );
    return resp.data;
  }

  async getCustomerList(
    page: number,
    sorting: Sorting,
    query?: string,
    allMerchantsSearch?: boolean,
  ): Promise<{
    users: Customer[];
    pageInfo: PageInfo;
  }> {
    const queryParams: any = {
      page: page.toString(),
      pageSize: '100',
      sorting: sortingParam(sorting),
    };

    if (query) {
      queryParams.query = query;
    }
    if (allMerchantsSearch) {
      queryParams.allMerchants = allMerchantsSearch || false;
    }
    const resp = await this.client.get(
      `/v1/back-office/customers?${new URLSearchParams(queryParams)}`,
    );

    return resp.data;
  }

  async getUserBlockEntries(nationalIdNumber: string): Promise<{
    nationalIdNumber: string;
    blockEntries: [{ blockId: string; blockedAt: string; merchantId: string }];
  }> {
    const resp = await this.client.get(
      `/v1/back-office/customers/user-block-entries?nationalIdNumber=${nationalIdNumber}`,
    );
    return resp.data;
  }

  async getBlockedUser(nationalIdNumber: string): Promise<BlockedUser> {
    const resp = await this.client.get(
      `/v1/back-office/customers/blocked-users/${nationalIdNumber}`,
    );
    return resp.data;
  }

  async blockUserMerchant(nationalIdNumber: string): Promise<any> {
    const resp = await this.client.post(
      `/v1/back-office/customers/blocked-users/${nationalIdNumber}/block-for-merchant`,
    );
    return resp.data;
  }

  async removeBlockEntry(entryId: string): Promise<any> {
    const resp = await this.client.delete(
      `/v1/back-office/customers/user-block-entries/${entryId}`,
    );
    return resp.data;
  }

  async blockUserGlobal(nationalIdNumber: string): Promise<any> {
    const resp = await this.client.post(
      `/v1/back-office/customers/blocked-users/${nationalIdNumber}/block-globally`,
    );
    return resp.data;
  }

  async removeGlobalBlockEntry(entryId: string): Promise<any> {
    const resp = await this.client.delete(
      `/v1/back-office/customers/user-block-entries/${entryId}/global`,
    );
    return resp.data;
  }

  async sendPayoutLink(userId: string): Promise<any> {
    const resp = await this.client.post(
      `/v1/back-office/customers/${userId}/payout-account-selection`,
    );
    return resp.data;
  }

  async addBankAccount(
    customerId: string,
    clearingNumber: string,
    accountNumber: string,
    accountName: string,
    bankName: string,
    verificationStatus: string,
  ): Promise<PayoutAccount> {
    const identifier = { clearingNumber, accountNumber };
    const resp = await this.client.post(
      `/v1/back-office/customers/${customerId}/payout-accounts/rix`,
      { identifier, accountName, bankName, verificationStatus },
    );
    return resp.data;
  }

  async addSwishAccount(
    customerId: string,
    nationalIdNumber: string,
    phoneNumber: string,
  ): Promise<PayoutAccount> {
    const resp = await this.client.post(
      `/v1/back-office/customers/${customerId}/payout-accounts/swish`,
      { nationalIdNumber, phoneNumber },
    );
    return resp.data;
  }

  async deletePayoutAccount(
    customerId: string,
    payoutAccountId: string,
  ): Promise<void> {
    await this.client.delete(
      `/v1/back-office/customers/${customerId}/payout-accounts/${payoutAccountId}`,
    );
  }

  async updatePayoutAccount(
    customerId: string,
    payoutAccountId: string,
    verificationStatus: string,
  ): Promise<void> {
    await this.client.patch(
      `/v1/back-office/customers/${customerId}/payout-accounts/${payoutAccountId}`,
      { verificationStatus },
    );
  }

  async getLoanApplications(
    customerId: string,
  ): Promise<{ loanApplications: LoanApplication[] }> {
    const resp = await this.client.get(
      `/v1/back-office/customers/${customerId}/loan-applications`,
    );
    return resp.data;
  }

  async getLoanApplication(
    customerId: string,
    loanApplicationId: string,
  ): Promise<LoanApplication> {
    const resp = await this.client.get(
      `/v1/back-office/customers/${customerId}/loan-applications/${loanApplicationId}`,
    );
    return resp.data;
  }
}

export type LoanApplication = {
  id: string;
  status: string;
  score?: {
    id: string;
    applicant: {
      firstName?: string;
      lastName?: string;
      address?: Address;
    };
    decision: {
      $type: string;
      reason?: string;
      creditLimit?: MoneyMinor;
      probabilityOfDefault?: number;
    };
    createdAt: string;
  };
  quotation?: {
    amount: MoneyMinor;
    amortizationPeriodMonths: number;
    nominalInterestRate: number;
    effectiveInterestRate: number;
    administrationFee: MoneyMinor;
    setupFee: MoneyMinor;
    monthlyPay: MoneyMinor;
    totalToPay: MoneyMinor;
    gracePeriod: string;
    createdAt: string;
  };
  signature?: {
    ipAddress: string;
    signedAt: string;
  };
  createdAt: string;
};

export type BlockedUser = {
  nationalIdNumber: string;
  isBlocked: boolean;
  isBlockedGlobally: boolean;
  blockedAt?: string;
};

export type PersonalIdentificationNumber = {
  value: string;
  standard: PersonalIdentificationStandard;
};

export type PhoneNumber = {
  nationalNumber: string;
  countryCode: number;
};

export type Address = {
  streetAddress: string;
  deliveryAddress?: string;
  invoiceAddress?: string;
  zipCode: string;
  city: string;
};

export type CustomerType = 'AUTHENTICATED_USER' | 'COMPANY_USER' | 'FOREIGN_USER';

export type PersonalIdentificationStandard = 'FINNISH_HETU' | 'SWEDISH_PERSONAL_IDENTITY_NUMBER';

export type Customer = {
  id: string;
  merchantId: string;
  userType: CustomerType;
  nationalIdNumber: string;
  personalIdentificationNumber: PersonalIdentificationNumber,
  registrationNumber?: string;
  foreignUserId?: string;
  email: string;
  firstName: string;
  lastName: string;
  companyName: string;
  ip: string;
  phone: string;
  address: Address | undefined;
  defaultPayoutMethod?: string;
  createdAt: string;
};

export interface CreateCustomerRequest  {
  personalIdentificationNumber: PersonalIdentificationNumber;
  firstName: string;
  lastName: string;
  country: string;
  email: string;
  phone?: string;
  externalData: { [key: string]: string };
}

export type CustomerUpdate = Partial<
  Pick<Customer, 'email' | 'phone' | 'address'>
>;

import {Tooltip} from '@mui/material';
import {
  DataGrid,
  DataGridProps,
  GridColDef,
  GridFilterInputSingleSelect,
  GridFilterItem,
} from '@mui/x-data-grid';
import {Link} from 'react-router-dom';
import {Offer} from '../api/OfferApi';
import StatusLabel from '../components/StatusLabel';
import formatDate from '../utils/format-date';
import formatMoney from '../utils/format-money';

type Props = Omit<DataGridProps, 'columns' | 'rows'> & {
  rows: Offer[];
  includeMerchant?: boolean;
  showSellerInfo?: boolean;
};
const OfferList = (props: Props) => {
  return (
    <DataGrid
      {...props}
      style={{
        marginTop: 20,
        height: 800,
        width: '100%',
        background: '#fff',
      }}
      rows={props.rows}
      columns={columns(props.includeMerchant || false, props.showSellerInfo || false)}
      disableSelectionOnClick={true}
    />
  );
};

export default OfferList;

function columns(includeMerchant: boolean, showSellerInfo: boolean): Array<GridColDef> {
  return [
    {
      field: 'merchantName',
      headerName: 'Merchant',
      width: 128,
      renderCell: (p: any) => <Link
        to={`/merchants/${p.row.merchantId}`}>{p.row.merchantName}</Link>,
      disableColumnMenu: true,
      hide: !includeMerchant,
      filterable: false,
      sortable: false,
    },
    {
      field: 'id',
      headerName: 'Offer ID',
      width: 330,
      renderCell: (p: any) => <Link
        to={`/offers/${p.row.id}`}>{p.row.id}</Link>,
      disableColumnMenu: true,
      hide: true,
      filterable: false,
    },
    {
      field: 'title',
      headerName: 'Title',
      width: 240,
      renderCell: (p: any) => (
        <Tooltip title={p.row.title}>
          <Link to={`/offers/${p.row.id}`}>{p.row.title}</Link>
        </Tooltip>
      ),
      disableColumnMenu: true,
      filterable: false,
    },
    {
      field: showSellerInfo ? 'seller.id' : 'buyer.id',
      headerName: showSellerInfo ? 'Seller ID' : 'Buyer ID',
      width: 290,
      renderCell: (p: any) => (
        <Link to={`/customers/${showSellerInfo ? p.row.seller.id : p.row.buyer?.id}`}>{showSellerInfo ? p.row.seller.id : p.row.buyer?.id}</Link>
      ),
      sortable: false,
      disableColumnMenu: true,
      hide: true,
      filterable: false,
    },
    {
      field: showSellerInfo ? 'seller.name' : 'buyer.name',
      headerName: showSellerInfo ? 'Seller' : 'Buyer',
      width: 200,
      renderCell: (p: any) => (
        <Tooltip title={p.row.seller.name}>
          <Link to={`/customers/${showSellerInfo ? p.row.seller.id : p.row.buyer?.id}`}>{showSellerInfo ? p.row.seller.name : p.row.buyer?.name}</Link>
        </Tooltip>
      ),
      sortable: false,
      disableColumnMenu: true,
      filterable: false,
    },
    {
      field: showSellerInfo ? 'seller.email' : 'buyer.email',
      headerName: showSellerInfo ? 'Seller email' : 'Buyer email',
      width: 200,
      renderCell: (p: any) => (
        <Tooltip title={p.row.seller.email}>
          <Link to={`/customers/${showSellerInfo ? p.row.seller.id : p.row.buyer?.id}`}>{showSellerInfo ? p.row.seller.email : p.row.buyer?.email}</Link>
        </Tooltip>
      ),
      sortable: false,
      disableColumnMenu: true,
      hide: true,
      filterable: false,
    },
    {
      field: 'price',
      headerName: 'Price',
      width: 120,
      valueGetter: (p: any) => formatMoney(p.row.price),
      sortable: false,
      disableColumnMenu: true,
      filterable: false,
    },
    {
      field: 'status',
      headerName: 'Status',
      width: 150,
      type: 'singleSelect',
      filterOperators: [
        {
          value: 'is',
          // Copy-pasta since the singleSelect operators is not exported for some reason
          getApplyFilterFn: (filterItem: GridFilterItem) => {
            if (filterItem.value == null || filterItem.value === '') {
              return null;
            }

            return ({value}: any): boolean => {
              if (typeof value === 'object') {
                return (
                  filterItem.value ===
                  (value as { value: any; label: string }).value
                );
              }

              return filterItem.value === value;
            };
          },
          InputComponent: GridFilterInputSingleSelect
        },
      ],
      valueOptions: [
        'AVAILABLE',
        'BOOKED',
        'IN_PAYOUT',
        'WAITING_ON_RENEW',
        'FINISHED_SOLD',
        'FINISHED_DELETED',
      ],
      renderCell: (p: any) => <StatusLabel status={p.row.status}/>,
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: 'createdAt',
      headerName: 'Created at',
      width: 140,
      renderCell: (p) => formatDate(p.row.createdAt),
      disableColumnMenu: true,
      filterable: false,
    },
    {
      field: 'updatedAt',
      headerName: 'Updated at',
      width: 150,
      renderCell: (p) => formatDate(p.row.updatedAt),
      disableColumnMenu: true,
      filterable: false,
    },
    {
      field: 'externalData',
      headerName: 'External data',
      width: 300,
      renderCell: (p) => {
        if (!p.row.externalData) return null;

        const items = (Object.entries(p.row.externalData) || [])
          .sort(([k, v], [k2, v2]) => k.localeCompare(k2))
          .map(([k, v]) => `${k}: ${v}`);

        return (
          <Tooltip
            title={
              <div style={{display: 'flex', flexDirection: 'column'}}>
                {items.map((item) => (
                  <span>{item}</span>
                ))}
              </div>
            }
          >
            <div>
              {items.map((item, i) => (
                <span>{i === 0 ? item : `, ${item}`}</span>
              ))}
            </div>
          </Tooltip>
        );
      },
      disableColumnMenu: true,
      filterable: false,
      hide: true,
    },
    {
      field: 'productType',
      headerName: 'Product Type',
      width: 240,
      renderCell: (p) => p.row.productType,
      disableColumnMenu: true,
      filterable: false,
    },
  ]
};

import { useState } from 'react';
import {
  Button,
  Typography,
  TextField,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import {LoadingButton} from "@mui/lab";
import { useNotificationContext } from '../../../context/NotificationContext';
import Modal from '../../../components/Modal';
import { CreateCustomerRequest, Customer, CustomerApi } from '../../../api/CustomerApi';
import validator from 'validator';
import personnummer from 'personnummer';

type Props = {
  onSuccess: (customer: Customer) => void;
  close: () => void;
};

const errorMessages = {};
type ErrorMessageKey = keyof typeof errorMessages;

const CreateCustomerModal = ({ onSuccess, close }: Props) => {
  const customerApi = new CustomerApi();

  const [createCustomerRequest, setCreateCustomerRequest] = useState<CreateCustomerRequest>({
    personalIdentificationNumber: {
      value: '',
      standard: 'SWEDISH_PERSONAL_IDENTITY_NUMBER'
    },
    firstName: '',
    lastName: '',
    country: 'SE',
    email: '',
    externalData: {}
  });

  function allCustomerInputsAreFilled() {
    return createCustomerRequest.personalIdentificationNumber.value && createCustomerRequest.firstName && createCustomerRequest.lastName && createCustomerRequest.email;
  }

  const [validationErrors, setValidationErrors] = useState({
    email: false,
    ssn: false,
    phone: false
  });

  const [loading, setLoading] = useState<boolean>(false);
  const { setNotification } = useNotificationContext();
  
  const createCustomer = async (e: any) => {
    e.preventDefault();
    setLoading(true);

    if (!e.target.reportValidity()) {
      setLoading(false);
      return;
    }

    try {
      const customer = await customerApi.createCustomer(createCustomerRequest);

      setNotification({
        severity: 'success',
        message: 'Customer created successfully',
      });
      onSuccess(customer);
      close();
    } catch (e: any) {
      const errorCode = e.response?.data?.errorCode as ErrorMessageKey;
      const message = errorMessages[errorCode] ?? 'Failed to create customer';

      setNotification({
        severity: 'error',
        message,
      });
    }
    setLoading(false);
  };

  const updateValue = (e: any) => {
    const { name, value } = e.target;
    setCreateCustomerRequest((u: CreateCustomerRequest) => ({ ...u, [name]: value }));
  };

  const updateSsnValue = (e: any) => {
    const { name, value } = e.target;
    setCreateCustomerRequest((u: CreateCustomerRequest) => ({ ...u, personalIdentificationNumber: { value: value, standard: 'SWEDISH_PERSONAL_IDENTITY_NUMBER'} }));
  };

  return (
    <Modal onClose={close} style={{ padding: '20px 30px' }}>
      <form onSubmit={createCustomer}>
        <Typography component="h2" fontSize={23}>
          Create new customer:
        </Typography>
        <TextField
          id="swedish_ssn"
          label="Person number"
          type="text"
          name="swedish_ssn"
          required
          fullWidth
          margin="dense"
          onChange={updateSsnValue}
          onFocus={() => setValidationErrors({ ...validationErrors, ssn: false })}
          onBlur={() => personnummer.valid(createCustomerRequest.personalIdentificationNumber.value) ? null : setValidationErrors({ ...validationErrors, ssn: true }) }
          value={createCustomerRequest.personalIdentificationNumber.value}
          error={validationErrors.ssn}
          helperText={validationErrors.ssn ? 'Invalid person number' : ''}
        />
        <TextField
          id="first_name"
          label="First name"
          type="text"
          name="firstName"
          required
          fullWidth
          margin="dense"
          onChange={updateValue}
          value={createCustomerRequest.firstName}
        />
        <TextField
          id="last_name"
          label="Last name"
          type="text"
          name="lastName"
          required
          fullWidth
          margin="dense"
          onChange={updateValue}
          value={createCustomerRequest.lastName}
        />
        <TextField
          id="email"
          label="Email"
          type="text"
          name="email"
          required
          fullWidth
          margin="dense"
          onChange={updateValue}
          onFocus={() => setValidationErrors({ ...validationErrors, email: false })}
          onBlur={() => validator.isEmail(createCustomerRequest.email) ? null : setValidationErrors({ ...validationErrors, email: true }) }
          helperText={validationErrors.email ? 'Invalid email' : ''}
          error={validationErrors.email}
          value={createCustomerRequest.email}
        />
        <TextField
          id="phone"
          label="Phone"
          type="text"
          name="phone"
          fullWidth
          margin="dense"
          onChange={updateValue}
          onFocus={() => setValidationErrors({ ...validationErrors, phone: false })}
          onBlur={() => (createCustomerRequest.phone && validator.isMobilePhone(createCustomerRequest.phone, "sv-SE")) ? null : setValidationErrors({ ...validationErrors, phone: true }) }
          helperText={validationErrors.phone ? 'Invalid phone number' : ''}
          error={validationErrors.phone}
          value={createCustomerRequest.phone}
        />

        <LoadingButton
          type="submit"
          variant="contained"
          color="primary"
          loading={loading}
          fullWidth
          sx={{ mt: 2 }}
          disabled={!allCustomerInputsAreFilled() || validationErrors.email || validationErrors.ssn || validationErrors.phone || loading}
        >
          Create customer
        </LoadingButton>
        <Button variant="outlined" fullWidth sx={{ mt: 1 }} onClick={close}>
          Cancel
        </Button>
      </form>
    </Modal>
  );
};

export default CreateCustomerModal;

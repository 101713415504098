import InviteList from './components/InviteList';
import AgentList from './components/AgentList';
import { Typography } from '@mui/material';

const Agents = () => (
  <>
    <Typography variant="h5">Agents</Typography>
    <InviteList />
    <AgentList />
  </>
);

export default Agents;

import { Card, CardHeader, CardContent, Grid } from '@mui/material';
import { MerchantCurrency } from '../../../api/MerchantApi';
import formatMoney from '../../../utils/format-money';

const MerchantOfferInfo = ({ merchantCurrency }: { merchantCurrency: MerchantCurrency }) => (
  <Card>
    <CardHeader
      title="Offer details:"
      titleTypographyProps={{ fontSize: 16 }}
    />
    <CardContent>
      <Grid container spacing={0.5}>
        <Grid item xs={3}>
          <b>Min price</b>
        </Grid>
        <Grid item xs={9}>
          <span>{formatMoney(merchantCurrency.offerMinPrice)}</span>
        </Grid>
        <Grid item xs={3}>
          <b>Max price</b>
        </Grid>
        <Grid item xs={9}>
          <span>{formatMoney(merchantCurrency.offerMaxPrice)}</span>
        </Grid>
      </Grid>
    </CardContent>
  </Card>
);

export default MerchantOfferInfo;

import { Duration } from "luxon";

const formatDuration = (duration: string) => {
  // this could be simplified once Temporal.Duration become standard ECMAScript API
  // see: https://tc39.es/proposal-temporal/docs/duration.html
  // return Temporal.Duration.from(duration).toLocaleString(locale);

  return Duration.fromISO(duration).rescale().toHuman();
};

export default formatDuration;

import { Tooltip, Typography } from '@mui/material';
import { DataGrid, DataGridProps, GridColDef } from '@mui/x-data-grid';
import { Link } from 'react-router-dom';
import formatDate from '../utils/format-date';
import formatMoney from '../utils/format-money';
import StatusLabel from './StatusLabel';
import { CustomerPaymentType } from '../scopes/customer/components/CustomerPayments';
import { useMemo } from 'react';
import PaymentActions from '../scopes/payment/components/PaymentActions';
import { useAuthContext } from '../context/AuthContext';
import merchantIds from '../constants/merchantIds';

const getColumns = ({
  isSuperAdmin,
  isRockerAgent,
  customerPaymentType,
}: {
  isSuperAdmin: boolean;
  isRockerAgent: boolean;
  customerPaymentType?: CustomerPaymentType;
}): Array<GridColDef> => [
  {
    field: 'actions',
    headerName: 'Actions',
    width: 150,
    disableColumnMenu: true,
    filterable: false,
    renderCell: (p) => <PaymentActions payment={p.row} />,
  },
  {
    field: 'id',
    headerName: 'Payment ID',
    width: 150,
    sortable: false,
    disableColumnMenu: true,
    renderCell: ({ row }) => (
      <Tooltip title={row.id}>
        <p>{row.id}</p>
      </Tooltip>
    ),
  },
  {
    field: 'amount',
    headerName: 'Price',
    width: 130,
    sortable: false,
    disableColumnMenu: true,
    renderCell: (p) => formatMoney(p.row.amount),
  },
  {
    field: 'buyer.id',
    headerName: 'Buyer ID',
    width: 300,
    sortable: false,
    disableColumnMenu: true,
    hide: true,
    renderCell: (p) => (
      <Link to={`/customers/${p.row.buyer.id}`}>{p.row.buyer.id}</Link>
    ),
  },
  {
    hide: customerPaymentType === 'buyer',
    field: 'buyer.name',
    headerName: 'Buyer',
    width: 180,
    sortable: false,
    disableColumnMenu: true,
    renderCell: (p) => (
      <Link to={`/customers/${p.row.buyer.id}`}>{p.row.buyer.name}</Link>
    ),
  },
  {
    field: 'buyer.email',
    headerName: 'Buyer email',
    width: 160,
    sortable: false,
    disableColumnMenu: true,
    hide: true,
    renderCell: (p) => (
      <Link to={`/customers/${p.row.buyer.id}`}>{p.row.buyer.email}</Link>
    ),
  },
  {
    hide: !(isRockerAgent || isSuperAdmin),
    field: 'buyerCoreviewAccountId',
    headerName: 'Buyer CV ID',
    width: 160,
    sortable: false,
    disableColumnMenu: true,
    renderCell: (p) => (
      <Tooltip title={p.row.buyerCoreviewAccountId + (p.row.buyerCoreviewAccountState ? ` (${formatMoney(p.row.buyerCoreviewAccountState.balance)})` : '')}>
        <p>{p.row.buyerCoreviewAccountId}</p>
      </Tooltip>
    ),
  },
  {
    field: 'seller.id',
    headerName: 'Seller ID',
    width: 300,
    sortable: false,
    disableColumnMenu: true,
    hide: true,
    renderCell: (p) => (
      <Link to={`/customers/${p.row.seller.id}`}>{p.row.seller.id}</Link>
    ),
  },
  {
    hide: customerPaymentType === 'seller',
    field: 'seller.name',
    headerName: 'Seller',
    width: 180,
    sortable: false,
    disableColumnMenu: true,
    renderCell: (p) => (
      <Tooltip title={p.row.seller.name}>
        <Link to={`/customers/${p.row.seller.id}`}>{p.row.seller.name}</Link>
      </Tooltip>
    ),
  },
  {
    field: 'seller.email',
    headerName: 'Seller email',
    width: 160,
    sortable: false,
    disableColumnMenu: true,
    hide: true,
    renderCell: (p) => (
      <Tooltip title={p.row.seller.email}>
        <Link to={`/customers/${p.row.seller.id}`}>{p.row.seller.email}</Link>
      </Tooltip>
    ),
  },
  {
    hide: !(isRockerAgent || isSuperAdmin),
    field: 'sellerCoreviewAccountId',
    headerName: 'Seller CV ID',
    width: 160,
    sortable: false,
    disableColumnMenu: true,
    renderCell: (p) => (
      <Tooltip title={p.row.sellerCoreviewAccountId + (p.row.sellerCoreviewAccountState ? ` (${formatMoney(p.row.sellerCoreviewAccountState.balance)})` : '')}>
        <p>{p.row.sellerCoreviewAccountId}</p>
      </Tooltip>
    ),
  },
  {
    field: 'offer.id',
    headerName: 'Offer ID',
    width: 330,
    sortable: false,
    disableColumnMenu: true,
    hide: true,
    renderCell: (p) => (
      <Link to={`/offers/${p.row.offer.id}`}>{p.row.offer.id}</Link>
    ),
  },
  {
    field: 'offer.name',
    headerName: 'Offer title',
    width: 140,
    sortable: false,
    disableColumnMenu: true,
    hide: true,
    renderCell: (p) => (
      <Tooltip title={p.row.offer.title}>
        <Link to={`/offers/${p.row.offer.id}`}>{p.row.offer.title}</Link>
      </Tooltip>
    ),
  },
  {
    field: 'paymentMethod',
    headerName: 'Method',
    width: 120,
    sortable: false,
    disableColumnMenu: true,
  },
  {
    field: 'status',
    headerName: 'Status',
    width: 120,
    type: 'singleSelect',
    renderCell: (p: any) => <StatusLabel status={p.row.status} />,
    sortable: false,
    disableColumnMenu: true,
  },
  {
    field: 'errorMessage',
    headerName: 'Error Message',
    width: 200,
    disableColumnMenu: true,
    filterable: false,
    renderCell: (p) => (
      <Tooltip title={p.row.errorMessage}>
        <Typography color="error">{p.row.errorMessage}</Typography>
      </Tooltip>
    ),
  },
  {
    field: 'errorCode',
    headerName: 'Error Code',
    width: 200,
    disableColumnMenu: true,
    filterable: false,
    renderCell: (p) => (
      <Tooltip title={p.row.errorCode}>
        <Typography color="error">{p.row.errorCode}</Typography>
      </Tooltip>
    ),
  },
  {
    field: 'providerErrorCode',
    headerName: 'Provider Error Code',
    width: 200,
    disableColumnMenu: true,
    filterable: false,
    renderCell: (p) => (
      <Tooltip title={p.row.providerErrorCode}>
        <Typography color="error">{p.row.providerErrorCode}</Typography>
      </Tooltip>
    ),
  },
  {
    hide: !(isRockerAgent || isSuperAdmin),
    field: 'reference',
    headerName: 'Reference',
    width: 120,
    sortable: false,
    disableColumnMenu: true,
    renderCell: (p) => (
      <Tooltip title={p.row.reference}>
        <span>{p.row.reference}</span>
      </Tooltip>
    ),
  },
  {
    hide: !isSuperAdmin,
    field: 'paymentMethodData',
    headerName: 'Payment Method Data',
    width: 200,
    renderCell: (p) => {
      if (!p.row.paymentMethodData) return null;
      const items = (Object.entries(p.row.paymentMethodData) || []).map(
        ([k, v]) => `${k}: ${v}`,
      );
      return (
        <Tooltip
          title={
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              {items.map((item) => (
                <span key={item}>{item}</span>
              ))}
            </div>
          }
        >
          <div style={{ width: 200 }}>
            {items.map((item, i) => (
              <span key={item}>{i === 0 ? item : `, ${item}`}</span>
            ))}
          </div>
        </Tooltip>
      );
    },
  },

  {
    field: 'merchantId',
    headerName: 'Merchant',
    width: 130,
    sortable: false,
    disableColumnMenu: true,
    renderCell: (p) => mapToMerchantName[p.row.merchantId],
  },
  {
    field: 'updatedAt',
    headerName: 'Last updated',
    width: 150,
    renderCell: (p) => formatDate(p.row.updatedAt),
    disableColumnMenu: true,
    filterable: false,
  },
];

type Props = Omit<DataGridProps, 'columns'> & {
  height?: number;
  topMargin?: boolean;
  noBorder?: boolean;
  customerPaymentType?: CustomerPaymentType;
};

const PaymentGrid = ({ height, noBorder, topMargin, ...props }: Props) => {
  const {
    user: { isSuperAdmin, claims },
  } = useAuthContext();

  const columns = useMemo(
    () =>
      getColumns({
        customerPaymentType: props.customerPaymentType,
        isRockerAgent: claims.merchant === merchantIds.ROCKER,
        isSuperAdmin,
      }),
    [],
  );

  return (
    <DataGrid
      initialState={{
        sorting: {
          sortModel: [{ field: 'updatedAt', sort: 'desc' }],
        }
      }}
      {...props}
      style={{
        height,
        width: '100%',
        marginTop: topMargin ? 20 : 0,
        background: '#fff',
        border: noBorder ? 'none' : '',
      }}
      autoHeight={!height}
      columns={columns}
      disableSelectionOnClick={true}
    />
  );
};

const mapToMerchantName: { [key: string]: string } = {
  [merchantIds.SWIFTCOURT]: 'Swiftcourt',
  [merchantIds.BUSSGODS]: 'Bussgods',
  [merchantIds.PLICK]: 'Plick',
  [merchantIds.BLOCKET]: 'Blocket',
  [merchantIds.ROCKER]: 'Rocker Pay',
  [merchantIds.ROCKER_CAPITAL_GOODS_PORTAL]: 'Rocker Capital Goods Portal',
  [merchantIds.ROCKER_PAY_PORTAL]: 'Rocker Pay Portal'
};

export default PaymentGrid;

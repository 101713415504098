import { Card, CardHeader, CardContent, Grid } from '@mui/material';
import { AntiFraud, Company } from '../../../api/MerchantApi';
import formatMoney from '../../../utils/format-money';

const MerchantAntiFraudInfo = ({ antiFraud }: { antiFraud: AntiFraud }) => (
  <Card style={{ marginTop: 25 }}>
    <CardHeader
      title="Anti fraud:"
      titleTypographyProps={{ fontSize: 16 }}
    />
    <CardContent>
      <Grid container spacing={0.5}>
        <Grid item xs={3}>
          <b>Maximum number of weekly payouts</b>
        </Grid>
        <Grid item xs={9}>
          <span>{antiFraud.maxNumberOfWeeklyPayouts}</span>
        </Grid>
        <Grid item xs={3}>
          <b>Maximum weekly payout amount</b>
        </Grid>
        <Grid item xs={9}>
          <span>{formatMoney(antiFraud.maxWeeklyPayout)}</span>
        </Grid>
        <Grid item xs={3}>
          <b>Minimum Bank ID profile age</b>
        </Grid>
        <Grid item xs={9}>
          <span>{antiFraud.minimumBankIdAge}</span>
        </Grid>
      </Grid>
    </CardContent>
  </Card>
);

export default MerchantAntiFraudInfo;

import { useState, useEffect } from 'react';
import {
  Button,
  Card,
  TableContainer,
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  Typography,
} from '@mui/material';
import { HighlightOff as HighlightOffIcon } from '@mui/icons-material';
import { Invite, InviteApi } from '../../../api/InviteApi';
import formatDate from '../../../utils/format-date';
import AgentInviteModal from '../../../components/AgentInviteModal';
import PageHeader from '../../../components/PageHeader';
import { useAuthContext } from '../../../context/AuthContext';
import ActionConfirmationModal, { ActionModalProps, } from '../../../components/ActionConfirmationModal';

const InviteList = () => {
  const [invites, setInvites] = useState<Array<Invite>>([]);
  const [inviteModal, setInviteModal] = useState(false);
  const [actionModal, setActionModal] = useState<ActionModalProps>({ text: '' });
  const { user: isMerchantAdmin, merchantId } = useAuthContext();

  const inviteApi = new InviteApi();

  const fetchInvites = async () => {
    const resp = isMerchantAdmin ? await inviteApi.getInvitationsForMerchant() : await inviteApi.getInvitations();
    const filteredInvites = resp.invites.filter(
      (inv) => inv.status === 'AVAILABLE',
    );
    setInvites(filteredInvites);
  };

  useEffect(() => { fetchInvites() }, [merchantId]);

  return (
    <>
      <PageHeader style={{ marginTop: '24px' }}>
        <Typography variant="h6">Pending invitations</Typography>
        <Button
          variant="contained"
          size="small"
          onClick={() => setInviteModal(true)}
        >
          Send invitation
        </Button>
      </PageHeader>
      <Card style={{ marginTop: 16 }}>
        <TableContainer>
          <Table stickyHeader aria-label="agents">
            <TableHead>
              <TableRow>
                <TableCell variant="head">Email</TableCell>
                <TableCell variant="head">Invited by</TableCell>
                <TableCell variant="head">Expires at</TableCell>
                <TableCell variant="head"></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {invites.map(
                ({ id, createdByEmail, createdForEmail, expiresAt }) => (
                  <TableRow key={id}>
                    <TableCell>{createdForEmail}</TableCell>
                    <TableCell>{createdByEmail}</TableCell>
                    <TableCell>{formatDate(expiresAt)}</TableCell>
                    <TableCell>
                      <Button
                        variant="contained"
                        color="error"
                        size="small"
                        onClick={() =>
                          setActionModal({
                            text: 'Delete Invitation',
                            action: async () => {
                              await inviteApi.deleteMerchantAdminInvitation(id);
                              fetchInvites();
                              setActionModal({ text: '' });
                            },
                          })
                        }
                      >
                        <HighlightOffIcon />
                      </Button>
                    </TableCell>
                  </TableRow>
                ),
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Card>
      {inviteModal && (
        <AgentInviteModal
          onSuccess={fetchInvites}
          close={() => setInviteModal(false)}
        />
      )}
      {actionModal.text && (
        <ActionConfirmationModal
          {...actionModal}
          close={() => setActionModal({ text: '' })}
        />
      )}
    </>
  );
};

export default InviteList;

import {
  Cancel,
  ChevronRight,
  Warning,
} from '@mui/icons-material';
import {
  Button,
  Menu,
  ListItemIcon,
  ListItemText,
  MenuItem,
  MenuList,
  TextField,
} from '@mui/material';
import * as React from 'react';
import { useState } from 'react';
import { Payout, PayoutApi } from '../../../api/PayoutApi';
import ActionConfirmationModal, {
  ActionModalProps,
} from '../../../components/ActionConfirmationModal';
import { useNotificationContext } from '../../../context/NotificationContext';
import { useOfferContext } from '../../offer/Offer';
import { useAuthContext } from '../../../context/AuthContext';

type Props = {
  payout: Payout;
};

type ModalProps = ActionModalProps & { textField?: boolean };

const VisibleWithPermission: React.FC<{ requiredPermission: string }> = (props) => {
  const authContext = useAuthContext();
  return <>
    {authContext.can(props.requiredPermission) && props.children}
  </>;
}

const PayoutActions = ({ payout }: Props) => {
  const [actionModal, setActionModal] = useState<ModalProps>({
    text: '',
  });
  const [componentPayout, setComponentPayout] = useState<Payout>(payout);
  const { setNotification } = useNotificationContext();
  const { refetchOffer, offer } = useOfferContext();
  // use offer context to trigger the re-fetch of the offer ?
  const payoutApi = new PayoutApi();


  const externalCall = async (callDescription: string, call: () => Promise<void>) => {
    try {
      await call();
      setNotification({
        severity: 'success',
        message: `Success: ${callDescription}`,
      });
    } catch (e) {
      setNotification({
        severity: 'error',
        message: `Failed: ${callDescription}`,
      });
    }
    setActionModal({ text: '' });
  }


  const withFilledComment = (action: (comment: string) => Promise<void>) => async (e: React.SyntheticEvent) => {
    const target = e.target as typeof e.target & {
      comment?: { value: string };
    };
    if (target.comment?.value) {
      return action(target.comment?.value)
    } else {
      setNotification({
        severity: 'error',
        message: `Comment field cannot be empty`,
      });
    }
  }


  const cancelPayout = withFilledComment(comment =>
    externalCall("Marking the payout as CANCELLED", async () => {
      const newPayout = await payoutApi.cancelPayout(payout.paymentId, payout.id, comment);
      setComponentPayout((prev) => ({ ...prev, ...newPayout }));
    }))

  const forceFailPayout = withFilledComment(comment =>
    externalCall("Marking the payout as FAILED", async () => {
      const newPayout = await payoutApi.forceFailPayout(payout.paymentId, payout.id, comment);
      setComponentPayout((prev) => ({ ...prev, ...newPayout }));
    }))

  const forceSucceedPayout = withFilledComment(comment =>
    externalCall("Marking the payout as SUCCEEDED", async () => {
      const newPayout = await payoutApi.forceSucceedPayout(payout.paymentId, payout.id, comment);
      setComponentPayout((prev) => ({ ...prev, ...newPayout }));
    }));

  const forceFailCompletedPayout = withFilledComment(comment =>
    externalCall("Marking the payout as FAILED", async () => {
      const newPayout = await payoutApi.forceFailCompletedPayout(payout.paymentId, payout.id, comment);
      setComponentPayout((prev) => ({ ...prev, ...newPayout }));
      refetchOffer();
    }))


  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Button
        id="action-list-button"
        onClick={handleClick}
        focusRipple={false}
        variant="outlined"
        style={{
          padding: '5px 10px',
          margin: 0,
          fontSize: 12,
        }}
      >
        Show actions
        <ChevronRight
          style={{
            transform: 'rotate(90deg)',
          }}
        />
      </Button>
      <Menu
        id="action-list-menu"
        aria-labelledby="action-list-button"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <MenuList>
          <VisibleWithPermission requiredPermission='CancelDelayedRockerCardPayout'>
            <MenuItem
              disabled={componentPayout.status !== 'PENDING' || componentPayout.payoutMethod !== 'ROCKER_CARD'}
              onClick={() =>
                setActionModal({
                  text: 'Cancel payout waiting for card onboarding',
                  textField: true,
                  action: cancelPayout,
                })
              }
            >
              <ListItemIcon>
                <Cancel />
              </ListItemIcon>
              <ListItemText>Cancel payout waiting for card onboarding</ListItemText>
            </MenuItem>
          </VisibleWithPermission>
          <VisibleWithPermission requiredPermission='ForceFailPayout'>
            <MenuItem
              disabled={componentPayout.status !== 'PENDING'}
              onClick={() =>
                setActionModal({
                  text: 'Mark stuck payout as FAILED',
                  textField: true,
                  action: forceFailPayout,
                })
              }
            >
              <ListItemIcon>
                <Warning />
              </ListItemIcon>
              <ListItemText>Mark stuck payout as FAILED</ListItemText>
            </MenuItem>
          </VisibleWithPermission>
          <VisibleWithPermission requiredPermission='ForceSucceedPayout'>
            <MenuItem
              disabled={componentPayout.status !== 'PENDING'}
              onClick={() =>
                setActionModal({
                  text: 'Mark stuck payout as SUCCEEDED',
                  textField: true,
                  action: forceSucceedPayout,
                })
              }
            >
              <ListItemIcon>
                <Warning />
              </ListItemIcon>
              <ListItemText>Mark stuck payout as SUCCEEDED</ListItemText>
            </MenuItem>
          </VisibleWithPermission>
          <VisibleWithPermission requiredPermission='ForceFailCompletedPayout'>
            <MenuItem
              disabled={!(componentPayout.status === 'COMPLETED' && offer?.status === 'FINISHED_SOLD')}
              onClick={() =>
                setActionModal({
                  text: 'Register bounced payout',
                  textField: true,
                  description: `
                  WARNING: This action could cause bookkeeping inconsistencies if used incorrectly.
                  It should ONLY be used after a payout provider (e.g. Trustly, AutoGiro etc) at first reports that a payout succeeded,
                  but later informs us that it has actually bounced.
                  `,
                  action: forceFailCompletedPayout,
                })
              }
            >
              <ListItemIcon>
                <Cancel />
              </ListItemIcon>
              <ListItemText>Register bounced payout</ListItemText>
            </MenuItem>
          </VisibleWithPermission>
        </MenuList>
      </Menu>
      {
        actionModal.text && (
          <ActionConfirmationModal
            {...actionModal}
            close={() => setActionModal({ text: '' })}
          >
            {actionModal.textField && (
              <TextField
                id="comment-text"
                name="comment"
                fullWidth
                placeholder="Add a comment..."
                variant="outlined"
                sx={{ marginTop: 2 }}
              />
            )}
          </ActionConfirmationModal>
        )
      }
    </>
  );
};

export default PayoutActions;

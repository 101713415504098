import { Card, CardHeader, CardContent, List } from '@mui/material';
import { useEffect, useState } from 'react';
import { PaymentApi } from '../../../api/PaymentApi';
import { AuditLog } from '../../../api/shared';
import LogEntry from '../../../components/LogEntry';

const PaymentLogs = ({ paymentId }: { paymentId: string }) => {
  const [logs, setLogs] = useState<AuditLog[]>([]);

  const paymentApi = new PaymentApi();

  const fetchPayment = async () => {
    const logs = await paymentApi.getPaymentLogs(paymentId);
    setLogs(logs);
  };

  useEffect(() => {
    fetchPayment();
  }, [paymentId]);

  return (
    <Card style={{ marginTop: 25 }}>
      <CardHeader title="Audit logs" />
      <CardContent>
        {logs.length < 1 ? (
          <p>No logs</p>
        ) : (
          <List
            sx={{
              width: '100%',
              bgcolor: 'background.paper',
              maxHeight: 500,
              overflowY: 'scroll',
            }}
          >
            {logs.map((log) => (
              <LogEntry
                key={log.eventId}
                title={log.type}
                author={log.author}
                time={log.time}
                data={log.data}
              />
            ))}
          </List>
        )}
      </CardContent>
    </Card>
  );
};

export default PaymentLogs;

import { GridSortModel } from '@mui/x-data-grid';
import { isEqual } from 'lodash';
import { useState, useEffect } from 'react';
import { Offer, OfferApi } from '../../../api/OfferApi';
import OfferGrid from '../../../components/OfferGrid';
import PaginationController from '../../../components/PaginationController';

type OfferFilters = 'seller' | 'buyer';

type Props = {
  customerId: string;
  filterOn: OfferFilters;
};

const OfferList = (props: Props) => {
  const offerApi = new OfferApi();
  const [offers, setOffers] = useState<Offer[]>([]);
  const [existsMore, setExistsMore] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [sorting, setSorting] = useState<GridSortModel>([
    { field: 'createdAt', sort: 'desc' },
  ]);
  
  const fetchOffers = async () => {
    setIsLoading(true);
    const resp = await offerApi?.getOfferList({
      page,
      sorting: {
        field: sorting.length > 0 ? sorting[0].field : 'createdAt',
        order: sorting.length > 0 && sorting[0].sort ? sorting[0].sort : 'desc',
      },
      sellerId: props.filterOn === 'seller' ? props.customerId : undefined,
      buyerId: props.filterOn === 'buyer' ? props.customerId : undefined,
    });

    setOffers(resp.offers);
    setExistsMore(resp.pageInfo.existsMore);
    setIsLoading(false);
  };

  useEffect(() => {
    fetchOffers();
  }, [page, sorting, props.customerId]);

  const handleSortModelChange = (newModel: GridSortModel) => {
    isEqual(sorting, newModel) || setSorting(newModel);
  };

  return <OfferGrid
    rows={offers}
    loading={isLoading}
    showSellerInfo={props.filterOn != 'seller'}
    sortingMode="server"
    onSortModelChange={handleSortModelChange}
    components={{
      Pagination: () => (
        <PaginationController
          loading={isLoading}
          page={page}
          rowsCount={offers.length}
          existsMore={existsMore}
          decrease={() => setPage((p) => p - 1)}
          increase={() => setPage((p) => p + 1)}
        />
      ),
    }}
    />;
};

export default OfferList;

import { createClient } from './client';

export class AffiliateApi {
  client;

  constructor() {
    this.client = createClient();
  }

  async deleteAffiliate(affiliateId: string): Promise<Affiliate> {
    const resp = await this.client.delete(`/v1/back-office/admin/affiliates/${affiliateId}`);
    return resp.data as Affiliate;
  }
  
  async createAffiliate(request: UpsertAffiliateRequest): Promise<Affiliate> {
    const resp = await this.client.post(`/v1/back-office/admin/affiliates`,
      request,
    );
    return resp.data as Affiliate;
  }

  async updateAffiliate(id: string, affiliate: UpsertAffiliateRequest): Promise<Affiliate> {
    const websiteOrNull = (!affiliate.website || affiliate.website == '') ? null : affiliate.website; //in backend patch with NULL means remove value

    const upsertAffiliateRequest: UpsertAffiliateRequest = {
      ...affiliate,
      website: websiteOrNull
    };

    const resp = await this.client.patch(`/v1/back-office/admin/affiliates/${id}`,
      upsertAffiliateRequest,
    );
    return resp.data as Affiliate;
  }

}

export interface Affiliate {
  id: string;
  merchantId: string;
  code: string;
  name: string;
  website?: string;
  enabled: boolean;
  createdAt: string;
  updatedAt: string;
};

export interface UpsertAffiliateRequest {
  code: string;
  name: string;
  website: string | null;
  enabled: boolean;
};


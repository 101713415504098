import React from 'react';
import { Route, Redirect, RouteProps } from 'react-router-dom';
import { useAuthContext } from '../context/AuthContext';

const PrivateRoute: React.FC<RouteProps> = ({ children, ...rest }) => {
  const { user } = useAuthContext();

  return (
    <Route
      {...rest}
      // @ts-ignore
      render={() => {
        return user.isSuperAdmin || user.isMerchantAdmin ? children : <Redirect to="/" />;
      }}
    />
  );
};

export default PrivateRoute;

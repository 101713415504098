import { MoneyMinor } from './shared';
import { createClient } from './client';

export class PayoutApi {
  client;

  constructor() {
    this.client = createClient();
  }

  async getPayoutsForPayment(paymentId: string): Promise<Payout[]> {
    const resp = await this.client.get(
      `/v1/back-office/payments/${paymentId}/payouts`,
    );
    return (resp.data as { payouts: Payout[] }).payouts;
  }

  async getRegisteredPayoutMethods(customerId: string): Promise<RegisteredPayoutMethod[]> {
    const resp = await this.client.get(
      `/v1/back-office/customers/${customerId}/registered-payout-methods`,
    );
    return (resp.data as { registeredPayoutMethods: RegisteredPayoutMethod[] }).registeredPayoutMethods;
  }

  async getPayoutAccountsForCustomer(customerId: string): Promise<PayoutAccount[]> {
    const resp = await this.client.get(
      `/v1/back-office/customers/${customerId}/payout-accounts`,
    );
    return (resp.data as { accounts: PayoutAccount[] }).accounts;
  }

  async cancelPayout(paymentId: string, payoutId: string, comment?: string): Promise<Payout[]> {
    const resp = await this.client.post(
      `/v1/back-office/payments/${paymentId}/payouts/${payoutId}/cancel`,
      { comment: comment }
    );

    return resp.data;
  }

  async forceFailPayout(paymentId: string, payoutId: string, comment?: string): Promise<Payout[]> {
    const resp = await this.client.post(
      `/v1/back-office/payments/${paymentId}/payouts/${payoutId}/force-fail`,
      { comment: comment }
    );

    return resp.data;
  }

  async forceSucceedPayout(paymentId: string, payoutId: string, comment?: string): Promise<Payout[]> {
    const resp = await this.client.post(
      `/v1/back-office/payments/${paymentId}/payouts/${payoutId}/force-succeed`,
      { comment: comment }
    );

    return resp.data;
  }

  async forceFailCompletedPayout(paymentId: string, payoutId: string, comment?: string): Promise<Payout[]> {
    const resp = await this.client.post(
      `/v1/back-office/payments/${paymentId}/payouts/${payoutId}/force-fail-completed`,
      { comment: comment }
    );

    return resp.data;
  }
}

export interface PayoutAccountInfo {
  id: string;
  accountName: string;
  bankName: string;
  verificationStatus?: string;
}

export interface PayoutAccount extends PayoutAccountInfo {
  provider: string;
}

export interface RegisteredPayoutMethod {
  payoutMethod: string;
  payoutAccount?: PayoutAccountInfo
}

export interface Payout {
  id: string;
  amount: MoneyMinor;
  paymentId: string;
  payoutMethod: string;
  status: string;
  payoutFee: MoneyMinor;
  serviceFee: MoneyMinor;
  createdAt: string;
  updatedAt: string;
  payoutAccount: PayoutAccountInfo;
  errorMessage?: string;
  errorCode?: string;
  providerErrorCode?: string;
}

import { CircularProgress } from '@mui/material';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Payment as PaymentModel, PaymentApi } from '../../api/PaymentApi';
import { Payout, PayoutApi } from '../../api/PayoutApi';
import PaymentDetails from './components/PaymentDetails';
import PaymentLogs from './components/PaymentLogs';

const Payment = () => {
  const [payment, setPayment] = useState<PaymentModel | null>(null);

  const [payouts, setPayouts] = useState<Payout[] | null>([]);
  const { paymentId } = useParams<{ paymentId: string }>();
  const paymentApi = new PaymentApi();
  const payoutApi = new PayoutApi();

  const fetchPayment = async () => {
    const payment = await paymentApi.getPayment(paymentId);
    const payouts = await payoutApi.getPayoutsForPayment(paymentId);
    setPayment(payment);

    setPayouts(payouts);
  };

  useEffect(() => {
    fetchPayment();
  }, [paymentId]);

  return (
    <>
      {!(payment && payouts) && <CircularProgress />}
      {payment && payouts && (
        <>
          <PaymentDetails payment={payment} />
          <PaymentLogs paymentId={paymentId} />
        </>
      )}
    </>
  );
};

export default Payment;

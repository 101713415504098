import {
  List,
  Grid,
  Card,
  CardHeader,
  CardContent,
  IconButton,
} from '@mui/material';
import formatDate from '../../../utils/format-date';
import EditIcon from '@mui/icons-material/Edit';
import copy from 'copy-to-clipboard';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import BlockIcon from '@mui/icons-material/Block';
import CheckIcon from '@mui/icons-material/Check';
import AddIcon from '@mui/icons-material/Add';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { useNotificationContext } from '../../../context/NotificationContext';
import { useAuthContext } from '../../../context/AuthContext';
import { useState } from 'react';
import EditAffiliateModal from './EditAffiliateModal';
import { Affiliate, AffiliateApi, UpsertAffiliateRequest } from '../../../api/AffiliateApi';
import ActionConfirmationModal, { ActionModalProps } from '../../../components/ActionConfirmationModal';
import { useMutation, useQueryClient } from 'react-query';
import { useParams } from 'react-router';

const MerchantAffiliates = ({
  affiliates,
}: {
  affiliates: Affiliate[];
  }) => {
  const affiliateApi = new AffiliateApi();
  const { setNotification } = useNotificationContext();
  const { user: { isSuperAdmin } } = useAuthContext();
  const [editAffilate, setEditAffiliate] = useState<UpsertAffiliateRequest>();
  const [confirmModal, setConfirmModal] = useState<ActionModalProps & {id: string}>({
    text: '',
    id: ''
  });
  const { merchantId } = useParams<{ merchantId: string }>();
  const queryClient = useQueryClient();
  const deleteAffiliateMutation = useMutation(
    (id: string ) => affiliateApi.deleteAffiliate(id),
    {
      onError: () =>
        setNotification({
          severity: 'error',
          message: 'Something went wrong. please try again or contact support',
        }),
      onSettled: () => {
        queryClient.invalidateQueries(['merchantAffiliates', merchantId]);
      },
    },
  );


  const link = (affiliate: Affiliate) => {
    return `https://pay.rocker.com/a/${affiliate.code}`;
  }
  const copyLink = (affiliate: Affiliate) => {
    copy(link(affiliate));
    setNotification({
      severity: 'success',
      message: 'Copied',
    });
  }

  const addAffiliate = () => {
    setEditAffiliate({
      code: '',
      name: '',
      website: null,
      enabled: true
    })
  }

  const deleteAffiliate = (id: string) => {
    deleteAffiliateMutation.mutate(id);
    setConfirmModal({ text: '', id: '' });
  }

  const AffiliateRow = ({
    affiliate,
    canEditAffiliate,
    setEditAffiliate
  }: {
      affiliate: Affiliate;
      canEditAffiliate: boolean;
      setEditAffiliate: (a: Affiliate) => void;
  }) => (
    <Card
      sx={{ p: 2, mb: 2, boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px' }}
    >
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <b style={{ textDecoration: 'underline', fontSize: 14 }}>
          {affiliate.name}
        </b>
        {canEditAffiliate && (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <IconButton onClick={() => setEditAffiliate(affiliate)}>
              <EditIcon />
            </IconButton>
            <IconButton onClick={() => setConfirmModal({
              text: `Are you sure you want to delete affiliate ${affiliate.name}?`,
              description: 'Only affiliate without any offers can be deleted. Otherwise use `enabled` flag',
              id: affiliate.id
            })}>
              <DeleteForeverIcon />
            </IconButton>
          </div>
        )}
      </div>
      <Grid container spacing={0.5}>
        <Grid item xs={6} md={3} lg={2}>
          <b>Code</b>
        </Grid>
        <Grid item xs={6} md={3} lg={4}>
          <span>{affiliate.code}</span>
        </Grid>
        <Grid item xs={6} md={3} lg={2}>
          <b>Website</b>
        </Grid>
        <Grid item xs={6} md={3} lg={4}>
          <a href={affiliate.website} target='_blank'>{affiliate.website}</a>
        </Grid>
        <Grid item xs={6} md={3} lg={2}>
          <b>Link</b><IconButton onClick={() => copyLink(affiliate)}>
            <ContentCopyIcon />
          </IconButton>
        </Grid>
        <Grid item xs={6} md={3} lg={4}>
          <a href={link(affiliate)} target='_blank'>{link(affiliate)}</a>
        </Grid>
        <Grid item xs={6} md={3} lg={2}>
          <b>Enabled</b>
        </Grid>
        <Grid item xs={6} md={3} lg={4}>
          <span>{affiliate.enabled ? <CheckIcon /> : <BlockIcon />}</span>
        </Grid>
        <Grid item xs={6} md={3} lg={2}>
          <b>Created at</b>
        </Grid>
        <Grid item xs={6} md={3} lg={4}>
          <span>{formatDate(affiliate.createdAt)}</span>
        </Grid>
        <Grid item xs={6} md={3} lg={2}>
          <b>Updated at</b>
        </Grid>
        <Grid item xs={6} md={3} lg={4}>
          <span>{formatDate(affiliate.updatedAt)}</span>
        </Grid>
      </Grid>
    </Card>
  );

  return (
    <>
      <Card style={{ marginTop: 25 }}>
        <CardHeader
          title={<div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <span style={{ fontSize: 16, marginRight: 10 }}>Affiliates:</span>
            {isSuperAdmin && (
              <IconButton onClick={addAffiliate}>
                <AddIcon />
              </IconButton>
            )}
          </div>}
          titleTypographyProps={{ fontSize: 16 }}
        />
        <CardContent>
          {affiliates.map((affiliate, i) => (
            <List key={affiliate.id} component="div" disablePadding>
              <AffiliateRow
                affiliate={affiliate}
                canEditAffiliate={isSuperAdmin}
                setEditAffiliate={(a) => setEditAffiliate(a as UpsertAffiliateRequest)}
              />
            </List>
          ))}
        </CardContent>
      </Card>
      {editAffilate && (
        <EditAffiliateModal
          affiliate={editAffilate}
          close={() => setEditAffiliate(undefined)}
        />
      )}
      {confirmModal.text && <ActionConfirmationModal
        {...confirmModal}
        action={() => deleteAffiliate(confirmModal.id)}
        close={() => setConfirmModal({ text: '', id: '' })}
      />}

    </>
  );
};

export default MerchantAffiliates;

import { ChevronRight, Delete, FactCheck } from '@mui/icons-material';
import {
  Button,
  Menu,
  ListItemIcon,
  ListItemText,
  MenuItem,
  MenuList,
  TextField,
  Select,
} from '@mui/material';
import * as React from 'react';
import { useState } from 'react';
import { CustomerApi } from '../../../api/CustomerApi';
import { PayoutAccount } from '../../../api/PayoutApi';
import ActionConfirmationModal, {
  ActionModalProps,
} from '../../../components/ActionConfirmationModal';
import { useAuthContext } from '../../../context/AuthContext';
import { useNotificationContext } from '../../../context/NotificationContext';

type Props = {
  customerId: string;
  payoutAccount: PayoutAccount;
  onDelete: () => void;
  onUpdate: (pa: PayoutAccount) => void;
};

type ModalProps = ActionModalProps & { textField?: boolean };

const PayoutAccountActions = ({
  customerId,
  payoutAccount,
  onDelete,
  onUpdate,
}: Props) => {
  const [actionModal, setActionModal] = useState<ModalProps>({
    text: '',
  });
  const [verificationStatusModal, setVerificationStatusModal] =
    useState<ModalProps>({
      text: '',
    });
  const authContext = useAuthContext();
  const { setNotification } = useNotificationContext();
  const customerApi = new CustomerApi();

  const deletePayoutAccount = async () => {
    try {
      await customerApi.deletePayoutAccount(customerId, payoutAccount.id);

      setNotification({
        severity: 'success',
        message: 'Payout account deleted',
      });
      onDelete();
    } catch (e) {
      setNotification({
        severity: 'error',
        message: `Failed to delete payout account`,
      });
    }
    setActionModal({ text: '' });
  };

  const changeVerificationStatus = async (e: any) => {
    try {
      const verificationStatus = e.target.verificationStatus.value;

      await customerApi.updatePayoutAccount(
        customerId,
        payoutAccount.id,
        verificationStatus,
      );

      setNotification({
        severity: 'success',
        message: 'Payout account verification status updated',
      });
      onUpdate({ ...payoutAccount, verificationStatus });
    } catch (e) {
      setNotification({
        severity: 'error',
        message: `Failed to update payout account verification status`,
      });
    }
    setVerificationStatusModal({ text: '' });
  };

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Button
        id="action-list-button"
        onClick={handleClick}
        focusRipple={false}
        variant="outlined"
        style={{
          padding: '5px 10px',
          margin: 0,
          fontSize: 12,
        }}
      >
        Show actions
        <ChevronRight
          style={{
            transform: 'rotate(90deg)',
          }}
        />
      </Button>
      <Menu
        id="action-list-menu"
        aria-labelledby="action-list-button"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <MenuList>
          {authContext.can('UpdatePayoutAccount') && (
            <MenuItem
              disabled={payoutAccount.provider !== 'AUTOGIRO'}
              onClick={() =>
                setVerificationStatusModal({
                  text: 'Change verification status',
                  action: changeVerificationStatus,
                })
              }
            >
              <ListItemIcon>
                <FactCheck />
              </ListItemIcon>
              <ListItemText>Change verification status</ListItemText>
            </MenuItem>
          )}
          {authContext.can('DeletePayoutAccount') && (
            <MenuItem
              disabled={payoutAccount.provider === 'ROCKER_CARD'}
              onClick={() =>
                setActionModal({
                  text: 'Delete payout account',
                  action: deletePayoutAccount,
                })
              }
            >
              <ListItemIcon>
                <Delete />
              </ListItemIcon>
              <ListItemText>Delete</ListItemText>
            </MenuItem>
          )}
        </MenuList>
      </Menu>
      {actionModal.text && (
        <ActionConfirmationModal
          {...actionModal}
          close={() => setActionModal({ text: '' })}
        >
          {actionModal.textField && (
            <TextField
              id="comment-text"
              name="comment"
              fullWidth
              placeholder="Add a comment..."
              variant="outlined"
              sx={{ marginTop: 2 }}
            />
          )}
        </ActionConfirmationModal>
      )}
      {verificationStatusModal.text && (
        <ActionConfirmationModal
          {...verificationStatusModal}
          close={() => setVerificationStatusModal({ text: '' })}
        >
          <Select
            labelId="verification-status-label"
            name="verificationStatus"
            required
            defaultValue={payoutAccount.verificationStatus}
            label="Verification status *"
          >
            <MenuItem value="UNVERIFIED">
              <ListItemText
                primary="Unverified"
                secondary="Unverified account"
              />
            </MenuItem>
            <MenuItem value="PAYABLE">
              <ListItemText
                primary="Payable"
                secondary="It has been verified that the account exists"
              />
            </MenuItem>
            <MenuItem value="VERIFIED_OWNER">
              <ListItemText
                primary="Verified owner"
                secondary="It has been verified that the account exists and customer is the account holder"
              />
            </MenuItem>
          </Select>
        </ActionConfirmationModal>
      )}
    </>
  );
};

export default PayoutAccountActions;

import { Button } from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import AddIcon from '@mui/icons-material/Add';
import { useEffect, useState } from 'react';
import { PayoutAccount, PayoutApi } from '../../../api/PayoutApi';
import formatDate from '../../../utils/format-date';
import AddBankAccountModal from '../../../components/AddBankAccountModal';
import PayoutAccountActions from './PayoutAccountActions';
import { useAuthContext } from '../../../context/AuthContext';
import AddSwishAccountModal from '../../../components/AddSwishAccountModal';
import { Customer } from '../../../api/CustomerApi';

type Props = {
  customer: Customer;
};

const PayoutAccountList = (props: Props) => {
  const authContext = useAuthContext();
  const [addBankAccountModal, setAddBankAccount] = useState(false);
  const [addSwishAccountModal, setAddSwishAccount] = useState(false);
  const [payoutAccounts, setPayoutAccounts] = useState<PayoutAccount[]>([]);
  const [loading, setLoading] = useState(false);
  const payoutApi = new PayoutApi();

  const columns: Array<GridColDef> = [
    {
      field: 'actions',
      headerName: 'Actions',
      width: 150,
      disableColumnMenu: true,
      filterable: false,
      renderCell: (p) => (
        <PayoutAccountActions
          customerId={props.customer.id}
          payoutAccount={p.row}
          onDelete={() => {
            setPayoutAccounts(
              payoutAccounts.filter((account) => account.id !== p.row.id),
            );
          }}
          onUpdate={(updatedPa) => {
            setPayoutAccounts(
              payoutAccounts.map((account) =>
                account.id === updatedPa.id ? updatedPa : account,
              ),
            );
          }}
        />
      ),
    },
    {
      field: 'id',
      headerName: 'Id',
      width: 340,
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: 'provider',
      headerName: 'Provider',
      width: 120,
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: 'accountName',
      headerName: 'Account name',
      width: 300,
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: 'bankName',
      headerName: 'Bank name',
      width: 200,
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: 'verificationStatus',
      headerName: 'Verification status',
      width: 200,
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: 'createdAt',
      headerName: 'Created at',
      width: 140,
      renderCell: (p) => p.row.createdAt && formatDate(p.row.createdAt),
      disableColumnMenu: true,
      filterable: false,
    },
  ];

  const fetchPayoutAccounts = async () => {
    setLoading(true);

    const resp = await payoutApi?.getRegisteredPayoutMethods(props.customer.id);

    // Convert RegisteredPayoutMethods to PayoutAccounts since flat objects are more compatible with the data grid
    const accounts = resp.map(
      (r) =>
        ({
          //Default values for Rocker Card
          id: '',
          customerId: props.customer.id,
          bankName: 'Rocker',
          accountName: 'Rocker Card',
          createdAt: '',
          //
          provider: r.payoutMethod,
          ...r.payoutAccount,
        } as PayoutAccount),
    );

    setPayoutAccounts(accounts);
    setLoading(false);
  };

  useEffect(() => {
    fetchPayoutAccounts();
  }, [props.customer.id]);

  return (
    <>
      <DataGrid
        style={{
          width: '100%',
          background: '#fff',
          border: 'none',
        }}
        autoHeight
        loading={loading}
        columns={columns}
        rows={payoutAccounts}
        disableSelectionOnClick={true}
        disableColumnSelector={true}
        hideFooter={true}
      />
      {authContext.can('CreatePayoutAccount') && (
        <>
          <Button
            variant="contained"
            startIcon={<AddIcon />}
            sx={{ margin: 2 }}
            onClick={() => setAddBankAccount(true)}
          >
            Add bank account
          </Button>
          <Button
            variant="contained"
            startIcon={<AddIcon />}
            sx={{ margin: 2 }}
            onClick={() => setAddSwishAccount(true)}
          >
            Add Swish account
          </Button>
        </>
      )}
      {addBankAccountModal && (
        <AddBankAccountModal
          customerId={props.customer.id}
          onSuccess={(payoutAccount) => {
            setPayoutAccounts([...payoutAccounts, payoutAccount]);
          }}
          close={() => setAddBankAccount(false)}
        />
      )}
      {addSwishAccountModal && (
        <AddSwishAccountModal
        customer={props.customer}
          onSuccess={(payoutAccount) => {
            setPayoutAccounts([...payoutAccounts, payoutAccount]);
          }}
          close={() => setAddSwishAccount(false)}
        />
      )}
    </>
  );
};

export default PayoutAccountList;

const locale = 'sv-SE';

const formatDate = (
  date?: string,
  dateStyle: Intl.DateTimeFormatOptions['dateStyle'] = 'short',
  timeStyle: Intl.DateTimeFormatOptions['timeStyle'] = 'short',
) => {
  if (!date) return '';

  const formatter = new Intl.DateTimeFormat(locale, { dateStyle, timeStyle });
  return formatter.format(new Date(date));
};

export default formatDate;

const mapLabel: { [key: string]: { text: string; color: string } } = {
  AVAILABLE: { text: 'Available', color: '#FCDA83' },
  BOOKED: { text: 'Booked', color: '#C2D0FA' },
  IN_PAYOUT: { text: 'In payout', color: '#C2D0FA' },
  WAITING_ON_RENEW: { text: 'Inactive', color: '#DBDDF0' },
  FINISHED_SOLD: { text: 'Sold', color: '#A7E6C3' },
  SETTLED: { text: 'Settled', color: '#A7E6C3' },
  FINISHED_DELETED: { text: 'Deleted', color: '#DBDDF0' },
  TIMED_OUT: { text: 'Timed out', color: '#DBDDF0' },
  FAILED: { text: 'Failed', color: '#F7B6B8' },
};

const defaultLabel = (key: string) => ({ text: key, color: '#C2D0FA' });

const StatusLabel = ({ status }: { status: string }) => {
  const label = mapLabel[status] || defaultLabel(status);

  return (
    <span
      style={{
        padding: '2px 6px',
        backgroundColor: label.color,
        borderRadius: 8,
      }}
    >
      {label.text}
    </span>
  );
};

export default StatusLabel;

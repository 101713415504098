import { useState } from 'react';
import { FormikValues, useFormik } from 'formik';
import {
  Button,
  Card,
  CardContent,
  Grid,
  IconButton,
  TextField,
  TextFieldProps,
} from '@mui/material';
import { Edit as EditIcon, Save as SaveIcon } from '@mui/icons-material';
import { Customer, CustomerApi } from '../../../api/CustomerApi';
import formatDate from '../../../utils/format-date';
import ButtonWithSpinner from '../../../components/ButtonWithSpinner';
import { useNotificationContext } from '../../../context/NotificationContext';
import payoutMethods from '../../../constants/payoutMethods';

type CustomerDetailsProps = {
  customer: Customer;
  onCustomerUpdate: (customer: Customer) => void;
};

const ReadOnlyTextField = (props: TextFieldProps) => (
  <TextField fullWidth disabled size="small" variant="outlined" {...props} />
);

type FormikTextFieldProps = TextFieldProps & {
  label: string;
  field: string;
  formik: FormikValues;
  isEditing: boolean;
};

const FormikTextField = (props: FormikTextFieldProps) => (
  <TextField
    fullWidth
    id={props.field}
    disabled={!props.isEditing}
    required={props.required}
    size="small"
    variant="outlined"
    label={props.label}
    value={props.formik.values[props.field]}
    onChange={props.formik.handleChange}
    error={
      props.formik.touched[props.field] &&
      Boolean(props.formik.errors[props.field])
    }
    helperText={
      props.formik.touched[props.field] && props.formik.errors[props.field]
    }
  />
);

export default function CustomerDetails({
  customer,
  onCustomerUpdate,
}: CustomerDetailsProps) {
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const { setNotification } = useNotificationContext();
  const customerApi = new CustomerApi();

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      email: customer.email,
      phone: customer.phone,
      streetAddress: customer.address?.streetAddress || '',
      deliveryAddress: customer.address?.deliveryAddress,
      invoiceAddress: customer.address?.invoiceAddress,
      zipCode: customer.address?.zipCode || '',
      city: customer.address?.city || '',
    },
    onReset: () => {
      setIsEditing(false);
    },
    onSubmit: (values) => {
      const doAsync = async () => {
        try {
          setIsSaving(true);

          const updatedCustomer = await customerApi?.updateCustomer(
            customer.id,
            {
              email: values.email,
              phone: values.phone,
              address: {
                streetAddress: values.streetAddress,
                deliveryAddress: values.deliveryAddress || undefined,
                invoiceAddress: values.invoiceAddress || undefined,
                zipCode: values.zipCode,
                city: values.city,
              },
            },
          );

          setIsEditing(false);
          setNotification({
            severity: 'success',
            message: 'Customer data has been updated successfully',
          });
          onCustomerUpdate(updatedCustomer);
        } catch (error: any) {
          setNotification({
            severity: 'error',
            message: `Cannot update customer data: ${error.message}`,
          });
        } finally {
          setIsSaving(false);
        }
      };

      doAsync();
    },
  });

  const formatPayoutMethod = (payoutMethod?: string): string => {
    return payoutMethod
      ? payoutMethods[payoutMethod as keyof typeof payoutMethods] ?? payoutMethod
      : 'None';
  };

  return (
    <>
      <Card>
        <CardContent>
          <form onReset={formik.handleReset} onSubmit={formik.handleSubmit}>
            <Grid container spacing={2} sx={{ pt: 1.5, position: 'relative' }}>
              <IconButton
                onClick={() => setIsEditing(true)}
                size="medium"
                sx={{ position: 'absolute', top: 28, right: -10 }}
              >
                <EditIcon />
              </IconButton>
              <Grid item xs={4}>
                <FormikTextField
                  formik={formik}
                  isEditing={isEditing}
                  label="Email address"
                  field="email"
                  required
                />
              </Grid>
              <Grid item xs={4}>
                <FormikTextField
                  formik={formik}
                  isEditing={isEditing}
                  label="Address"
                  field="streetAddress"
                  required
                />
              </Grid>
              <Grid item xs={3.4}>
                <ReadOnlyTextField
                  label="Created at"
                  defaultValue={formatDate(customer.createdAt)}
                />
              </Grid>

              <Grid item xs={4}>
                <FormikTextField
                  formik={formik}
                  isEditing={isEditing}
                  label="Phone number"
                  field="phone"
                  required
                />
              </Grid>
              <Grid item xs={4}>
                <FormikTextField
                  formik={formik}
                  isEditing={isEditing}
                  label="Zip code"
                  field="zipCode"
                  required
                />
              </Grid>
              <Grid item xs={3.4}>
                <ReadOnlyTextField
                  label="IP Address"
                  defaultValue={customer.ip}
                />
              </Grid>
              <Grid item xs={4} />
              <Grid item xs={4}>
                <FormikTextField
                  formik={formik}
                  isEditing={isEditing}
                  label="City"
                  field="city"
                  required
                />
              </Grid>
              <Grid item xs={3.4}>
                <ReadOnlyTextField
                  label="Default payout method"
                  value={formatPayoutMethod(customer.defaultPayoutMethod)}
                />
              </Grid>
              {isEditing && (
                <Grid item xs={12}>
                  <Grid
                    container
                    direction="row"
                    justifyContent="flex-end"
                    spacing={2}
                  >
                    <Grid item>
                      <Button
                        type="reset"
                        variant="contained"
                        disabled={isSaving}
                      >
                        Cancel
                      </Button>
                    </Grid>
                    <Grid item>
                      <ButtonWithSpinner
                        type="submit"
                        variant="contained"
                        color="primary"
                        startIcon={<SaveIcon />}
                        loading={isSaving}
                      >
                        Save changes
                      </ButtonWithSpinner>
                    </Grid>
                  </Grid>
                </Grid>
              )}
            </Grid>
          </form>
        </CardContent>
      </Card>
    </>
  );
}

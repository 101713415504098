export type Order = 'asc' | 'desc';

export interface MoneyMinor {
  amount: number;
  currency: string;
  unit: 'MINOR'
}

export interface Sorting {
  field: string;
  order: Order;
}

export interface PageInfo {
  number: number;
  size: number;
  existsMore: boolean;
}

export interface UserInfo {
  id: string;
  name: string;
  email: string;
}

export interface OfferInfo {
  id: string;
  title: string;
}

export function sortingParam(sorting: Sorting): string {
  return `${sorting.field}.${sorting.order}`;
}

export interface AuditLogUser {
  userId: string;
  userIdType: string;
  merchantId: string;
  email: string;
}
export interface AuditLog {
  sequenceNbr: number;
  eventId: string;
  category: string;
  type: string;
  traceLogsExplorerUrl: string;
  subject: {
    merchantId: string;
    id: string;
    type: string;
  };
  time: string;
  data: { [key: string]: any };
  author: AuditLogUser;
}

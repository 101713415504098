import { AxiosResponse } from 'axios';
import { createClient } from './client';

export interface EventLogSubscriptionResponse {
  isSubscribed: boolean;
}

export type SubscribeToOfferChangesRequest = {
  $type: 'OfferIdSubject';
  merchantId: string;
  offerId: string;
};

export class EventLogSubscriptionApi {
  client;

  constructor() {
    this.client = createClient();
  }

  async isSubscribed(payload: SubscribeToOfferChangesRequest): Promise<EventLogSubscriptionResponse> {
    const resp = await this.client.post(
      `/v1/back-office/event-log-subscriptions/get`,
      payload
    );
    return resp.data;
  }

  async subscribe(payload: SubscribeToOfferChangesRequest): Promise<AxiosResponse<any>> {
    return await this.client.post(
      `/v1/back-office/event-log-subscriptions`,
      payload
    );
  }

  async unsubscribe(payload: SubscribeToOfferChangesRequest): Promise<AxiosResponse<any>> {
    return await this.client.delete(
      `/v1/back-office/event-log-subscriptions`,
      {
        headers: {
          'Content-Type': 'application/json'
        },
        data: payload
      }
    );
  }

}

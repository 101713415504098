import {useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import isEqual from 'lodash/isEqual';
import {DataGrid, GridColDef, GridSortModel} from '@mui/x-data-grid';
import AddIcon from '@mui/icons-material/Add';
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Tooltip,
  Typography
} from '@mui/material';
import {Customer, CustomerApi} from '../../api/CustomerApi';
import {Sorting} from '../../api/shared';
import formatDate from '../../utils/format-date';
import SearchBar from '../../components/SearchBar';
import PaginationController from '../../components/PaginationController';
import GridToolbar from '../../components/GridToolbar';
import {useAuthContext} from "../../context/AuthContext";
import CreateCustomerModal from '../offer/components/CreateCustomerModal';
import { useQuery } from 'react-query';
import { MerchantApi } from '../../api/MerchantApi';

function columns(includeAllMerchants: boolean): Array<GridColDef> {
  return [
    {
      field: 'merchantName',
      headerName: 'Merchant',
      width: 128,
      renderCell: (p: any) => <Link
        to={`/merchants/${p.row.merchantId}`}>{p.row.merchantName}</Link>,
      disableColumnMenu: true,
      hide: !includeAllMerchants,
      filterable: false,
      sortable: false,
    },
    {
      field: 'id',
      headerName: 'User ID',
      width: 300,
      renderCell: (p) => <Link to={`/customers/${p.row.id}`}>{p.row.id}</Link>,
      disableColumnMenu: true,
      hide: true,
    },
    {
      field: 'firstName',
      headerName: 'Name',
      width: 140,
      renderCell: (p) => {
        const displayName = p.row.companyName
          ? p.row.companyName
          : `${p.row.firstName} ${p.row.lastName}`;
        return (
          <Tooltip title={displayName}>
            <Link to={`/customers/${p.row.id}`}>{displayName}</Link>
          </Tooltip>
        );
      },
      disableColumnMenu: true,
    },

    {field: 'email', headerName: 'Email', width: 200, filterable: false},
    {
      field: 'nationalIdNumber',
      headerName: 'National ID',
      width: 130,
      disableColumnMenu: true,
      hide: true,
    },
    {
      field: 'createdAt',
      headerName: 'Created at',
      width: 150,
      renderCell: (p) => formatDate(p.row.createdAt),
      disableColumnMenu: true,
    },
    {
      field: 'updatedAt',
      headerName: 'Updated at',
      width: 150,
      renderCell: (p) => formatDate(p.row.updatedAt),
      disableColumnMenu: true,
      filterable: false,
    },
  ];
}

const CustomerList = () => {
  const authCtx = useAuthContext();
  const customerApi = new CustomerApi();
  const merchantApi = new MerchantApi();
  const [createCustomerModal, setCreateCustomerModal] = useState(false);
  const [customers, setCustomers] = useState<Customer[]>([]);
  const [existsMore, setExistsMore] = useState(false);
  const [page, setPage] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [query, setQuery] = useState<string>('');
  const [sorting, setSorting] = useState<GridSortModel>([
    {field: 'updatedAt', sort: 'desc'},
  ]);
  const [allMerchantsSearch, setAllMerchantsSearch] = useState<boolean>(
    JSON.parse(localStorage.getItem('allMerchantsSearch') || 'false'),
  );
  const { data: merchantConfigData, isLoading: isMerchantConfigLoading } =
    useQuery(['merchantConfig', authCtx.merchantId], () =>
      merchantApi.getMerchantConfig(authCtx.merchantId),
    );

  useEffect(() => {
    localStorage.setItem('allMerchantsSearch', JSON.stringify(allMerchantsSearch));
  }, [allMerchantsSearch]);

  const fetchCustomerList = async (
    p: number,
    s: GridSortModel,
    query?: string,
  ) => {
    const sortingParam: Sorting = {
      order: s.length > 0 && s[0].sort ? s[0].sort : 'desc',
      field: s.length > 0 ? s[0].field : 'createdAt',
    };
    setIsLoading(true);
    const resp = await customerApi.getCustomerList(p, sortingParam, query, allMerchantsSearch);
    setCustomers(resp.users);
    setExistsMore(resp.pageInfo.existsMore);
    setIsLoading(false);
  };

  useEffect(() => {
    fetchCustomerList(page, sorting, query);
  }, [sorting, page, query, allMerchantsSearch]);

  const handleSortModelChange = (newModel: GridSortModel) => {
    isEqual(sorting, newModel) || setSorting(newModel);
  };

  return (
    <>
      <Typography variant="h5" mb={2}>
        Customers
      </Typography>
      <Box sx={{display: 'flex', flexDirection: 'row'}}>
        <SearchBar onSearch={setQuery}></SearchBar>
        {authCtx.can('GenericRockerAgentOperation') && (
          <FormControlLabel
            id='all-merchants-search-checkbox'
            name='all-merchants-search-checkbox'
            label='search all merchants'
            control={
              <Checkbox
                checked={allMerchantsSearch}
                onChange={(event) => setAllMerchantsSearch(event.target.checked)}
              />
            }
          />
        )}
        {authCtx.can("CreateEntity") && merchantConfigData?.backofficeCreateEntityPermission?.createUserAllowed &&
          <Button
            variant="contained"
            startIcon={<AddIcon />}
            sx={{ margin: 2 }}
            onClick={() => setCreateCustomerModal(true)}
          >
            Create customer
          </Button>}
      </Box>
      {createCustomerModal && <CreateCustomerModal onSuccess={(c) => setCustomers([c, ...customers])} close={() => setCreateCustomerModal(false)} />}
      <DataGrid
        style={{
          marginTop: 20,
          height: 800,
          width: '100%',
          background: '#fff',
        }}
        rows={customers}
        columns={columns(allMerchantsSearch)}
        loading={isLoading}
        sortingMode="server"
        onSortModelChange={handleSortModelChange}
        sortModel={sorting}
        disableSelectionOnClick={true}
        components={{
          Toolbar: () => <GridToolbar columns density/>,
          Pagination: () => (
            <PaginationController
              loading={isLoading}
              page={page}
              rowsCount={customers.length}
              existsMore={existsMore}
              decrease={() => setPage((p) => p - 1)}
              increase={() => setPage((p) => p + 1)}
            />
          ),
        }}
      />
    </>
  );
};

export default CustomerList;

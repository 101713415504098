import axios from 'axios';
import { getAuth, signOut } from 'firebase/auth';
import { getCookie, removeCookie, setCookie } from '../utils/cookies';

export const createClient = () => {
  const client = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL,
  });

  client.interceptors.request.use(
    async (req) => {
      const auth = getAuth();
      const token = await auth.currentUser?.getIdToken();
      const merchantId = getCookie('merchantId');
      req.headers.Authorization = `Bearer ${token}`;
      req.headers['X-MerchantId'] = merchantId || 'pending';
      req.headers['X-MerchantId-List'] = merchantId || 'pending';

      return req;
    },
    (e) => Promise.reject(e),
  );

  client.interceptors.response.use(
    (req) => req,
    (e) => {
      if (e?.response?.status === 401 && window.location.href !== '/') {
        signOut(getAuth());
        removeCookie('merchantId');
        window.location.href = '/';
      }

      if (e?.response?.status === 300) {
        const newMerchantId = e.response.data.customErrorData.MERCHANT_ID;
        setCookie('merchantId', newMerchantId);

        console.debug(`Changing merchant to ${newMerchantId}`);

        // @ts-ignore
        if (!window.reloading) {
          console.debug('Reloading page');

          window.location.reload();
          // @ts-ignore
          window.reloading = true;
        }
      }

      return Promise.reject(e);
    },
  );

  return client;
};

import { Snackbar, Alert } from '@mui/material';
import { useNotificationContext } from '../context/NotificationContext';

const Notification = () => {
  const { notification, clearNotification } = useNotificationContext();

  if (!notification) return null;

  return (
    <Snackbar
      open={true}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      autoHideDuration={7000}
      onClose={clearNotification}
    >
      <Alert severity={notification.severity} elevation={6} variant="filled">
        {notification.message}
      </Alert>
    </Snackbar>
  );
};

export default Notification;

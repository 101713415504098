import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { useEffect, useState } from 'react';
import formatDate from '../../../utils/format-date';
import { useAuthContext } from '../../../context/AuthContext';
import { GoodwillPayoutApi, RichGoodwillPayoutResponse } from '../../../api/GoodwillPayoutApi';
import { Customer } from '../../../api/CustomerApi';
import { Tooltip, Typography } from '@mui/material';
import formatMoney from '../../../utils/format-money';

type Props = {
  customer: Customer;
};

const GoodwillPayoutList = (props: Props) => {
  const authContext = useAuthContext();
  const [goodwillPayouts, setGoodwillPayouts] = useState<RichGoodwillPayoutResponse[]>([]);
  const [loading, setLoading] = useState(false);
  const goodwillPayoutApi = new GoodwillPayoutApi();

  const columns: Array<GridColDef> = [
    {
      field: 'id',
      headerName: 'Payout ID',
      width: 150,
      sortable: false,
      disableColumnMenu: true,
      renderCell: ({ row }) => (
        <Tooltip title={row.id} placement="top">
          <p>{row.id}</p>
        </Tooltip>
      ),
    },
    {
      field: 'amount',
      headerName: 'Amount',
      width: 100,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (p) => formatMoney(p.row.amount),
    },
    {
      field: 'status',
      headerName: 'Status',
      width: 150,
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: 'payoutMethod',
      headerName: 'Method',
      width: 100,
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: 'payoutAccountName',
      headerName: 'Payout Account',
      width: 150,
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: 'errorMessage',
      headerName: 'Error Message',
      width: 200,
      disableColumnMenu: true,
      filterable: false,
      renderCell: (p) => (
        <Tooltip title={p.row.errorMessage}>
          <Typography color="error">{p.row.errorMessage}</Typography>
        </Tooltip>
      ),
    },
    {
      field: 'errorCode',
      headerName: 'Error Code',
      width: 200,
      disableColumnMenu: true,
      filterable: false,
      renderCell: (p) => (
        <Tooltip title={p.row.errorCode}>
          <Typography color="error">{p.row.errorCode}</Typography>
        </Tooltip>
      ),
    },
    {
      field: 'providerErrorCode',
      headerName: 'Provider Error Code',
      width: 200,
      disableColumnMenu: true,
      filterable: false,
      renderCell: (p) => (
        <Tooltip title={p.row.providerErrorCode}>
          <Typography color="error">{p.row.providerErrorCode}</Typography>
        </Tooltip>
      ),
    },
    {
      field: 'createdAt',
      headerName: 'Created',
      width: 150,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (p) => formatDate(p.row.createdAt),
    },
    {
      field: 'updatedAt',
      headerName: 'Updated',
      width: 150,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (p) => formatDate(p.row.updatedAt),
    },
    {
      field: 'apiUser',
      headerName: 'API user',
      width: 150,
      sortable: false,
      disableColumnMenu: true,
      renderCell: ({ row }) => (
        <Tooltip title={<p>UserId: {row.apiUserId}<br />Email: {row.apiUserEmail}</p>} placement="top">
          <p>{row.apiUserType}</p>
        </Tooltip>
      ),
    },
  ];

  const fetchGoodwillPayouts = async () => {
    setLoading(true);

    const resp = await goodwillPayoutApi?.getGoodwillPayouts(props.customer.id);
  
    setGoodwillPayouts(resp.payouts);
    setLoading(false);
  };

  useEffect(() => {
    fetchGoodwillPayouts();
  }, [props.customer.id]);

  return (
    <>
      <DataGrid
        initialState={{
          sorting: {
            sortModel: [{ field: 'updatedAt', sort: 'desc' }],
          },
        }}
        style={{
          width: '100%',
          background: '#fff',
          border: 'none',
        }}
        autoHeight
        loading={loading}
        columns={columns}
        rows={goodwillPayouts}
        disableSelectionOnClick={true}
        disableColumnSelector={true}
        hideFooter={true}
      />
    </>
  );
};

export default GoodwillPayoutList;

import React, { useState } from 'react';
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  Typography,
} from '@mui/material';
import { Payment, PaymentApi } from '../api/PaymentApi';
import { useNotificationContext } from '../context/NotificationContext';
import Modal from './Modal';
import {LoadingButton} from "@mui/lab";

type Props = {
  paymentId: string;
  onSuccess: (payment: Payment) => void;
  close: () => void;
};

const payoutMethods = [
  { id: 'USER_DEFAULT', name: 'User\'s default method' },
  { id: 'TRUSTLY', name: 'Trustly' },
  { id: 'SWISH', name: 'Swish' },
  { id: 'AUTOGIRO', name: 'Autogiro' },
  { id: 'ROCKER_CARD', name: 'Rocker Card' },
];

const errorMessages = {
  'USER_HAS_NOT_CHOSEN_DEFAULT_PAYOUT_METHOD': 'User has not chosen the default payout method. Please select payout method manually.',
  'NO_REGISTERED_AUTOGIRO_PAYOUT_ACCOUNT': 'User does not have any Autogiro account registered. Please choose other payout method.',
  'NO_REGISTERED_TRUSTLY_PAYOUT_ACCOUNT': 'User does not have any Trustly account registered. Please choose other payout method.',
  'NO_REGISTERED_SWISH_PAYOUT_ACCOUNT': 'User does not have any Swish account registered. Please choose other payout method.',
};
type ErrorMessageKey = keyof typeof errorMessages;

const ForcePayoutModal = ({ paymentId, onSuccess, close }: Props) => {
  const [method, setMethod] = useState<string>('USER_DEFAULT');
  const [loading, setLoading] = useState<boolean>(false);
  const { setNotification } = useNotificationContext();
  const paymentApi = new PaymentApi();

  const updateValue = (e: any) => {
    const { value } = e.target;
    setMethod(() => value);
  };

  const forcePayout = async () => {
    setLoading(true);
    try {
      // TODO make forcePayout return payment and set that instead of calling get payment
      await paymentApi.forcePayout(paymentId, method);
      const payment = await paymentApi.getPayment(paymentId);

      setNotification({
        severity: 'success',
        message: 'Payout forced',
      });
      onSuccess(payment);
      close();
    } catch (e: any) {
      const errorCode = e.response?.data?.errorCode as ErrorMessageKey;
      const message = errorMessages[errorCode] ?? 'Failed to force payout';

      setNotification({
        severity: 'error',
        message,
      });
    }
    setLoading(false);
  };

  return (
    <Modal onClose={close}>
      <form>
        <Typography component="h2" fontSize={23}>
          Force payout:
        </Typography>
        <FormControl fullWidth sx={{ mt: 1, mb: 0.5 }}>
          <InputLabel id="method-label">Method *</InputLabel>
          <Select
            labelId="method-label"
            name="method"
            value={method}
            label="Method *"
            onChange={updateValue}
          >
            {payoutMethods.map(({ id, name }) => (
              <MenuItem value={id}>{name}</MenuItem>
            ))}
          </Select>
        </FormControl>

        <LoadingButton
          sx={{ mt: 2 }}
          type="submit"
          variant="contained"
          color="primary"
          fullWidth
          loading={loading}
          onClick={forcePayout}
        >
          Payout
        </LoadingButton>
        <Button variant="outlined" fullWidth sx={{ mt: 1 }} onClick={close}>
          Cancel
        </Button>
      </form>
    </Modal>
  );
};

export default ForcePayoutModal;

import { Typography } from '@mui/material';
import PageHeader from '../../components/PageHeader';
import MerchantList from './components/MerchantList';

const Merchants = () => {
  return (
    <>
      <PageHeader>
        <Typography variant="h5">Merchants</Typography>
      </PageHeader>
      <MerchantList />
    </>
  );
};

export default Merchants;

import React from 'react';
import {Link} from 'react-router-dom';
import {Card, CardContent, Grid} from '@mui/material';
import {styled} from '@mui/material/styles';
import {Offer} from '../../../api/OfferApi';
import formatMoney from '../../../utils/format-money';
import formatDate from '../../../utils/format-date';
import ExternalDetailsSection from './ExternalDetailsSection';

const GridTitle = styled('div')`
  display: flex;
  justify-content: flex-end;
  text-align: right;
`;

const OfferSection = ({offer}: { offer: Offer }) => {
  return (
    <>
      <Card sx={{position: 'relative'}}>
        <CardContent>
          <Grid container>
            <Grid container xs={6} rowSpacing={0.5} columnSpacing={1}>
              <Grid item xs={4}>
                <GridTitle>Seller:</GridTitle>
              </Grid>
              <Grid item xs={8}>
                <Link to={`/customers/${offer.seller.id}`}>
                  {offer.seller.name} ({offer.seller.email})
                </Link>
              </Grid>
              <Grid item xs={4}>
                <GridTitle>Price:</GridTitle>
              </Grid>
              <Grid item xs={8}>
                {formatMoney(offer.price)}
              </Grid>
              <Grid item xs={4}>
                <GridTitle>Description:</GridTitle>
              </Grid>
              <Grid item xs={8}>
                {offer.description}
              </Grid>
              {offer.manualCode && <Grid item xs={4}>
                <GridTitle>Manual code:</GridTitle>
              </Grid>}
              {offer.manualCode && <Grid item xs={8}>
                {offer.manualCode}
              </Grid>}
              {offer.productType != null && <Grid item xs={4}>
                <GridTitle>Product type:</GridTitle>
              </Grid>}
              {offer.productType != null && <Grid item xs={8}>
                {offer.productType}
              </Grid>}
              <Grid item xs={4}>
                <GridTitle>Default Refund Policy:</GridTitle>
              </Grid>
              <Grid item xs={8}>
                {offer.serviceFeeRefundable ? 'Refund (including fees)' : 'Refund (without fees)'}
              </Grid>
              <Grid item xs={4}>
                <GridTitle>Payment Link:</GridTitle>
              </Grid>
              <Grid item xs={8}><a href={offer.offerUrl} target="_blank" rel="noopener noreferrer">{offer.offerUrl}</a></Grid>
            </Grid>
            <Grid container xs={6} rowSpacing={0.5} columnSpacing={1}>
              <Grid item xs={4}>
                <GridTitle>Merchant:</GridTitle>
              </Grid>
              <Grid item xs={8}>
                {offer.merchantName || ''}
              </Grid>
              <Grid item xs={4}>
                <GridTitle>Created at:</GridTitle>
              </Grid>
              <Grid item xs={8}>
                {formatDate(offer.createdAt)}
              </Grid>
              <Grid item xs={4}>
                <GridTitle>Updated at:</GridTitle>
              </Grid>
              <Grid item xs={8}>
                {formatDate(offer.updatedAt)}
              </Grid>
            </Grid>
            <Grid container xs={6} rowSpacing={0.5} columnSpacing={1}>
              {offer.serviceFeeItems ? (
                <>
                  {Object.keys(offer.serviceFeeItems).map((key) => (
                    <React.Fragment key={key}>
                      <Grid item xs={4}>
                        <GridTitle>{key}:</GridTitle>
                      </Grid>
                      <Grid item xs={8}>
                        {formatMoney(offer.serviceFeeItems[key])}
                      </Grid>
                    </React.Fragment>
                  ))}
                </>
              ) : (
                <>
                  <Grid item xs={4}>
                    <GridTitle>Service fee:</GridTitle>
                  </Grid>
                  <Grid item xs={8}>
                    {formatMoney(offer.serviceFee.actual)}
                  </Grid>
                </>
              )}
            </Grid>

            {offer?.externalData &&
              Object.keys(offer.externalData).length !== 0 && (
                <ExternalDetailsSection offer={offer} />
              )}
          </Grid>
            {offer.affiliate && <Grid container xs={6} rowSpacing={0.5} columnSpacing={1}>
              <Grid item xs={4}>
                <GridTitle>Affiliate Code:</GridTitle>
              </Grid>
              <Grid item xs={8}> {offer.affiliate.code } </Grid>
            </Grid>}
        </CardContent>
      </Card>
    </>
  );
};

export default OfferSection;

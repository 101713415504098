import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { Button, TextField, Typography } from '@mui/material';
import { useQueryClient, useMutation } from 'react-query';
import Modal from '../../../components/Modal';
import {
  MerchantApi,
  PayoutMethod,
  PayoutMethodPayload,
} from '../../../api/MerchantApi';
import { useAuthContext } from '../../../context/AuthContext';
import { useNotificationContext } from '../../../context/NotificationContext';

const normalizeData = ({
  minPrice,
  maxPrice,
  fee,
  listOrder,
}: PayoutMethodPayload): PayoutMethodPayload => ({
  minPrice: (minPrice || 0) * 100,
  maxPrice: (maxPrice || 0) * 100,
  fee: (fee || 0) * 100,
  listOrder: listOrder || 0,
});

type Props = {
  payoutMethod: PayoutMethod;
  close: () => void;
};

const EditPayoutMethodModal = ({ payoutMethod, close }: Props) => {
  const merchantApi = new MerchantApi();
  const [data, setData] = useState({
    minPrice: payoutMethod.minAmount.amount / 100,
    maxPrice: payoutMethod.maxAmount.amount / 100,
    fee: payoutMethod.fee.amount / 100,
    listOrder: payoutMethod.listOrder,
  });
  const { merchantId } = useParams<{ merchantId: string }>();
  const { user } = useAuthContext();
  const { setNotification } = useNotificationContext();
  const queryClient = useQueryClient();

  const payoutMethodMutation = useMutation(
    (data: any) => merchantApi.updatePayoutMethod(data),
    {
      onError: () =>
        setNotification({
          severity: 'error',
          message: 'Something went wrong. please try again or contact support',
        }),
      onSettled: () => {
        queryClient.invalidateQueries(['merchantPayoutMethods', merchantId]);
        close();
      },
    },
  );

  const save = async () => {
    try {
      const normalizedData = normalizeData(data);
      payoutMethodMutation.mutate({
        merchantId: payoutMethod.merchantId,
        payoutMethod: payoutMethod.payoutMethod,
        data: normalizedData,
        currency: payoutMethod.maxAmount.currency
      });
    } catch (e) {
      setNotification({
        severity: 'error',
        message: 'Something went wrong. please try again or contact support',
      });
    }
  };

  const updateValue = (e: any) => {
    const { name, value } = e.target;
    setData((d: any) => ({ ...d, [name]: parseFloat(value) }));
  };

  return (
    <Modal onClose={close}>
      <Typography component="h2" fontSize={23}>
        Edit payment method
      </Typography>
      <div style={{ margin: '16px 0px' }}>
        <div>
          <b>Merchant: </b>
          <span>
            {
              user.merchants?.find((m) => m.id === payoutMethod.merchantId)
                ?.name
            }
          </span>
        </div>
        <div>
          <b>Payment Method: </b>
          <span>{payoutMethod.payoutMethod}</span>
        </div>
      </div>
      <form>
        <TextField
          id="minPrice"
          label="Min price"
          type="number"
          name="minPrice"
          fullWidth
          margin="dense"
          onChange={updateValue}
          value={data.minPrice}
        />
        <TextField
          id="maxPrice"
          label="Max price"
          type="number"
          name="maxPrice"
          fullWidth
          margin="dense"
          onChange={updateValue}
          value={data.maxPrice}
        />
        <TextField
          id="fee"
          label="Fee"
          type="number"
          name="fee"
          fullWidth
          margin="dense"
          onChange={updateValue}
          value={data.fee}
        />
        <TextField
          id="listOrder"
          label="List order"
          type="number"
          name="listOrder"
          fullWidth
          margin="dense"
          onChange={updateValue}
          value={data.listOrder}
        />
        <div style={{ marginTop: 12 }}>
          <Button
            variant="contained"
            color="primary"
            sx={{ mr: 1, width: '150px' }}
            onClick={save}
          >
            Save
          </Button>
          <Button variant="outlined" sx={{ width: '150px' }} onClick={close}>
            Cancel
          </Button>
        </div>
      </form>
    </Modal>
  );
};

export default EditPayoutMethodModal;

import { Box } from '@mui/material';
import { useAuthContext } from '../context/AuthContext';
import AppBar from './AppBar';
import AppDrawer from './AppDrawer';
import Main from './Main';

const App = () => {
  const { merchantId } = useAuthContext();

  return (
    <Box sx={{ display: 'flex' }} key={merchantId}>
      <AppBar />
      <AppDrawer />
      <Main />
    </Box>
  );
};

export default App;

import { useState } from 'react';
import {
  List,
  Grid,
  Card,
  CardHeader,
  CardContent,
  IconButton,
  Chip,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import { PayoutMethod } from '../../../api/MerchantApi';
import formatDate from '../../../utils/format-date';
import formatMoney from '../../../utils/format-money';
import EditPayoutMethodModal from './EditPayoutMethodModal';
import { useAuthContext } from '../../../context/AuthContext';

const PayoutMethodRow = ({
  payoutMethod,
  setEditPayoutMethod,
  canEditPayoutMethod
}: {
    payoutMethod: PayoutMethod;
    setEditPayoutMethod: (v: PayoutMethod) => void;
    canEditPayoutMethod: Boolean;
}) => (
  <Card
    sx={{ p: 2, mb: 2, boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px' }}
  >
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
      }}
    >
      <div>
        <b style={{ textDecoration: 'underline', fontSize: 14 }}>
          {payoutMethod.payoutMethod}
        </b>
        {!payoutMethod.enabled && <Chip label="Disabled" variant="outlined" color="error" size="small" sx={{ml:1}} />}
      </div>
      {canEditPayoutMethod && <IconButton onClick={() => setEditPayoutMethod(payoutMethod)}>
        <EditIcon />
      </IconButton>}
    </div>
    <Grid container spacing={0.5}>
      <Grid item xs={6} md={3} lg={2}>
        <b>List order</b>
      </Grid>
      <Grid item xs={6} md={3} lg={4}>
        <span>{payoutMethod.listOrder}</span>
      </Grid>
      <Grid item xs={6} md={3} lg={2}>
        <b>Fee</b>
      </Grid>
      <Grid item xs={6} md={3} lg={4}>
        <span>{formatMoney(payoutMethod.fee)}</span>
      </Grid>
      <Grid item xs={6} md={3} lg={2}>
        <b>Min price</b>
      </Grid>
      <Grid item xs={6} md={3} lg={4}>
        <span>{formatMoney(payoutMethod.minAmount)}</span>
      </Grid>
      <Grid item xs={6} md={3} lg={2}>
        <b>Max price</b>
      </Grid>
      <Grid item xs={6} md={3} lg={4}>
        <span>{formatMoney(payoutMethod.maxAmount)}</span>
      </Grid>
      <Grid item xs={6} md={3} lg={2}>
        <b>Created at</b>
      </Grid>
      <Grid item xs={6} md={3} lg={4}>
        <span>{formatDate(payoutMethod.createdAt)}</span>
      </Grid>
      <Grid item xs={6} md={3} lg={2}>
        <b>Updated at</b>
      </Grid>
      <Grid item xs={6} md={3} lg={4}>
        <span>{formatDate(payoutMethod.updatedAt)}</span>
      </Grid>
    </Grid>
  </Card>
);

const MerchantPayoutMethods = ({
  payoutMethods,
}: {
  payoutMethods: PayoutMethod[];
}) => {
  const [editPayoutMethod, setEditPayoutMethod] = useState<PayoutMethod>();
  const { user: { isSuperAdmin } } = useAuthContext();

  return (
    <>
      <Card>
        <CardHeader
          title="Payout methods:"
          titleTypographyProps={{ fontSize: 16 }}
        />
        <CardContent>
          {payoutMethods.map((method, i) => (
            <List key={method.payoutMethod} component="div" disablePadding>
              <PayoutMethodRow
                payoutMethod={method}
                setEditPayoutMethod={setEditPayoutMethod}
                canEditPayoutMethod={isSuperAdmin}
              />
            </List>
          ))}
        </CardContent>
      </Card>
      {editPayoutMethod && (
        <EditPayoutMethodModal
          payoutMethod={editPayoutMethod}
          close={() => setEditPayoutMethod(undefined)}
        />
      )}
    </>
  );
};

export default MerchantPayoutMethods;

import { useEffect, useState } from 'react';
import { Typography, IconButton, Tooltip, Button } from '@mui/material';
import { styled } from '@mui/material/styles';
import {
  Delete as DeleteIcon,
  NotificationsActive as NotificationsActiveIcon,
  NotificationsOff as NotificationsOffIcon,
  NotificationAdd as NotificationAddIcon,
  ChevronLeft as ChevronLeftIcon,
} from '@mui/icons-material';
import EditIcon from '@mui/icons-material/Edit';
import { Link } from 'react-router-dom';
import { OfferApi, Offer } from '../../../api/OfferApi';
import { useNotificationContext } from '../../../context/NotificationContext';
import ActionConfirmationModal, {
  ActionModalProps,
} from '../../../components/ActionConfirmationModal';
import { useAuthContext } from '../../../context/AuthContext';
import StatusLabel from '../../../components/StatusLabel';

import { useOfferContext } from '../Offer';
import { EventLogSubscriptionApi } from '../../../api/EventLogSubscriptionApi';
import { MerchantApi } from '../../../api/MerchantApi';
import { useQuery } from 'react-query';
import OfferModal from './OfferModal';

const Wrapper = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginBottom: '24px',
});

const TitleWrapper = styled('div')({
  display: 'flex',
  alignItems: 'center',
});

const ButtonContentWrapper = styled('div')(
  ({ disabled }: { disabled?: boolean }) => ({
    display: 'flex',
    flexDirection: 'column',
    color: disabled ? '#8C8DBA' : '#595388',
  }),
);

const StyledLink = styled(Link)({ display: 'flex', alignItems: 'center' });

const OfferHeader = ({
  offer,
  fetchOffer,
}: {
  offer: Offer;
  fetchOffer: any;
  }) => {
  const offerApi = new OfferApi();
  const merchantApi = new MerchantApi();
  const eventLogSubscriptioinApi = new EventLogSubscriptionApi();

  const [updateOfferModal, setUpdateOfferModal] = useState(false);
  const [isSubscribed, setIsSubscribed] = useState(false);
  const authContext = useAuthContext();

  const fetchIsSubscribed = async () => {
    if (authContext.can('SubscribeToEventLogEvents')) {
      const resp = await eventLogSubscriptioinApi.isSubscribed({
        $type: 'OfferIdSubject',
        merchantId: offer.merchantId,
        offerId: offer.id
      })
      setIsSubscribed(resp.isSubscribed);
    }
  };

  useEffect(() => {
    fetchIsSubscribed();
  }, [offer.id]);

  const { data: merchantConfigData, isLoading: isMerchantConfigLoading } =
    useQuery(['merchantConfig', offer.merchantId], () =>
      merchantApi.getMerchantConfig(offer.merchantId),
    );

  const [actionModal, setActionModal] = useState<ActionModalProps>({
    text: '',
  });
  const { setNotification } = useNotificationContext();

  const { paymentsWithPayouts } = useOfferContext();

  const statusIcons = () => {
    return paymentsWithPayouts
      ?.filter((payment) => payment.status === 'SETTLED')
      .reduce((prev, curr) => {
        prev += curr.pauseState === 'PAUSED' && !prev.includes('⏸') ? '⏸' : '';
        prev +=
          curr.payoutConsent === 'BLOCKED' && !prev.includes('⛔')
            ? '⛔'
            : curr.payoutConsent === 'CONFIRMED' && !prev.includes('✅')
            ? '✅'
            : '';

        return prev;
      }, '');
  };

  const tooltipText = (statusIcon?: string) =>
    statusIcon
      ?.split('')
      .map((l) =>
        l === '⏸'
          ? 'Paused'
          : l === '⛔'
          ? 'Blocked'
          : l === '✅'
          ? 'Payout consent'
          : '',
      )
      // @ts-ignore
      .reduce((prev, curr, i) => {
        prev += ` ${i !== 0 ? '•' : ''} ${curr}`;
        return prev;
      }, ``);

  const deleteOffer = async () => {
    try {
      await offerApi.deleteOffer(offer.id);
      await fetchOffer();
      setActionModal({ text: '' });
      setNotification({
        severity: 'success',
        message: 'Offer deleted successfully',
      });
    } catch (e) {
      setActionModal({ text: '' });
      setNotification({
        severity: 'error',
        message: 'Something went wrong. please try again or contact support',
      });
    }
  };

  const subscribe = async () => {
    try {
      await eventLogSubscriptioinApi.subscribe({
        $type: 'OfferIdSubject',
        merchantId: offer.merchantId,
        offerId: offer.id
      });
      setActionModal({ text: '' });
      setNotification({
        severity: 'success',
        message: 'Successfully subscribed to offer updates',
      });
    } catch (e) {
      setActionModal({ text: '' });
      setNotification({
        severity: 'error',
        message: 'Something went wrong. please try again or contact support',
      });
    } finally {
      await fetchIsSubscribed();
    }
  };

  const unsubscribe = async () => {
    try {
      await eventLogSubscriptioinApi.unsubscribe({
        $type: 'OfferIdSubject',
        merchantId: offer.merchantId,
        offerId: offer.id
      });
      setActionModal({ text: '' });
      setNotification({
        severity: 'success',
        message: 'Successfully unsubscribed from offer updates',
      });
    } catch (e) {
      setActionModal({ text: '' });
      setNotification({
        severity: 'error',
        message: 'Something went wrong. please try again or contact support',
      });
    } finally {
      await fetchIsSubscribed();
    }
  };

  const notDeletable = !(
    offer.status === 'AVAILABLE' || offer.status === 'WAITING_ON_RENEW'
  );

  return (
    <>
      <StyledLink to={`/offers`}>
        <ChevronLeftIcon sx={{ fontSize: 28 }} />
        <span>Return to Offers overview</span>
      </StyledLink>
      <Wrapper>
        <TitleWrapper>
          <Typography variant="h4" mr={2}>
            {offer.title}
          </Typography>
          <StatusLabel status={offer.status} />
          <Tooltip title={tooltipText(statusIcons()) ?? ''}>
            <Typography ml={3} fontSize={24}>
              {statusIcons()}{isSubscribed && <NotificationsActiveIcon sx={{ fontSize: 30 }} />}
            </Typography>
          </Tooltip>
        </TitleWrapper>
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          {authContext.can("CreateEntity") && merchantConfigData?.backofficeCreateEntityPermission?.createOfferAllowed &&
            <Button
              variant="contained"
              startIcon={<EditIcon />}
              disabled={offer.status !== 'AVAILABLE'}
              sx={{ margin: 2 }}
              onClick={() => setUpdateOfferModal(true)}
            >
              Update offer
            </Button>}
          {updateOfferModal &&
            <OfferModal
              offerId={offer.id}
              initialData={{
                title: offer.title,
                escrowValue: offer.escrowValue,
                serviceFeeItems: Object.entries(offer.serviceFeeItems).map(([name, value]) => ({ name, value })),
                images: offer.images
              }}
              title={`Update offer`}
              onSuccess={(o) => { }}
              close={() => setUpdateOfferModal(false)} />}
          {authContext.can('SubscribeToEventLogEvents') && !isSubscribed && <IconButton
            size="large"
            onClick={() =>
              setActionModal({
                text: 'You are about to subscribe for notifications for this offer',
                action: subscribe,
              })
            }
          >
            <ButtonContentWrapper>
              <NotificationAddIcon sx={{ fontSize: 40 }} />
              <span style={{ fontSize: 10 }}>Subscribe</span>
            </ButtonContentWrapper>
          </IconButton>}
          {authContext.can('SubscribeToEventLogEvents') && isSubscribed && <IconButton
            size="large"
            onClick={() =>
              setActionModal({
                text: 'You are about to unsubscribe from notifications for this offer',
                action: unsubscribe,
              })
            }
          >
            <ButtonContentWrapper>
              <NotificationsOffIcon sx={{ fontSize: 40 }} />
              <span style={{ fontSize: 10 }}>Unsubscribe</span>
            </ButtonContentWrapper>
          </IconButton>}
          <IconButton
            size="large"
            disabled={notDeletable}
            onClick={() =>
              setActionModal({
                text: 'You are about to delete this offer',
                action: deleteOffer,
              })
            }
          >
            <ButtonContentWrapper disabled={notDeletable}>
              <DeleteIcon sx={{ fontSize: 40 }} />
              <span style={{ fontSize: 10 }}>Delete</span>
            </ButtonContentWrapper>
          </IconButton>
        </div>
      </Wrapper>
      {actionModal.text && (
        <ActionConfirmationModal
          {...actionModal}
          close={() => setActionModal({ text: '' })}
        />
      )}
    </>
  );
};

export default OfferHeader;

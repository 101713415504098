import { Card, CardHeader, CardContent, Grid } from '@mui/material';
import { MerchantConfig } from '../../../api/MerchantApi';
import formatDuration from '../../../utils/format-duration';
import BlockIcon from '@mui/icons-material/Block';
import CheckIcon from '@mui/icons-material/Check';

const MerchantSettingsInfo = ({ config }: { config: MerchantConfig }) => (
  <Card style={{ marginTop: 25 }}>
    <CardHeader
      title="Settings:"
      titleTypographyProps={{ fontSize: 16 }}
    />
    <CardContent>
      <Grid container spacing={0.5}>
        <Grid item xs={3}>
          <b>Send notifications</b>
        </Grid>
        <Grid item xs={9}>
          <span>{config.sendNotification ? 'enabled' : 'disabled'}</span>
        </Grid>
        <Grid item xs={3}>
          <b>Loan applications</b>
        </Grid>
        <Grid item xs={9}>
          <span>{config.loanApplicationsEnabled ? 'enabled' : 'disabled'}</span>
        </Grid>
        <Grid item xs={3}>
          <b>Payment timeout duration</b>
        </Grid>
        <Grid item xs={9}>
          <span>{formatDuration(config.paymentTimeoutDuration)}</span>
        </Grid>
        <Grid item xs={3}>
          <b>Minimum customer age</b>
        </Grid>
        <Grid item xs={9}>
          <span>{config.minAgeYears} years</span>
        </Grid>
        <Grid item xs={3}>
          <b>Autogiro customer reference</b>
        </Grid>
        <Grid item xs={9}>
          <span>{config.autogiroCustomerReference}</span>
        </Grid>
        <Grid item xs={3}>
          <b>Bid enabled</b>
        </Grid>
        <Grid item xs={9}>
          <span>{config.offer.bidEnabled ? <CheckIcon /> : <BlockIcon />}</span>
        </Grid>
        <Grid item xs={3}>
          <b>Campaign mode</b>
        </Grid>
        <Grid item xs={9}>
          <span>{config.serviceFee.campaignMode ? <CheckIcon /> : <BlockIcon />}</span>
        </Grid>
      </Grid>
    </CardContent>
  </Card>
);

export default MerchantSettingsInfo;

import { useState } from 'react';
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  Typography,
  TextField,
} from '@mui/material';
import { useNotificationContext } from '../context/NotificationContext';
import Modal from './Modal';
import { PayoutAccount } from '../api/PayoutApi';
import { CustomerApi } from '../api/CustomerApi';
import {LoadingButton} from "@mui/lab";

type Props = {
  customerId: string;
  onSuccess: (payoutAccount: PayoutAccount) => void;
  close: () => void;
};

const emptyBankAccount = {
  clearingNumber: '',
  accountNumber: '',
  bankName: '',
  verificationStatus: 'UNVERIFIED',
};

const errorMessages = {
  NO_MATCHING_BANK_FOR_ACCOUNT_NUMBER:
    'No bank was found that would match provided clearing number',
  ACCOUNT_NUMBER_REJECTED: 'Invalid account number'
};
type ErrorMessageKey = keyof typeof errorMessages;

const AddBankAccountModal = ({ customerId, onSuccess, close }: Props) => {

  const [bankAccount, setBankAccount] = useState<any>(emptyBankAccount);
  const [loading, setLoading] = useState<boolean>(false);
  const { setNotification } = useNotificationContext();
  const customerApi = new CustomerApi();

  const updateValue = (e: any) => {
    const { name, value } = e.target;
    setBankAccount((u: any) => ({ ...u, [name]: value }));
  };

  const addBankAccount = async (e: any) => {
    e.preventDefault();
    setLoading(true);

    if (!e.target.reportValidity()) {
      setLoading(false);
      return;
    }

    try {
      const payoutAccount = await customerApi.addBankAccount(
        customerId,
        bankAccount.clearingNumber,
        bankAccount.accountNumber,
        'Icke namngivet konto',
        bankAccount.bankName,
        bankAccount.verificationStatus,
      );

      setNotification({
        severity: 'success',
        message: 'Bank account added successfully',
      });
      onSuccess(payoutAccount);
      close();
    } catch (e: any) {
      const errorCode = e.response?.data?.errorCode as ErrorMessageKey;
      const message = errorMessages[errorCode] ?? 'Failed to add bank account';

      setNotification({
        severity: 'error',
        message,
      });
    }
    setLoading(false);
  };

  return (
    <Modal onClose={close}>
      <form onSubmit={addBankAccount}>
        <Typography component="h2" fontSize={23}>
          Add bank account:
        </Typography>
        <TextField
          id="clearing-number"
          label="Clearing number"
          type="text"
          name="clearingNumber"
          inputProps={{
            autocomplete: 'off',
            inputMode: 'numeric',
            pattern: '[0-9]{4,5}',
          }}
          required
          fullWidth
          margin="dense"
          onChange={updateValue}
          value={bankAccount.clearingNumber}
        />
        <TextField
          id="account-number"
          label="Account number"
          type="text"
          name="accountNumber"
          inputProps={{
            autocomplete: 'off',
            inputMode: 'numeric',
            pattern: '[0-9]{2,10}',
          }}
          required
          fullWidth
          margin="dense"
          onChange={updateValue}
          value={bankAccount.accountNumber}
        />
        <TextField
          id="bank-name"
          label="Bank name"
          type="text"
          name="bankName"
          required
          fullWidth
          margin="dense"
          onChange={updateValue}
          value={bankAccount.bankName}
        />
        <FormControl fullWidth sx={{ mt: 1, mb: 0.5 }}>
          <InputLabel id="verification-status-label">
            Verification status
          </InputLabel>
          <Select
            labelId="verification-status-label"
            name="verificationStatus"
            required
            value={bankAccount.verificationStatus}
            label="Verification status *"
            onChange={updateValue}
          >
            <MenuItem value="UNVERIFIED">Unverified</MenuItem>
            <MenuItem value="VERIFIED_OWNER">Verified owner</MenuItem>
          </Select>
        </FormControl>

        <LoadingButton
          type="submit"
          variant="contained"
          color="primary"
          loading={loading}
          fullWidth
          sx={{ mt: 2 }}
        >
          Add bank account
        </LoadingButton>
        <Button variant="outlined" fullWidth sx={{ mt: 1 }} onClick={close}>
          Cancel
        </Button>
      </form>
    </Modal>
  );
};

export default AddBankAccountModal;

import { createTheme, ThemeOptions } from '@mui/material/styles';
import type {} from '@mui/x-data-grid/themeAugmentation';
import shadows, { Shadows } from '@mui/material/styles/shadows';
import { deepmerge } from '@mui/utils';
import { Theme as RockerTheme } from '@rocker-ui/core';
import { MUITheme } from '@rocker-ui/web';
// @ts-ignore
import ObjektivMk2W03XBold from './fonts/ObjektivMk2App-XBold.woff';
// @ts-ignore
import ObjektivMk2W03Bold from './fonts/ObjektivMk2W03Bold.woff2';
// @ts-ignore
import ObjektivMk2W03Regular from './fonts/ObjektivMk2W03Regular.woff2';
import './index.css';

const rocker_core_theme = RockerTheme.assemble(
  RockerTheme.ColorScheme.Light,
  RockerTheme.FontFamily.Default('web'),
);

const localMuiTheme: ThemeOptions = {
  palette: {
    primary: {
      main: '#38355A',
    },
    secondary: {
      main: '#6235FF',
    },
    background: {
      default: '#F2F3F7',
      paper: '#FFFFFF',
    },
    text: {
      primary: '#2B2654',
      disabled: '#2B2654',
      secondary: '#2B2654',
    },
  },
  shadows: Array(shadows.length).fill('none') as Shadows,
  typography: {
    fontFamily: rocker_core_theme.fontFamily.regular,
    h1: {
      fontFamily: rocker_core_theme.fontFamily.bold,
    },
    h2: {
      fontFamily: rocker_core_theme.fontFamily.bold,
    },
    h5: {
      fontFamily: rocker_core_theme.fontFamily.bold,
    },
    h6: {
      fontFamily: rocker_core_theme.fontFamily.bold,
    },
  },
  components: {
    MuiTabs: {
      styleOverrides: {
        indicator: {
          backgroundColor: 'transparent',
        },
        flexContainer: {
          background: '#F2F3F7',
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          '&.Mui-disabled': {
            opacity: 0.5,
          },
          textTransform: 'capitalize',
          color: '#595388',
          background: '#DBDDF0',
          borderRadius: '16px 16px 0px 0px',
          '&.Mui-selected': {
            fontWeight: 'bold',
            color: '#6235FF',
            background: '#FFFFFF',
          },
        },
      },
    },
    MuiDrawer: {
      styleOverrides: {
        paper: {
          borderRight: 'none',
        },
      },
    },
    MuiListItemButton: {
      styleOverrides: {
        root: {
          color: '#8C8DBA',
          fontWeight: 500,
          borderBottom: '2px solid transparent',
          '&.Mui-selected': {
            backgroundColor: '#F2F3F7',
            color: '#6235FF',
            borderBottom: '2px solid #6235FF',
          },
        },
      },
    },
    MuiInput: {
      styleOverrides: {
        root: {
          borderRadius: '16px',
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          color: '#2B2654',
        },
      },
    },
    MuiDataGrid: {
      styleOverrides: {
        panelWrapper: {
          background: '#F2F3F7',
          boxShadow: 'rgb(100 100 111 / 20%) 0px 7px 29px 0px',
        },
        menu: {
          boxShadow: 'rgb(100 100 111 / 20%) 0px 7px 29px 0px',
        },
        columnHeader: {
          fontFamily: rocker_core_theme.fontFamily.bold,
        },
        panelContent: {
          backgroundColor: '#F2F3F7',
        },
        root: {
          color: '#000',
          borderRadius: '16px',
          border: 'none',
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          borderRadius: '16px',
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          backgroundColor: '#ffffff',
        },
      },
    },
    MuiCardContent: {
      styleOverrides: {
        root: {
          ':last-child': {
            paddingBottom: '16px',
          },
        },
      },
    },
    MuiPopover: {
      styleOverrides: {
        paper: {
          boxShadow: 'rgba(149, 157, 165, 0.2) 0px 8px 24px',
        },
      },
    },
    MuiCssBaseline: {
      styleOverrides: `
      @font-face {
        font-family: ${rocker_core_theme.fontFamily.regular};
        font-style: normal;
        font-weight: 400;
        src:  url(${ObjektivMk2W03Regular}) format('woff2');
      }
      @font-face {
        font-family: ${rocker_core_theme.fontFamily.bold};
        font-style: normal;
        font-weight: 600;
        src: url(${ObjektivMk2W03Bold}) format('woff2');
      }
      @font-face {
        font-family: ${rocker_core_theme.fontFamily.xbold};
        font-style: normal;
        font-weight: 800;
        src: url(${ObjektivMk2W03XBold}) format('woff2');
      }
    `,
    },
  },
};

export default createTheme({
  ...rocker_core_theme,
  ...deepmerge(MUITheme, localMuiTheme),
});

import { useState } from 'react';
import {
  List,
  Grid,
  Card,
  CardHeader,
  CardContent,
  IconButton,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import BlockIcon from '@mui/icons-material/Block';
import CheckIcon from '@mui/icons-material/Check';
import { PaymentMethod } from '../../../api/MerchantApi';
import formatDate from '../../../utils/format-date';
import formatMoney from '../../../utils/format-money';
import EditPaymentMethodModal from './EditPaymentMethodModal';
import { useAuthContext } from '../../../context/AuthContext';

const PaymentMethodRow = ({
  paymentMethod,
  setEditPaymentMethod,
  canEditPaymentMethod
}: {
    paymentMethod: PaymentMethod;
    setEditPaymentMethod: (v: PaymentMethod) => void;
    canEditPaymentMethod: Boolean;
  
}) => (
  
  <Card
    sx={{ p: 2, mb: 2, boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px' }}
  >
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
      }}
    >
      <b style={{ textDecoration: 'underline', fontSize: 14 }}>
        {paymentMethod.paymentMethod}
      </b>
      {canEditPaymentMethod && <IconButton onClick={() => setEditPaymentMethod(paymentMethod)}>
        <EditIcon />
      </IconButton>}
    </div>
    <Grid container spacing={0.5}>
      <Grid item xs={6} md={3} lg={2}>
        <b>List order</b>
      </Grid>
      <Grid item xs={6} md={3} lg={4}>
        <span>{paymentMethod.listOrder}</span>
      </Grid>
      <Grid item xs={6} md={3} lg={2}>
        <b>Fee</b>
      </Grid>
      <Grid item xs={6} md={3} lg={4}>
        <span>{formatMoney(paymentMethod.fee)}</span>
      </Grid>
      <Grid item xs={6} md={3} lg={2}>
        <b>Min price</b>
      </Grid>
      <Grid item xs={6} md={3} lg={4}>
        <span>{formatMoney(paymentMethod.minPrice)}</span>
      </Grid>
      <Grid item xs={6} md={3} lg={2}>
        <b>Max price</b>
      </Grid>
      <Grid item xs={6} md={3} lg={4}>
        <span>{formatMoney(paymentMethod.maxPrice)}</span>
      </Grid>
      <Grid item xs={6} md={3} lg={2}>
        <b>Created at</b>
      </Grid>
      <Grid item xs={6} md={3} lg={4}>
        <span>{formatDate(paymentMethod.createdAt)}</span>
      </Grid>
      <Grid item xs={6} md={3} lg={2}>
        <b>Updated at</b>
      </Grid>
      <Grid item xs={6} md={3} lg={4}>
        <span>{formatDate(paymentMethod.updatedAt)}</span>
      </Grid>
      <Grid item xs={6} md={3} lg={2}>
        <b>Auth required</b>
      </Grid>
      <Grid item xs={6} md={3} lg={4}>
        <span>{paymentMethod.allowUnauthenticatedBuyers ? <BlockIcon /> : <CheckIcon />}</span>
      </Grid>
    </Grid>
  </Card>
);

const MerchantPaymentMethods = ({
  paymentMethods,
}: {
  paymentMethods: PaymentMethod[];
}) => {
  const [editPaymentMethod, setEditPaymentMethod] = useState<PaymentMethod>();
  const { user: { isSuperAdmin } } = useAuthContext();

  return (
    <>
      <Card>
        <CardHeader
          title="Payment methods:"
          titleTypographyProps={{ fontSize: 16 }}
        />
        <CardContent>
          {paymentMethods.map((method, i) => (
            <List key={method.paymentMethod} component="div" disablePadding>
              <PaymentMethodRow
                paymentMethod={method}
                setEditPaymentMethod={setEditPaymentMethod}
                canEditPaymentMethod={isSuperAdmin}
              />
            </List>
          ))}
        </CardContent>
      </Card>
      {editPaymentMethod && (
        <EditPaymentMethodModal
          paymentMethod={editPaymentMethod}
          close={() => setEditPaymentMethod(undefined)}
        />
      )}
    </>
  );
};

export default MerchantPaymentMethods;

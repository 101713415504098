import { useState, useEffect } from 'react';
import {
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  RadioGroup,
  FormControlLabel,
  Radio,
  Button,
  Typography,
} from '@mui/material';
import { useAuthContext } from '../context/AuthContext';
import { Group, UserApi } from '../api/UserApi';
import { InviteApi } from '../api/InviteApi';
import { useNotificationContext } from '../context/NotificationContext';
import Modal from './Modal';
import {LoadingButton} from "@mui/lab";

type Props = { onSuccess: () => void; close: () => void };

const tempUser = {
  email: '',
  merchant: '',
  groupId: '',
  superAdmin: false,
};

const normalizeUser = (user: any) => ({
  ...user,
  access: [user.merchant],
});

const normalizeAgent = (user: any) => ({
  email: user.email,
  groupId: user.groupId,
});

const validateUser = (user: any) => {
  let resp = { error: '' };
  if (!user.groupId) resp.error = 'group';
  if (!user.merchant) resp.error = 'merchant';
  if (!user.email) resp.error = 'email';
  return resp;
};

const validateAgent = (user: any) => {
  let resp = { error: '' };
  if (!user.groupId) resp.error = 'group';
  if (!user.email) resp.error = 'email';
  return resp;
};

const AgentInviteModal = ({ onSuccess, close }: Props) => {
  const [user, setUser] = useState<any>(tempUser);
  const [groups, setGroups] = useState<Group[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const { setNotification } = useNotificationContext();
  const userApi = new UserApi();
  const inviteApi = new InviteApi();

  const {
    user: { merchants, isMerchantAdmin },
  } = useAuthContext();

  const fetchGroups = async () => {
    if(isMerchantAdmin) {
      const groups = await userApi.getGroupsForMerchant();
      setGroups(groups);
    } else {
      const groups = await userApi.getGroups();
      setGroups(groups);
    }
  };

  const updateValue = (e: any) => {
    const { name, value } = e.target;
    setUser((u: any) => ({ ...u, [name]: value }));
  };

  const sendInvite = async () => {
    setLoading(true);
    const validation = validateUser(user);
    if (validation?.error) {
      setLoading(false);
      return window.alert(`Missing field: ${validation.error}`);
    }
    try {
      await inviteApi.createInvitation(normalizeUser(user));

      setNotification({
        severity: 'success',
        message: 'Invite sent!',
      });
      onSuccess();
      close();
    } catch (e) {
      setNotification({
        severity: 'error',
        message: 'Something went wrong. please try again or contact support',
      });
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchGroups();
  }, []);

  return (
    <Modal onClose={close}>
      <form>
        <Typography component="h2" fontSize={23}>
          Send invite:
        </Typography>
        <TextField
          id="user-email"
          label="Email *"
          type="email"
          name="email"
          fullWidth
          margin="dense"
          onChange={updateValue}
          value={user.email}
        />
        
        <FormControl fullWidth sx={{ mt: 1, mb: 0.5 }}>
          <InputLabel id="merchant-label">Merchant *</InputLabel>
          <Select
            labelId="merchant-label"
            name="merchant"
            value={user.merchant}
            label="Merchant *"
            onChange={updateValue}
          >
            {merchants.map(({ id, name }) => (
              <MenuItem value={id}>{name}</MenuItem>
            ))}
          </Select>
        </FormControl>
        <Typography component="h3" fontSize={18} mt={1}>
          Group: *
        </Typography>
        <RadioGroup defaultValue={user?.groupId}>
          {groups.map(({ id, name }) => (
            <FormControlLabel
              key={name}
              control={
                <Radio
                  color="primary"
                  value={id}
                  name="groupId"
                  onChange={updateValue}
                />
              }
              label={name}
            />
          ))}
        </RadioGroup>
        <LoadingButton
          variant="contained"
          color="primary"
          fullWidth
          sx={{ mt: 2 }}
          loading={loading}
          onClick={sendInvite}
        >
          Invite
        </LoadingButton>
        <Button variant="outlined" fullWidth sx={{ mt: 1 }} onClick={close}>
          Cancel
        </Button>
      </form>
    </Modal>
  );
};

export default AgentInviteModal;

import * as React from 'react';
import { useState, useContext, useMemo } from 'react';

type Notification = {
  severity: 'error' | 'success';
  message: string;
} | null;

type NotificationContextType = {
  notification: Notification;
  setNotification: (notification: Notification) => void;
  clearNotification: () => void;
};

const NotificationContext = React.createContext({} as NotificationContextType);

const NotificationContextProvider: React.FC = ({ children }) => {
  const [notification, setNotification] = useState<Notification>(null);

  const clearNotification = () => setNotification(null);

  const memoedValue = useMemo(
    () => ({
      notification,
      setNotification,
      clearNotification,
    }),
    [notification],
  );

  return (
    <NotificationContext.Provider value={memoedValue}>
      {children}
    </NotificationContext.Provider>
  );
};

const useNotificationContext = () => useContext(NotificationContext);

export { NotificationContextProvider, useNotificationContext };
export default NotificationContext;

import { createClient } from './client';
import { MoneyMinor } from './shared';

export interface RichPaymentShippingResponse {
  shipping: RichShippingResponse;
  returnShipping?: RichShippingResponse;
}

export type ShippingAddress = {
  streetAddress: string;
  postalCode: string;
  city: string;
  countryCode: string;
};

export type ServicePointShipping = {
  name: string;
  address: ShippingAddress;
  $type: 'ServicePoint';
};

export type HomeShipping = {
  $type: 'Home';
};

export type ShippingType = HomeShipping | ServicePointShipping;

export type ShippingProvider = 'DhlShippingId';

export type ShippingId = {
  id: string;
  $type: ShippingProvider;
};

export type ShippingParty = {
  name: string;
  phone: string;
  email: string;
  address: ShippingAddress;
};

export type WeightUnit = 'KILOGRAM' | 'GRAM';

export type Weight = {
  value: number;
  unit: WeightUnit;
};

export type PackageInfo = {
  weight: Weight;
};

export interface RichShippingResponse {
  id: ShippingId;
  shipper: ShippingParty;
  receiver: ShippingParty;
  shippingType: ShippingType;
  createdAt: string;
  deliveredAt?: string;
  trackingUri: string;
  qrCode: string;
  packagesInfo: Array<PackageInfo>,
  shippingFeeInclVat: MoneyMinor;
}

export class ShippingApi {
  client;

  constructor() {
    this.client = createClient();
  }

  async getShipping(paymentId: string): Promise<RichPaymentShippingResponse> {
    const resp = await this.client.get(
      `/v1/back-office/payments/${paymentId}/shipping`
    );
    return resp.data;
  }
}

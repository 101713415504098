import { Link } from 'react-router-dom';
import { useQueryClient, useQuery, useMutation } from 'react-query';
import {
  Button,
  TableContainer,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Card,
  Skeleton,
  Typography,
} from '@mui/material';
import { HighlightOff as HighlightOffIcon } from '@mui/icons-material';
import { UserApi } from '../../../api/UserApi';
import { useAuthContext } from '../../../context/AuthContext';
import { useNotificationContext } from '../../../context/NotificationContext';
import ActionConfirmationModal, {
  ActionModalProps,
} from '../../../components/ActionConfirmationModal';
import { useState } from 'react';

const AgentList = () => {
  const [actionModal, setActionModal] = useState<ActionModalProps>({
    text: '',
  });
  const userApi = new UserApi();
  const { user, merchantId } = useAuthContext();
  const { setNotification } = useNotificationContext();
  const queryClient = useQueryClient();
  const { merchants } = user;

  const { isLoading, isFetching, data } = useQuery(['agents', merchantId], () =>
    userApi.getUsers(merchantId),
  );

  const mutation = useMutation((id: string) => userApi.deleteUser(id), {
    onError: () =>
      setNotification({
        severity: 'error',
        message: 'Something went wrong. please try again or contact support',
      }),
    onSettled: () => {
      queryClient.invalidateQueries(['agents', merchantId]);
      setActionModal({ text: '' });
    },
  });

  return (
    <>
      <Typography variant="h6" mt={4}>
        Existing Agents
      </Typography>
      <Card style={{ marginTop: 16 }}>
        {isLoading || isFetching ? (
          <div style={{ padding: 20 }}>
            <Skeleton />
            <Skeleton />
            <Skeleton />
            <Skeleton />
          </div>
        ) : (
          <TableContainer style={{ maxHeight: 600 }}>
            <Table stickyHeader aria-label="users">
              <TableHead>
                <TableRow>
                  <TableCell variant="head">Email</TableCell>
                  <TableCell variant="head">Merchant</TableCell>
                  <TableCell variant="head" />
                </TableRow>
              </TableHead>
              <TableBody>
                {data?.map((row) => (
                  <TableRow key={row.id}>
                    <TableCell>
                      <Link to={`/agents/${row.id}`}>{row.email}</Link>
                    </TableCell>
                    <TableCell>
                      {merchants.find(({ id }) => row.own_merchant === id)
                        ?.name || ''}
                    </TableCell>
                    <TableCell>
                      <Button
                        variant="contained"
                        disabled={row.id === user.uid || !user.isSuperAdmin}
                        color="error"
                        size="small"
                        onClick={() =>
                          setActionModal({
                            text: 'Delete Agent',
                            action: () => mutation.mutate(row.id),
                          })
                        }
                      >
                        <HighlightOffIcon />
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </Card>
      {actionModal.text && (
        <ActionConfirmationModal
          {...actionModal}
          close={() => setActionModal({ text: '' })}
        />
      )}
    </>
  );
};

export default AgentList;

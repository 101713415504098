import Cookies from 'js-cookie';

const getCookie = (key: string) => {
  const value = Cookies.get(key);
  try {
    const parsedValue = value ? JSON.parse(value) : '';
    return parsedValue;
  } catch (e) {
    return value;
  }
};

const setCookie = (
  key: string,
  value: string | Record<string, any>,
  options?: Record<string, any>,
) => {
  const stringifiedValue = JSON.stringify(value);
  Cookies.set(key, stringifiedValue, options);
};

const removeCookie = (key: string) => Cookies.remove(key);

export { getCookie, setCookie, removeCookie };

import { Card, CardHeader, CardContent, Grid } from '@mui/material';
import { Company, Escrow } from '../../../api/MerchantApi';
import formatDuration from '../../../utils/format-duration';

const MerchantEscrowInfo = ({ escrow }: { escrow: Escrow }) => (
  <Card style={{ marginTop: 25 }}>
    <CardHeader
      title="Escrow:"
      titleTypographyProps={{ fontSize: 16 }}
    />
    <CardContent>
      <Grid container spacing={0.5}>
        <Grid item xs={3}>
          <b>First reminder duration</b>
        </Grid>
        <Grid item xs={9}>
          <span>{formatDuration(escrow.firstReminderDuration)}</span>
        </Grid>
        <Grid item xs={3}>
          <b>Second reminder duration</b>
        </Grid>
        <Grid item xs={9}>
          <span>{formatDuration(escrow.secondReminderDuration)}</span>
        </Grid>
        <Grid item xs={3}>
          <b>Timeout duration</b>
        </Grid>
        <Grid item xs={9}>
          <span>{formatDuration(escrow.timeoutDuration)}</span>
        </Grid>
        <Grid item xs={3}>
          <b>Timeout action</b>
        </Grid>
        <Grid item xs={9}>
          <span>{escrow.timeoutAction.toLowerCase()}</span>
        </Grid>
      </Grid>
    </CardContent>
  </Card>
);

export default MerchantEscrowInfo;

import { createClient } from './client';

export type Group = {
  id: string;
  name: string;
  description: string;
  template: string;
  created_at: string;
};

export type GroupDetails = {
  id: string;
  name: string;
  description: string;
  members: string[];
  roles: string[];
  merchants: string[];
  template: string;
};

export type GroupMember = {
  id: string;
  email: string;
  own_merchant: string;
  created_at: string;
  inactive: boolean;
};

export class GroupApi {
  client;

  constructor() {
    this.client = createClient();
  }

  async getGroups(): Promise<Group[]> {
    const resp = await this.client.get(`/v1/back-office/admin/groups`);
    return resp.data;
  }

  async createGroup(name: string): Promise<Group> {
    const resp = await this.client.post(
      `/v1/back-office/admin/groups/${name}`,
      { name },
    );
    return resp.data;
  }

  async deleteGroup(groupId: string) {
    const resp = await this.client.delete(
      `/v1/back-office/admin/groups/${groupId}`,
    );
    return resp.data;
  }

  async getGroup(groupId: string): Promise<GroupDetails> {
    const resp = await this.client.get(
      `/v1/back-office/admin/groups/${groupId}`,
    );
    return resp.data;
  }

  async getGroupMembers(groupId: string): Promise<GroupMember[]> {
    const resp = await this.client.get(
      `/v1/back-office/admin/groups/${groupId}/members`,
    );
    return resp.data;
  }

  async putGroupMember(groupId: string, agentId: string) {
    const resp = await this.client.put(
      `/v1/back-office/admin/groups/${groupId}/members/${agentId}`,
    );
    return resp.data;
  }

  async deleteGroupMember(groupId: string, agentId: string) {
    const resp = await this.client.delete(
      `/v1/back-office/admin/groups/${groupId}/members/${agentId}`,
    );
    return resp.data;
  }

  async putGroupMemberForMerchant(groupId: string, agentId: string) {
    const resp = await this.client.put(
      `/v1/back-office/admin/merchant/groups/${groupId}/members/${agentId}`,
    );
    return resp.data;
  }

  async deleteGroupMemberForMerchant(groupId: string, agentId: string) {
    const resp = await this.client.delete(
      `/v1/back-office/admin/merchant/groups/${groupId}/members/${agentId}`,
    );
    return resp.data;
  }

  async updateGroupRole({
    add,
    groupId,
    roleId,
  }: {
    add: boolean;
    groupId: string;
    roleId: string;
  }) {
    let resp;
    if (add) {
      resp = await this.client.put(
        `/v1/back-office/admin/groups/${groupId}/roles/${roleId}`,
      );
    } else {
      resp = await this.client.delete(
        `/v1/back-office/admin/groups/${groupId}/roles/${roleId}`,
      );
    }
    return resp.data;
  }

  async getGroupMerchants(
    groupId: string,
  ): Promise<{ merchants: { id: string; name: string }[] }> {
    const resp = await this.client.get(
      `/v1/back-office/admin/groups/${groupId}/merchants`,
    );
    return resp.data;
  }

  async updateGroupMerchant({
    add,
    groupId,
    merchantId,
  }: {
    add: boolean;
    groupId: string;
    merchantId: string;
  }): Promise<{}> {
    let resp;
    if (add) {
      resp = await this.client.put(
        `/v1/back-office/admin/groups/${groupId}/merchants/${merchantId}`,
      );
    } else {
      resp = await this.client.delete(
        `/v1/back-office/admin/groups/${groupId}/merchants/${merchantId}`,
      );
    }
    return resp.data;
  }
}

import { Box, Button, Divider, Paper, TextField, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import {
  getAuth,
  signInWithCustomToken,
  getRedirectResult,
  signInWithPopup,
  signInWithRedirect,
  GoogleAuthProvider,
  OAuthProvider,
  linkWithCredential,
} from 'firebase/auth';
import { AuthCredential } from '@firebase/auth-types';
import { useState } from 'react';
import { ReactComponent as GoogleLogo } from '../assets/icons/google-logo.svg';
import { ReactComponent as MicrosoftLogo } from '../assets/icons/microsoft-logo.svg';
import { ReactComponent as RockerLogo } from '../assets/icons/rocker-logo.svg';
import * as Sentry from "@sentry/react";

const googleProvider = new GoogleAuthProvider();
const microsoftProvider = new OAuthProvider('microsoft.com');

const Wrapper = styled('div')({
  height: '100vh',
  display: 'flex',
  flexDirection: 'row',
  flex: 1,
});

const LoginPage = () => {
  const [token, setToken] = useState<string>('');

  const urlParams = new URLSearchParams(window.location.search);
  const customTokenEnabled = urlParams.has('token');

  const customTokenSignIn = async (e: React.SyntheticEvent) => {
    e.preventDefault();

    try {
      const auth = getAuth();
      signInWithCustomToken(auth, token);
    } catch (e) {
      Sentry.captureException(e);
      console.log(e);
    }
  };

  const handleExistingAccountWithDifferentCredential = async (e: any) => {
    try {
      const credential = OAuthProvider.credentialFromError(e);

      const auth = getAuth();

      await signInWithRedirect(auth, googleProvider);

      const result = await getRedirectResult(auth);

      if (result) {
        const user = result.user;
        await linkWithCredential(user, credential as AuthCredential);
      }
    } catch (e) {
      Sentry.captureException(e);
      console.log(e);
    }
  }

  const externalSignIn = async (provider: any) => {
    try {
      const auth = getAuth();
      await signInWithPopup(auth, provider);
    } catch (error: any) {
      if (error.code === 'auth/account-exists-with-different-credential') {
        await handleExistingAccountWithDifferentCredential(error);
      } else {
        Sentry.captureException(error);
        console.log(error);
      }
    }
  };

  const updateValue = (e: any) => {
    const { value } = e.target;
    setToken(() => value);
  };

  return (
    <Wrapper>
      <Box flex={1} />
      <Box
        display="flex"
        alignItems={'center'}
        style={{
          background: '#38355A',
        }}
        flex={2}
      >
        <Box ml={'80px'}>
          <Box alignItems={'center'} display={'flex'} flexDirection={'row'}>
            <RockerLogo />
            <Typography ml={'28px'} color={'#FFD5AF'} fontSize={54}>
              Pay
            </Typography>
          </Box>
          <Divider
            style={{
              margin: ' 24px 0',
              background: '#FFD5AF',
              width: 64,
              height: 2,
            }}
          ></Divider>
          <Typography component="h2" fontSize={40} color={'#FFD5AF'}>
            Back Office
          </Typography>
          <Typography mt={'46px'} component="h2" variant="h5" color={'#fff'}>
            Logga in med din jobbmail
          </Typography>
          <Button
            fullWidth
            variant="contained"
            color="secondary"
            onClick={() => externalSignIn(googleProvider)}
            style={{ background: '#fff', color: '#2B2654', marginTop: 24 }}
          >
            <GoogleLogo style={{ height: 30, marginRight: 8 }} /> Logga in med
            Google
          </Button>
          <Button
            fullWidth
            variant="contained"
            onClick={() => externalSignIn(microsoftProvider)}
            style={{ marginTop: 16, background: '#fff', color: '#2B2654' }}
          >
            <MicrosoftLogo style={{ height: 30, marginRight: 8 }} />
            Logga in med Microsoft
          </Button>
          {customTokenEnabled && <Paper elevation={0} sx={{ mt: 2, p: 1 }}>
            <form onSubmit={customTokenSignIn}>
              <TextField id="token" label="Token" variant="outlined" onChange={updateValue} fullWidth />
            </form>
          </Paper>}
        </Box>
      </Box>
    </Wrapper>
  );
};

export default LoginPage;

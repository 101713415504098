import { useState } from 'react';
import { Button, Typography } from '@mui/material';
import PageHeader from '../../components/PageHeader';
import GroupList from './components/GroupList';
import CreateGroupModal from './components/CreateGroupModal';

const Groups = () => {
  const [createGroup, setCreateGroup] = useState(false);

  return (
    <>
      <PageHeader>
        <Typography variant="h5">Groups</Typography>
        <Button
          variant="contained"
          size="small"
          onClick={() => setCreateGroup(true)}
        >
          Create new group
        </Button>
      </PageHeader>
      <GroupList />
      {createGroup && <CreateGroupModal close={() => setCreateGroup(false)} />}
    </>
  );
};

export default Groups;

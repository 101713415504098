import { useState, useEffect } from 'react';
import { Payment, PaymentApi } from '../../../api/PaymentApi';
import PaymentGrid from '../../../components/PaymentGrid';
import * as Sentry from "@sentry/react";

export type CustomerPaymentType = 'buyer' | 'seller';

type Props = {
  buyerId?: string;
  sellerId?: string;
  customerPaymentType: CustomerPaymentType;
  filter?: (payment: Payment) => boolean;
};

const CustomerPayments = ({
  buyerId,
  sellerId,
  customerPaymentType,
  filter,
}: Props) => {
  const [payments, setPayments] = useState<Payment[]>([]);
  const [loading, setLoading] = useState(false);
  const paymentApi = new PaymentApi();

  const fetchPayments = async () => {
    try {
      setLoading(true);
      const resp = await paymentApi?.getPaymentList(
        0,
        undefined,
        buyerId,
        sellerId,
      );
      
      const payments = filter ? resp.payments.filter(filter) : resp.payments;

      setPayments(payments);
      setLoading(false);
    } catch (e) {
      Sentry.captureException(e);
      console.log(e);
    }
  };

  useEffect(() => {
    fetchPayments();
  }, [buyerId, sellerId]);

  return (
    <PaymentGrid
      {...{ loading, customerPaymentType }}
      rows={payments}
      hideFooter={true}
      noBorder
    />
  );
};

export default CustomerPayments;

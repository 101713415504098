import { CustomerApi } from '../../../api/CustomerApi';
import AuditLogs from '../../../components/AuditLogs';

const CustomerLogs = ({ customerId }: { customerId: string }) => {
  const customerApi = new CustomerApi();

  return (
    <AuditLogs
      fetchLogs={(page, rowsPerPage) => customerApi.getCustomerLogs(customerId, page, rowsPerPage)}
      rowsPerPage={100}
    />
  );
};

export default CustomerLogs;

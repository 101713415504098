const merchantIds = {
  SWIFTCOURT: 'e4a33d29-6cbb-45e4-a7d4-5c7cf670857e',
  BUSSGODS: '94d37f6c-155d-11ec-82a8-0242ac130003',
  PLICK: '7bdcd95e-2b67-42b9-8f4c-f01098b42ad2',
  BLOCKET: '20e1a379-d036-4ba9-aa14-bec191b2af1f',
  ROCKER: '77257406-6f34-4389-9608-ed540f626a22',
  ROCKER_CAPITAL_GOODS_PORTAL: '15fe8c20-1f71-4bf8-bd4c-9c2a1388c7b3',
  ROCKER_PAY_PORTAL: 'd4023ded-e94b-4ca1-ad88-212814c4a763',
};

export default merchantIds;

import React, { ReactNode, useState } from 'react';
import { Button, ButtonTypeMap, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import Modal from './Modal';

type Action = (target?: any) => void;

type ActionDescriptor = {
  name: string;
  action: Action;
  buttonColor?: ButtonTypeMap['props']['color'];
};

type Props = {
  text: string;
  description?: string | ReactNode;
  actions: Array<ActionDescriptor>;
  close: () => void;
};

export type MultiActionModalProps = Pick<
  Props,
  'text' | 'description' | 'actions'
>;

const MultiActionModal: React.FC<Props> = ({
  children,
  text,
  description,
  actions,
  close,
}) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<boolean>(false);

  const handleAction = async (e: React.SyntheticEvent, action: Action) => {
    e.preventDefault();
    if (!action) return null;
    try {
      setLoading(true);
      if (children) {
        await action(e);
      } else {
        await action();
      }
    } catch (e) {
      setError(true);
    }
    setLoading(false);
  };

  return (
    <Modal cancelButton={false} onClose={close} style={{ width: 700 }}>
      <Typography
        component="h2"
        mb={0}
        sx={{ fontSize: '24px', lineHeight: '36px', fontWeight: '700' }}
      >
        {text}
      </Typography>
      {description && (
        <Typography component="p" mb={0} mt={2}>
          {description}
        </Typography>
      )}
      <div
        style={{ display: 'flex', flexDirection: 'column', marginTop: '16px' }}
      >
        {children}
        <div style={{ marginTop: 24, marginLeft: 'auto' }}>
          <Button
            sx={{ width: 120, mr: 1 }}
            variant="text"
            color="primary"
            onClick={close}
          >
            Cancel
          </Button>
          {actions.map((actionDescriptor, i) => (
            <LoadingButton
              sx={{ minWidth: '150px', marginLeft: '8px' }}
              type="submit"
              variant="contained"
              color={actionDescriptor.buttonColor ?? 'primary'}
              loading={loading}
              onClick={(e) => handleAction(e, actionDescriptor.action)}
              key={i}
            >
              {actionDescriptor.name}
            </LoadingButton>
          ))}
        </div>
        {error && (
          <span style={{ fontSize: 12, marginTop: 8, color: 'red' }}>
            Something went wrong. Try again or contact support.
          </span>
        )}
      </div>
    </Modal>
  );
};

export default MultiActionModal;

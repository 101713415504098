import { useState, useEffect } from 'react';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { Invoice, InvoiceApi } from '../../../api/InvoiceApi';
import formatMoney from '../../../utils/format-money';
import formatDate from '../../../utils/format-date';

type Props = {
  customerId: string;
};

const InvoiceList = (props: Props) => {
  const [invoices, setInvoices] = useState<Invoice[]>([]);
  const invoiceApi = new InvoiceApi();

  const fetchInvoices = async () => {
    const invoices = await invoiceApi.getCustomerInvoices(props.customerId);
    setInvoices(invoices);
  };

  useEffect(() => {
    fetchInvoices();
  }, [props.customerId]);

  return (
    <TableContainer component={Paper}>
      <Table aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>ID</TableCell>
            <TableCell>CV account ID</TableCell>
            <TableCell align="right">Total</TableCell>
            <TableCell align="right">Created at</TableCell>
            <TableCell align="right">PDF</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {invoices.map((invoice) => (
            <TableRow key={invoice.invoiceNr}>
              <TableCell>{invoice.invoiceNr}</TableCell>
              <TableCell>{invoice.accountId}</TableCell>
              <TableCell align="right">
                {formatMoney({ amount: invoice.total * 100, currency: 'SEK', unit: 'MINOR' })}
              </TableCell>
              <TableCell align="right">
                {formatDate(invoice.invoiceDate)}
              </TableCell>
              <TableCell align="right">
                <a
                  href={`https://admin.prod.rockerapi.dev/invoice/pdf/get/${invoice.invoiceNr}`}
                >
                  Invoice PDF
                </a>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default InvoiceList;

import { useQueryClient, useMutation } from 'react-query';
import { Button, TextField, Typography } from '@mui/material';
import Modal from '../../../components/Modal';
import { GroupApi } from '../../../api/GroupApi';
import { useNotificationContext } from '../../../context/NotificationContext';
import {LoadingButton} from "@mui/lab";
import {useState} from "react";

const CreateGroupModal = ({ close }: { close: () => void }) => {
  const groupApi = new GroupApi();
  const queryClient = useQueryClient();
  const { setNotification } = useNotificationContext();

  const mutation = useMutation((name: string) => groupApi.createGroup(name), {
    onError: () =>
      setNotification({
        severity: 'error',
        message: 'Something went wrong. please try again or contact support',
      }),
    onSettled: () => {
      queryClient.invalidateQueries('groups');
      close();
    },
  });

  const submit = async (e: React.SyntheticEvent) => {
    e.preventDefault();
    const target = e.target as typeof e.target & {
      name: { value: string };
    };
    if (!target.name.value) return;

    mutation.mutate(target.name.value);
  };

  return (
    <Modal onClose={close}>
      <Typography component="h2" fontSize={23}>
        Create group:
      </Typography>
      <form onSubmit={submit}>
        <TextField label="Name" name="name" fullWidth margin="normal" />
        <LoadingButton variant="contained" type="submit" loading={mutation.isLoading} fullWidth sx={{ mt: 2 }}>
          Create
        </LoadingButton>
      </form>
    </Modal>
  );
};

export default CreateGroupModal;

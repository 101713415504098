import {
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormGroup,
} from '@mui/material';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import { MerchantApi } from '../../../api/MerchantApi';
import Modal from '../../../components/Modal';
import { useNotificationContext } from '../../../context/NotificationContext';

const ConfigRow = styled('div')`
  display: flex;
`;

const NotificationConfigModal = ({ close }: { close: () => void }) => {
  const merchantApi = new MerchantApi();
  const queryClient = useQueryClient();
  const { merchantId } = useParams<{ merchantId: string }>();
  const { setNotification } = useNotificationContext();

  const { isLoading, data } = useQuery(['notificationConfig', merchantId], () =>
    merchantApi.getNotificationConfig(merchantId),
  );

  const mutation = useMutation(
    ({
      notification,
      payload,
    }: {
      notification: string;
      payload: { [key: string]: boolean };
    }) =>
      merchantApi.updateNotificationConfig({
        merchantId,
        notification,
        payload,
      }),
    {
      onError: () => {
        setNotification({
          severity: 'error',
          message: 'Something went wrong. please try again or contact support',
        });
      },
      onSuccess: (data) => {
        queryClient.setQueryData(['notificationConfig', merchantId], data);
      },
    },
  );

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!data) return;
    const { checked, name } = event.target;
    const [notification, media] = name.split('*');
    const configToUpdate = data.configs.find(
      (config) => config.notification === notification,
    );

    if (!configToUpdate) return;
    const { sms, email } = configToUpdate;
    const payload = { sms, email, [media]: checked };
    mutation.mutate({ notification, payload });
  };

  return (
    <Modal onClose={close}>
      <form>
        {isLoading ? (
          <CircularProgress />
        ) : (
          data?.configs.map(({ notification, email, sms }) => (
            <ConfigRow key={notification}>
              <FormControl>
                <FormGroup row>
                  <p style={{ fontSize: 12, width: '240px' }}>{notification}</p>
                  <FormControlLabel
                    key={`${notification}*sms`}
                    name={`${notification}*sms`}
                    label={'sms'}
                    control={<Checkbox checked={sms} onChange={handleChange} />}
                  />
                  <FormControlLabel
                    key={`${notification}*email`}
                    name={`${notification}*email`}
                    label={'email'}
                    control={
                      <Checkbox checked={email} onChange={handleChange} />
                    }
                  />
                </FormGroup>
              </FormControl>
            </ConfigRow>
          ))
        )}
      </form>
    </Modal>
  );
};

export default NotificationConfigModal;

import { Close, CommentOutlined } from '@mui/icons-material';
import {
  Badge,
  Button,
  CircularProgress,
  Divider,
  List,
  ListItem,
  ListItemText,
  TextField,
  Typography,
} from '@mui/material';
import { styled } from '@mui/styles';
import { Box } from '@mui/system';
import React, { FC, useEffect, useState } from 'react';
import { Comment, OfferApi } from '../../../api/OfferApi';
import { useNotificationContext } from '../../../context/NotificationContext';
import formatDate from '../../../utils/format-date';
import {LoadingButton} from "@mui/lab";

type OfferCommentsProps = {
  offerId: string;
};

const OfferComments: FC<OfferCommentsProps> = ({ offerId }) => {
  const [isCommentsOpen, toggleComments] = useState(false);
  const [comments, setComments] = useState<Array<Comment>>([]);
  const [loadingGet, setLoadingGet] = useState(false);
  const [loadingPost, setLoadingPost] = useState(false);
  const { setNotification } = useNotificationContext();
  const offerApi = new OfferApi();

  const fetchComments = async (offerId: string) => {
    try {
      setLoadingGet(true);
      const resp = await offerApi.getOfferComments(offerId);
      setComments(resp.comments);
    } catch (e) {}
    setLoadingGet(false);
  };

  const postComment = async (e: React.SyntheticEvent) => {
    try {
      e.preventDefault();
      const target = e.target as typeof e.target & {
        comment: { value: string };
        reset: () => void;
      };
      if (!target.comment.value) return;

      setLoadingPost(true);
      const addedComment = await offerApi.postOfferComment(
        offerId,
        target.comment.value,
      );
      target.reset();
      setComments([...comments, addedComment]);
    } catch (e) {
      setNotification({
        severity: 'error',
        message: `Failed to post a comment`,
      });
    }
    setLoadingPost(false);
  };

  useEffect(() => {
    fetchComments(offerId);
  }, [offerId]);

  return isCommentsOpen ? (
    <CommentsOpenContainer>
      <Box
        mb={2}
        display="flex"
        alignItems="center"
        justifyContent="space-between"
      >
        <Typography variant="h2" fontSize={19}>
          Agent Comments
        </Typography>

        <Close cursor="pointer" onClick={() => toggleComments(false)} />
      </Box>
      <form onSubmit={postComment}>
        <TextField
          id="comment-text"
          name="comment"
          fullWidth
          multiline
          rows={3}
          placeholder="Add a comment..."
          variant="outlined"
          disabled={loadingPost}
        />
        <LoadingButton
          style={{ marginTop: 10 }}
          fullWidth
          variant="contained"
          color="primary"
          type="submit"
          loading={loadingPost}
        >
          Post
        </LoadingButton>
      </form>
      <List
        style={{
          display: 'flex',
          flexDirection: 'column',
          maxHeight: 300,
          overflowY: 'scroll',
        }}
      >
        {loadingGet ? (
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <CircularProgress size={24} />
          </div>
        ) : (
          comments
            .map(({ text, createdAt, author }, i) => (
              <div key={`${author} - ${i}`}>
                {i !== 0 && <Divider />}
                <ListItem>
                  <ListItemText
                    primary={text}
                    secondary={
                      <>
                        {formatDate(createdAt)}
                        {author?.email && (
                          <>
                            <br />
                            {author.email}
                          </>
                        )}
                      </>
                    }
                  />
                </ListItem>
              </div>
            ))
            .reverse()
        )}
      </List>
    </CommentsOpenContainer>
  ) : (
    <CommentsClosedContainer onClick={() => toggleComments(true)}>
      <Box
        display="flex"
        alignItems="center"
        style={{
          transform: 'rotate(90deg)',
        }}
      >
        <Badge badgeContent={comments.length} color="error" style={{
              transform: 'rotate(-90deg)',
            }}>
          <CommentOutlined color="primary" />
        </Badge>
        <Typography ml={1} variant="h2" fontSize={14}>
          Agent&nbsp;Comments
        </Typography>
      </Box>
    </CommentsClosedContainer>
  );
};

export default OfferComments;

const CommentsClosedContainer = styled('div')({
  cursor: 'pointer',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  background: '#FFFFFF',
  borderRadius: '16px 0px 0px 16px',
  boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.24)',
  position: 'fixed',
  zIndex: 1,
  top: '176px',
  right: '0px',
  width: '48px',
  height: '188px',
});

const CommentsOpenContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  padding: '16px',
  position: 'fixed',
  zIndex: '1',
  width: '448px',

  right: '0px',
  top: '176px',
  background: '#FFFFFF',
  boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.24)',
  borderRadius: '16px 0px 0px 16px',
});

import { Link } from 'react-router-dom';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import {
  TableContainer,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Card,
  Skeleton,
  Button,
} from '@mui/material';
import { GroupApi } from '../../../api/GroupApi';
import formatDate from '../../../utils/format-date';
import { useNotificationContext } from '../../../context/NotificationContext';
import ActionConfirmationModal, {
  ActionModalProps,
} from '../../../components/ActionConfirmationModal';
import { useState } from 'react';

const GroupList = () => {
  const [actionModal, setActionModal] = useState<ActionModalProps>({
    text: '',
  });
  const groupApi = new GroupApi();
  const queryClient = useQueryClient();
  const { setNotification } = useNotificationContext();

  const { isLoading, data } = useQuery(['groups'], () => groupApi.getGroups());

  const mutation = useMutation((id: string) => groupApi.deleteGroup(id), {
    onError: () =>
      setNotification({
        severity: 'error',
        message: 'Something went wrong. please try again or contact support',
      }),
    onSettled: () => {
      queryClient.invalidateQueries('groups');
      setActionModal({ text: '' });
    },
  });

  return (
    <>
      <Card style={{ marginTop: 25 }}>
        {isLoading ? (
          <div style={{ padding: 20 }}>
            <Skeleton />
            <Skeleton />
            <Skeleton />
            <Skeleton />
          </div>
        ) : (
          <TableContainer>
            <Table stickyHeader aria-label="users">
              <TableHead>
                <TableRow>
                  <TableCell variant="head">Name</TableCell>
                  <TableCell variant="head">Created At</TableCell>
                  <TableCell variant="head" />
                </TableRow>
              </TableHead>
              <TableBody>
                {data?.map((group) => (
                  <TableRow key={group.id}>
                    <TableCell>
                      <Link to={`/groups/${group.id}`}>{group.name}</Link>
                    </TableCell>
                    <TableCell>{formatDate(group.created_at)}</TableCell>
                    <TableCell>
                      <Button
                        variant="contained"
                        color="error"
                        size="small"
                        onClick={() =>
                          setActionModal({
                            text: 'Delete group',
                            action: () => mutation.mutate(group.id),
                          })
                        }
                      >
                        X
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </Card>
      {actionModal.text && (
        <ActionConfirmationModal
          {...actionModal}
          close={() => setActionModal({ text: '' })}
        />
      )}
    </>
  );
};

export default GroupList;

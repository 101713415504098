import { PackageInfo, RichPaymentShippingResponse, RichShippingResponse, ShippingAddress, ShippingParty, ShippingProvider, ShippingType } from '../../../api/ShippingApi';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { Tooltip, Typography } from '@mui/material';
import formatDate from '../../../utils/format-date';
import formatMoney from '../../../utils/format-money';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import ReplyIcon from '@mui/icons-material/Reply';
import { styled } from '@mui/material/styles';


const StyledReplyIcon = styled(ReplyIcon)({
  position: 'absolute',
  fontSize: '15px',
  color: '#ffffff',
  right: '1px',
  top: '3px',
});

const columns: Array<GridColDef> = [
  {
    field: 'provider',
    headerName: 'Provider',
    width: 90,
    sortable: false,
    disableColumnMenu: true,
    renderCell: ({ row }) => providerName(row.shippingId.$type),
  },
  {
    field: 'shippingId',
    headerName: 'Shipping ID',
    width: 150,
    sortable: false,
    disableColumnMenu: true,
    renderCell: ({ row }) => (
      <Tooltip title={row.shippingId.id}>
        <a href={row.trackingUri} target="_blank">{row.shippingId.id}</a>
      </Tooltip>
    ),
  },
  {
    field: 'type',
    headerName: 'Type',
    width: 60,
    sortable: false,
    disableColumnMenu: true,
    renderCell: ({ row }) =>
      <Tooltip title={(row.isReturn) ? 'Return' : 'Original'}>
        <div style={{ position: 'relative', width: 'fit-content' }}>
          <LocalShippingIcon sx={{ transform: `scaleX(${(row.isReturn) ? '-1' : '1'})` }} />
          {row.isReturn && <StyledReplyIcon />}
        </div>
      </Tooltip>
  },
  {
    field: 'sender',
    headerName: 'Sender',
    width: 160,
    sortable: false,
    disableColumnMenu: true,
    renderCell: ({ row }) => (
      <Tooltip title={partyInfo(row.shipper)}>
        <p>{row.shipper.name}</p>
      </Tooltip>
    ),
  },
  {
    field: 'receiver',
    headerName: 'Receiver',
    width: 160,
    sortable: false,
    disableColumnMenu: true,
    renderCell: ({ row }) => (
      <Tooltip title={partyInfo(row.receiver)}>
        <p>{row.receiver.name}</p>
      </Tooltip>
    ),
  },
  {
    field: 'destination',
    headerName: 'Destination',
    width: 170,
    sortable: false,
    disableColumnMenu: true,
    renderCell: ({ row }) => (
      <Tooltip title={destination(row.shippingType)}>
        <p>{destinationName(row.shippingType)}</p>
      </Tooltip>
    ),
  },
  {
    field: 'pickedUp',
    headerName: 'Picked up',
    width: 150,
    sortable: false,
    disableColumnMenu: true,
    renderCell: ({ row }) => formatDate(row.deliveredAt),
  },
  {
    field: 'weight',
    headerName: 'Package',
    width: 100,
    sortable: false,
    disableColumnMenu: true,
    renderCell: (p) => packageInfo(p.row.packagesInfo[0])
    ,
  },
  {
    field: 'shippingFee',
    headerName: 'Fee',
    width: 100,
    sortable: false,
    disableColumnMenu: true,
    renderCell: (p) => formatMoney(p.row.shippingFeeInclVat),
  },
  {
    field: 'createdAt',
    headerName: 'CreatedAt',
    width: 150,
    sortable: false,
    disableColumnMenu: true,
    renderCell: (p) => formatDate(p.row.createdAt),
  },

];

const providerName = (x: ShippingProvider) => {
  return (x == 'DhlShippingId') ? 'DHL' : 'UNKNOWN';
};

const partyInfo = (x: ShippingParty) => {
  return <p>Name: {x.name}<br />Email: {x.email}<br />Phone: {x.phone}<br />Address: {address(x.address)}</p>;
};

const packageInfo = (x: PackageInfo) => {
  var unit = (x.weight.unit == 'KILOGRAM') ? 'kg' : 'g';
  return `${x.weight.value} ${unit}`
};

const destinationName = (x: ShippingType) => {
  if (x.$type == 'ServicePoint') {
    return x.name;
  } else {
    return x.$type
  }
};

const destination = (x: ShippingType) => {
  if (x.$type == 'ServicePoint') {
    return <p>Name: {x.name}<br />Address: {address(x.address)}</p>;
  } else {
    return x.$type
  }
};

const address = (x: ShippingAddress) => {
  return <span>{x.streetAddress}, {x.postalCode} {x.city}</span>;
};



type Props = {
  offerId?: string;
  shippings: RichPaymentShippingResponse[];
  loading: boolean;
};

const OfferShipping = (props: Props) => {

  const shippings = props.shippings.flatMap((paymentShipping) => {
    if (paymentShipping.returnShipping) {
      return [{ ...paymentShipping.shipping, isReturn: false }, { ...paymentShipping.returnShipping, isReturn: true }];
    } else {
      return [{ ...paymentShipping.shipping, isReturn: false }];
    }
  }).map((original) => {
    const { id, ...rest } = original; // Extract 'id' and other properties

    return {
      ...rest, // Copy other properties
      shippingId: id, // Rename 'id' to 'shippingId'
      id: id.id // Add new property 'Id'
    };
  });

  return (
    <DataGrid
      loading={props.loading}
      rows={shippings}
      hideFooter={true}
      initialState={{
        sorting: {
          sortModel: [{ field: 'createdAt', sort: 'desc' }],
        }
      }}
      style={{
        width: '100%',
        marginTop: 0,
        background: '#fff',
        border: '',
      }}
      autoHeight={true}
      columns={columns}
      disableSelectionOnClick={true} />
  );
};

export default OfferShipping;

import React, { ReactNode, useState } from 'react';
import { Button, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import Modal from './Modal';

type Props = {
  text: string;
  description?: string | ReactNode;
  actionDisabled?: boolean;
  action?: (target?: any) => void;
  close: () => void;
};

export type ActionModalProps = Pick<Props, 'text' | 'description' | 'action'>;

const ActionConfirmationModal: React.FC<Props> = ({
  children,
  text,
  description,
  actionDisabled = false,
  action,
  close,
}) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<boolean>(false);

  const handleAction = async (e: React.SyntheticEvent) => {
    e.preventDefault();
    if (!action) return null;
    try {
      setLoading(true);
      if (children) {
        await action(e);
      } else {
        await action();
      }
    } catch (e) {
      setError(true);
    }
    setLoading(false);
  };

  return (
    <Modal cancelButton={false} onClose={close}>
      <Typography
        component="h2"
        mb={0}
        sx={{ fontSize: '24px', lineHeight: '36px', fontWeight: '700' }}
      >
        {text}
      </Typography>
      {description && (
        <Typography component="p" mb={0} mt={2}>
          {description}
        </Typography>
      )}
      <form
        onSubmit={handleAction}
        style={{ display: 'flex', flexDirection: 'column', marginTop: '16px' }}
      >
        {children}
        <div style={{ marginTop: 24, marginLeft: 'auto' }}>
          <Button
            sx={{ width: 120, mr: 1 }}
            variant="text"
            color="primary"
            onClick={close}
          >
            Cancel
          </Button>
          <LoadingButton
            sx={{ width: 150 }}
            type="submit"
            variant="contained"
            color="primary"
            loading={loading}
            disabled={loading || actionDisabled}
          >
            Confirm
          </LoadingButton>
        </div>
        {error && (
          <span style={{ fontSize: 12, marginTop: 8, color: 'red' }}>
            Something went wrong. Try again or contact support.
          </span>
        )}
      </form>
    </Modal>
  );
};

export default ActionConfirmationModal;

import { IconButton } from '@mui/material';
import {
  NavigateNext as NavigateNextIcon,
  NavigateBefore as NavigateBeforeIcon,
} from '@mui/icons-material';

type Props = {
  loading: boolean;
  page: number;
  rowsCount: number;
  existsMore: boolean;
  decrease: () => void;
  increase: () => void;
};

const PaginationController = ({
  loading,
  page,
  existsMore,
  decrease,
  increase,
}: Props) => (
  <div style={{ padding: 10 }}>
    <IconButton disabled={page < 1} onClick={decrease} size="large">
      <NavigateBeforeIcon />
    </IconButton>
    <span>{page + 1}</span>
    <IconButton
      disabled={!existsMore || loading}
      onClick={increase}
      size="large"
    >
      <NavigateNextIcon />
    </IconButton>
  </div>
);

export default PaginationController;

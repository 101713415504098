import {
  Article as ArticleIcon,
  Code as CodeIcon,
  Groups as GroupsIcon,
  People as PeopleIcon,
  Settings as SettingsIcon,
  // Dashboard as DashboardIcon,
  ShoppingCart as ShoppingCartIcon,
} from '@mui/icons-material';
import { Drawer } from '@rocker-ui/web';
import React from 'react';
import { useHistory } from 'react-router';
import { useAuthContext } from '../context/AuthContext';

type ListItem = {
  title: string;
  icon: React.ReactNode;
  path: string;
  exactPathMatch?: boolean;
};

const AppDrawer = () => {
  const history = useHistory();
  const {
    user: { isSuperAdmin, isMerchantAdmin },
  } = useAuthContext();

  const mergedLists = isSuperAdmin
    ? [...DRAWER_LIST_ITEMS, ...SUPERADMIN_DRAWER_LIST_ITEMS]
    : (isMerchantAdmin ? [...DRAWER_LIST_ITEMS, ...MERCHANTADMIN_DRAWER_LIST_ITEMS]
      : DRAWER_LIST_ITEMS);

  const listOptions = mergedLists.map((o) => ({
    ...o,
    onClick: () => history.push(o.path),
    selected: o?.exactPathMatch
      ? history.location.pathname === o.path
      : history.location.pathname.startsWith(o.path),
  }));

  return <Drawer {...{ listOptions }} />;
};

export default AppDrawer;

const DRAWER_LIST_ITEMS: ListItem[] = [
  // {
  //   title: 'Dashboard',
  //   icon: <DashboardIcon />,
  //   path: '/',
  //   exactPathMatch: true,
  // },
  {
    title: 'Offers',
    icon: <ShoppingCartIcon />,
    path: '/offers',
  },
  {
    title: 'Customers',
    icon: <PeopleIcon />,
    path: '/customers',
  },
  {
    title: 'Docs',
    icon: <ArticleIcon />,
    path: '/api-docs/guide',
  },
  {
    title: 'Frontend API',
    icon: <CodeIcon />,
    path: '/api-docs/frontend',
  },
  {
    title: 'Merchant API',
    icon: <CodeIcon />,
    path: '/api-docs/merchant',
  },
];

const SUPERADMIN_DRAWER_LIST_ITEMS: ListItem[] = [
  {
    title: 'Backoffice API',
    icon: <CodeIcon />,
    path: '/api-docs/backoffice',
  },
  {
    title: 'Agents',
    icon: <PeopleIcon />,
    path: '/agents',
  },
  {
    title: 'Groups',
    icon: <GroupsIcon />,
    path: '/groups',
  },
  {
    title: 'Merchants',
    icon: <SettingsIcon />,
    path: '/merchants',
  },
];

const MERCHANTADMIN_DRAWER_LIST_ITEMS: ListItem[] = [
  {
    title: 'Agents',
    icon: <PeopleIcon />,
    path: '/agents',
  },
  {
    title: 'Merchants',
    icon: <SettingsIcon />,
    path: '/merchants',
  }
];

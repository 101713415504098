import {
  Block,
  Cancel,
  Check,
  ChevronRight,
  CurrencyExchange,
  DoubleArrow,
  Lock,
  LockOpen,
  Pause,
  PlayArrow,
  Send,
  Warning,
} from '@mui/icons-material';
import {
  Button,
  Menu,
  TextField,
  Tooltip,
  ListItemIcon,
  ListItemText,
  MenuItem,
  MenuList,
  FormControlLabel,
  Checkbox,
} from '@mui/material';
import Typography from '@mui/material/Typography';
import formatMoney from '../../../utils/format-money';
import * as React from 'react';
import { useState } from 'react';
import { useQuery } from 'react-query';
import { PaymentApi } from '../../../api/PaymentApi';
import ActionConfirmationModal, {
  ActionModalProps,
} from '../../../components/ActionConfirmationModal';
import { useNotificationContext } from '../../../context/NotificationContext';
import type { PaymentWithPayouts } from '../../offer/components/OfferTabs';
import { useOfferContext } from '../../offer/Offer';
import ForcePayoutModal from '../../../components/ForcePayoutModal';
import { useAuthContext } from '../../../context/AuthContext';
import feeNames from '../../../constants/feeNames';
import { MoneyMinor } from '../../../api/shared';
import MultiActionModal, {
  MultiActionModalProps,
} from '../../../components/MultiActionModal';

type Props = {
  payment: PaymentWithPayouts;
};

/**
 * These ModalProps should probably be called RefundModalConfirmationProps,
 * and .textField should probably be called "showCommentInputField", as that
 * is its only use case.
 */
type ModalProps = ActionModalProps & { textField?: boolean };

type SelectiveRefundModalProps = MultiActionModalProps & {
  feesToRefund: Set<string>;
};

const PaymentActions = ({ payment }: Props) => {
  const { offer } = useOfferContext();

  const [componentPayment, setComponentPayment] =
    useState<PaymentWithPayouts>(payment);
  // This is basically a yes/no confirmation modal.
  const [actionModal, setActionModal] = useState<ModalProps>({
    text: '',
  });
  // This is a more advanced modal for user input regarding refunds
  const [selectiveRefundModal, setSelectiveRefundModal] =
    useState<SelectiveRefundModalProps>({
      text: '',
      feesToRefund: new Set<string>(),
      actions: [],
    });
  const [forcePayoutModal, setForcePayoutModal] = useState(false);
  const authContext = useAuthContext();
  const { setNotification } = useNotificationContext();
  const paymentApi = new PaymentApi();

  const paymentRefundDisabled =
    (componentPayment?.status !== 'SETTLED' &&
      componentPayment.payouts?.length === 0) ||
    componentPayment?.payoutConsent === 'CONFIRMED';

  const forcePayoutDisabled =
    componentPayment?.status !== 'SETTLED' ||
    componentPayment?.payoutConsent === 'DECLINED' ||
    componentPayment.payouts?.some((payout) => payout.status === 'COMPLETED');

  const pausePayment = async (e: React.SyntheticEvent) => {
    const target = e.target as typeof e.target & {
      comment?: { value: string };
      reset: () => void;
    };
    if (!target.comment?.value) return;
    const newState =
      componentPayment.pauseState !== 'NOT_PAUSED' ? 'NOT_PAUSED' : 'PAUSED';
    try {
      await paymentApi.updatePaymentPausedState(
        payment.id,
        newState,
        target.comment.value,
      );
      setComponentPayment((prev) => ({ ...prev, pauseState: newState }));
      setNotification({
        severity: 'success',
        message: 'Payment pause state updated',
      });
    } catch (e) {
      setNotification({
        severity: 'error',
        message: `Failed to change payment pause state`,
      });
    }
    setActionModal({ text: '' });
  };

  const blockPayout = async () => {
    try {
      // TODO make block return payment and set that instead of calling get payment
      const newState = payment.payoutConsent !== 'BLOCKED';
      await paymentApi.blockPayout(payment.id, newState);
      const newPayment = await paymentApi.getPayment(payment.id);
      setComponentPayment((prev) => ({ ...prev, ...newPayment }));
      setNotification({
        severity: 'success',
        message: 'Payout block updated',
      });
    } catch (e) {
      setNotification({
        severity: 'error',
        message: `Failed to change payout block state`,
      });
    }
    setActionModal({ text: '' });
  };

  const flagAsSucceeded = async () => {
    try {
      await paymentApi.flagAsSucceeded(payment.id);
      const newPayment = await paymentApi.getPayment(payment.id);
      setComponentPayment((prev) => ({ ...prev, ...newPayment }));
      setNotification({
        severity: 'success',
        message: 'Payment flagged as succeeded',
      });
    } catch (e) {
      setNotification({
        severity: 'error',
        message: `Failed to flag payment as succeeded`,
      });
    }
    setActionModal({ text: '' });
  };

  const flagAsFailed = async () => {
    try {
      await paymentApi.flagAsFailed(payment.id);
      const newPayment = await paymentApi.getPayment(payment.id);
      setComponentPayment((prev) => ({ ...prev, ...newPayment }));
      setNotification({
        severity: 'success',
        message: 'Payment flagged as failed',
      });
    } catch (e) {
      setNotification({
        severity: 'error',
        message: `Failed to flag payment as failed`,
      });
    }
    setActionModal({ text: '' });
  };

  const { data: refundData } = useQuery(['refunds', payment.id], () =>
    paymentApi.getRefunds(payment.id),
  );

  const refundPayment = async (forceFull: boolean) => {
    try {
      const newPayment = await paymentApi.refundPayment(payment.id, forceFull);

      setComponentPayment((prev) => ({ ...prev, ...newPayment }));
      setNotification({
        severity: 'success',
        message: 'Payment refunded',
      });
    } catch (error) {
      setNotification({
        severity: 'error',
        // @ts-ignore
        message: `Failed to refund payment:  ${error.message}`,
      });
    }
    setActionModal({ text: '' });
  };

  const selectiveRefundPayment = async (
    feeTypesToRefund: Set<string>,
    flagAsExternallyRefunded: boolean = false,
  ) => {
    try {
      const newPayment = await paymentApi.selectiveRefundPayment(
        payment.id,
        feeTypesToRefund,
        flagAsExternallyRefunded,
      );

      setComponentPayment((prev) => ({ ...prev, ...newPayment }));
      setNotification({
        severity: 'success',
        message: 'Payment refunded',
      });
    } catch (error) {
      setNotification({
        severity: 'error',
        // @ts-ignore
        message: `Failed to refund payment:  ${error.message}`,
      });
    }
    setSelectiveRefundModal({ ...selectiveRefundModal, text: '', actions: [] });
  };

  const cancelPayment = async () => {
    try {
      await paymentApi.cancelPayment(payment.id);
      const newPayment = await paymentApi.getPayment(payment.id);
      setComponentPayment((prev) => ({ ...prev, ...newPayment }));
      setNotification({
        severity: 'success',
        message: 'Payment Cancelled',
      });
    } catch (error) {
      setNotification({
        severity: 'error',
        // @ts-ignore
        message: `Failed to cancel payment:  ${error.message}`,
      });
    }
    setActionModal({ text: '' });
  };

  const resendReceipt = async () => {
    try {
      await paymentApi.resendBuyerReceipt(payment.id);
      setNotification({
        severity: 'success',
        message: 'Receipt resent',
      });
    } catch (e) {
      setNotification({
        severity: 'error',
        message: 'Failed to resend receipt',
      });
    }
    setActionModal({ text: '' });
  };

  const grantPayoutConsent = async () => {
    try {
      const newPayment = await paymentApi.grantPayoutConsent(payment.id);
      setComponentPayment((prev) => ({ ...prev, ...newPayment }));
      setNotification({
        severity: 'success',
        message: 'Payout consent granted',
      });
    } catch (e) {
      setNotification({
        severity: 'error',
        message: 'Failed to grant payout consent',
      });
    }
    setActionModal({ text: '' });
  };

  const revertPayoutConsent = async () => {
    try {
      const newPayment = await paymentApi.revertPayoutConsent(payment.id);
      setComponentPayment((prev) => ({ ...prev, ...newPayment }));
      setNotification({
        severity: 'success',
        message: 'Payout consent reverted',
      });
    } catch (e) {
      setNotification({
        severity: 'error',
        message: 'Failed to revert payout consent',
      });
    }
    setActionModal({ text: '' });
  };

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const openRefundModal = (forceFull: boolean) =>
    setActionModal({
      text: forceFull ? 'Refund with fees' : 'Refund without fees',
      description: forceFull ? (
        <>
          {' '}
          You will refund the whole payment made by the buyer <b>
            INCLUDING
          </b>{' '}
          fees. Amount to be refunded:{' '}
          {!refundData ? '...' : formatMoney(payment.amount)}.
        </>
      ) : (
        <>
          You will refund the payment made by the buyer <b>EXCLUDING</b> fees.
          Amount to be refunded:{' '}
          {!refundData
            ? '...'
            : formatMoney({
                ...payment.amount,
                amount:
                  payment.amount.amount -
                  (offer?.serviceFee?.actual?.amount ?? 0),
              })}
        </>
      ),
      action: () => refundPayment(forceFull),
    });

  const renderSelectiveRefundModal = (feesToRefund: Set<string>) =>
    setSelectiveRefundModal({
      ...selectiveRefundModal,
      text: 'Refund incl selected fees',
      description: (
        <>
          {' '}
          You will refund the whole payment made by the buyer <b>
            INCLUDING
          </b>{' '}
          the fees you select below. Amount to be refunded will be between{' '}
          {formatMoney({
            ...payment.amount,
            amount:
              payment.amount.amount - (offer?.serviceFee?.actual?.amount ?? 0),
          })}{' '}
          and {formatMoney(payment.amount)}{' '}
        </>
      ),
      feesToRefund,
      actions: authContext.can('FlagAsExternallyRefunded')
        ? [
            {
              name: 'Refund',
              action: () => selectiveRefundPayment(feesToRefund),
            },
            {
              name: 'Flag as externally refunded',
              action: () => selectiveRefundPayment(feesToRefund, true),
              buttonColor: 'warning',
            },
          ]
        : [
            {
              name: 'Refund',
              action: () => selectiveRefundPayment(feesToRefund),
            },
          ],
    });

  const selectiveRefundCheckAllFeesHandler = (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
  ) => {
    e.preventDefault();
    renderSelectiveRefundModal(new Set(offerFeeTypes()));
  };

  const selectiveRefundCheckNoFeesHandler = (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
  ) => {
    e.preventDefault();
    renderSelectiveRefundModal(new Set([]));
  };

  function offerFeeTypes(): Array<string> {
    if (offer?.serviceFeeItems) {
      return Object.keys(offer.serviceFeeItems);
    } else if (offer?.serviceFee && offer.serviceFee.actual.amount > 0) {
      return ['ESCROW_FEE'];
    } else {
      return [];
    }
  }

  function zeroMoney(): MoneyMinor {
    return {
      amount: 0,
      currency: offer?.price?.currency || 'SEK',
      unit: 'MINOR'
    };
  }

  function totalSelectiveRefundAmount(): MoneyMinor {
    const fullRefundAmount: MoneyMinor = payment.amount;
    const feesNotSelectedForRefund: string[] = offerFeeTypes().filter(
      (c) => !selectiveRefundModal.feesToRefund.has(c),
    );

    const amountNotSelectedForRefund: MoneyMinor = feesNotSelectedForRefund
      .map(feeAmount)
      .reduce(
        (a, b, _, __) => ({
          amount: a.amount + b.amount,
          currency: a.currency,
          unit: 'MINOR'
        }),
        zeroMoney(),
      );

    return {
      amount: fullRefundAmount.amount - amountNotSelectedForRefund.amount,
      currency: fullRefundAmount.currency,
      unit: 'MINOR'
    };
  }

  function feeAmount(feeType: string): MoneyMinor {
    if (offer?.serviceFeeItems) {
      let item = offer.serviceFeeItems[feeType];

      if (!!item) {
        return item;
      }
    } else if (feeType == 'ESCROW_FEE' && offer?.serviceFee) {
      return offer.serviceFee.actual;
    }

    return {
      amount: 0,
      currency: 'SEK',
      unit: 'MINOR'
    };
  }

  return (
    <>
      <Button
        id="action-list-button"
        onClick={handleClick}
        focusRipple={false}
        variant="outlined"
        style={{
          padding: '5px 10px',
          margin: 0,
          fontSize: 12,
        }}
      >
        Show actions
        <ChevronRight
          style={{
            transform: 'rotate(90deg)',
          }}
        />
      </Button>
      <Menu
        id="action-list-menu"
        aria-labelledby="action-list-button"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <MenuList>
          <Tooltip
            arrow
            placement="top"
            title={`Automatic payment release paused: ${
              payment.pauseState === 'NOT_PAUSED' ? 'No' : 'Yes'
            }`}
          >
            <MenuItem
              disabled={componentPayment.payoutConsent !== 'UNDEFINED'}
              color={
                componentPayment.pauseState === 'PAUSED' ? 'success' : 'error'
              }
              onClick={() =>
                setActionModal({
                  text: `${
                    componentPayment.pauseState === 'PAUSED'
                      ? 'Unpause'
                      : 'Pause'
                  } payment`,
                  description:
                    'If a payment is paused, it will not automatically be released from escrow.',
                  textField: true,
                  action: pausePayment,
                })
              }
            >
              <ListItemIcon>
                {componentPayment.pauseState === 'PAUSED' ? (
                  <PlayArrow />
                ) : (
                  <Pause />
                )}
              </ListItemIcon>
              <ListItemText>
                {`${
                  componentPayment.pauseState === 'PAUSED' ? 'Unpause' : 'Pause'
                }
            `}
              </ListItemText>
            </MenuItem>
          </Tooltip>
          <Tooltip
            placement="top"
            title={`${
              componentPayment.payoutConsent === 'BLOCKED' ? 'Unblock' : 'Block'
            }: -- Payout blocked: ${
              payment.payoutConsent === 'BLOCKED' ? 'Yes' : 'No'
            }`}
            arrow
          >
            <MenuItem
              disabled={['CONFIRMED', 'DECLINED'].includes(
                componentPayment.payoutConsent,
              )}
              color={
                componentPayment.payoutConsent === 'BLOCKED'
                  ? 'success'
                  : 'error'
              }
              onClick={() =>
                setActionModal({
                  text: `${
                    componentPayment.payoutConsent === 'BLOCKED'
                      ? 'Unblock'
                      : 'Block'
                  } payout`,
                  action: blockPayout,
                })
              }
            >
              <ListItemIcon>
                {componentPayment.payoutConsent === 'BLOCKED' ? (
                  <LockOpen />
                ) : (
                  <Lock />
                )}
              </ListItemIcon>
              <ListItemText>
                {componentPayment.payoutConsent === 'BLOCKED'
                  ? 'Unblock'
                  : 'Block'}
              </ListItemText>
              <Typography variant="body2" color="text.secondary"></Typography>
            </MenuItem>
          </Tooltip>
          {authContext.can('ForceSucceedPayment') && (
            <MenuItem
              disabled={componentPayment.status !== 'INIT'}
              onClick={() =>
                setActionModal({
                  text: 'Force flag as completed',
                  description: 'Are you sure this is correct?',
                  action: flagAsSucceeded,
                })
              }
            >
              <ListItemIcon>
                <Warning />
              </ListItemIcon>
              <ListItemText>Force flag as succeeded</ListItemText>
            </MenuItem>
          )}
          {authContext.can('ForceFailPayment') && (
            <MenuItem
              disabled={componentPayment.status !== 'INIT'}
              onClick={() =>
                setActionModal({
                  text: 'Force flag as failed',
                  description: 'Are you sure this is correct?',
                  action: flagAsFailed,
                })
              }
            >
              <ListItemIcon>
                <Warning />
              </ListItemIcon>
              <ListItemText>Force flag as failed</ListItemText>
            </MenuItem>
          )}
          <MenuItem
            onClick={() => openRefundModal(true)}
            disabled={paymentRefundDisabled}
          >
            <ListItemIcon>
              <CurrencyExchange />
            </ListItemIcon>
            <ListItemText>Force Refund (including fees)</ListItemText>
          </MenuItem>
          <MenuItem
            onClick={() => openRefundModal(false)}
            disabled={paymentRefundDisabled}
          >
            <ListItemIcon>
              <CurrencyExchange />
            </ListItemIcon>
            <ListItemText>Force Refund (without fees)</ListItemText>
          </MenuItem>
          <MenuItem
            onClick={() => renderSelectiveRefundModal(new Set(offerFeeTypes()))}
            disabled={paymentRefundDisabled}
          >
            <ListItemIcon>
              <CurrencyExchange />
            </ListItemIcon>
            <ListItemText>Force Refund (selective)</ListItemText>
          </MenuItem>
          <MenuItem
            disabled={componentPayment.status !== 'INIT'}
            onClick={() =>
              setActionModal({
                text: 'Cancel payment',
                action: cancelPayment,
              })
            }
          >
            <ListItemIcon>
              <Cancel />
            </ListItemIcon>
            <ListItemText>Cancel</ListItemText>
          </MenuItem>
          <MenuItem
            disabled={componentPayment?.status !== 'SETTLED'}
            onClick={() =>
              setActionModal({
                text: 'Resend receipt to the buyer',
                action: resendReceipt,
              })
            }
          >
            <ListItemIcon>
              <Send />
            </ListItemIcon>
            <ListItemText>Resend receipt</ListItemText>
          </MenuItem>
          <MenuItem
            disabled={componentPayment?.status !== 'SETTLED' || !['UNDEFINED', 'DECLINED'].includes(componentPayment?.payoutConsent)}
            onClick={() =>
              setActionModal({
                text: 'Force grant payout consent',
                action: grantPayoutConsent,
              })
            }
          >
            <ListItemIcon>
              <Check />
            </ListItemIcon>
            <ListItemText>Grant payout consent</ListItemText>
          </MenuItem>
          <MenuItem
            disabled={componentPayment?.payoutConsent !== 'CONFIRMED'}
            onClick={() =>
              setActionModal({
                text: 'Force revert payout consent',
                action: revertPayoutConsent,
              })
            }
          >
            <ListItemIcon>
              <Block />
            </ListItemIcon>
            <ListItemText>Revert payout consent</ListItemText>
          </MenuItem>
          <MenuItem
            color="error"
            disabled={forcePayoutDisabled}
            onClick={() => setForcePayoutModal(true)}
          >
            <ListItemIcon>
              <DoubleArrow />
            </ListItemIcon>
            <ListItemText>Force Payout</ListItemText>
          </MenuItem>
        </MenuList>
      </Menu>
      {actionModal.text && (
        <ActionConfirmationModal
          {...actionModal}
          close={() => setActionModal({ text: '' })}
        >
          {actionModal.textField && (
            <TextField
              id="comment-text"
              name="comment"
              fullWidth
              placeholder="Add a comment..."
              variant="outlined"
              sx={{ marginTop: 2 }}
            />
          )}
        </ActionConfirmationModal>
      )}
      {selectiveRefundModal.text && (
        <MultiActionModal
          {...selectiveRefundModal}
          close={() =>
            setSelectiveRefundModal({
              ...selectiveRefundModal,
              text: '',
            })
          }
        >
          <div>
            <a href="" onClick={selectiveRefundCheckAllFeesHandler}>
              all
            </a>
            ,{' '}
            <a href="" onClick={selectiveRefundCheckNoFeesHandler}>
              none
            </a>
          </div>
          {offerFeeTypes().map((feeType: string) => {
            const feeName: string =
              feeNames[feeType as keyof typeof feeNames] || feeType;
            return (
              <FormControlLabel
                id={`${feeType}-refund-checkbox`}
                name={`${feeType}-refund-checkbox`}
                key={`${feeType}-refund-checkbox`}
                label={`refund ${feeName} (${formatMoney(feeAmount(feeType))})`}
                control={
                  <Checkbox
                    checked={selectiveRefundModal.feesToRefund.has(feeType)}
                    onChange={(event) => {
                      let fees = selectiveRefundModal.feesToRefund;
                      if (event.target.checked) {
                        fees.add(feeType);
                      } else {
                        fees.delete(feeType);
                      }
                      renderSelectiveRefundModal(fees);
                    }}
                  />
                }
              />
            );
          })}
          {
            <Typography component="p" mb={0} mt={2}>
              {`total refunded amount: ${formatMoney(
                totalSelectiveRefundAmount(),
              )}`}
            </Typography>
          }
        </MultiActionModal>
      )}
      {forcePayoutModal && (
        <ForcePayoutModal
          paymentId={payment.id}
          onSuccess={(newPayment) =>
            setComponentPayment((prev) => ({ ...prev, ...newPayment }))
          }
          close={() => setForcePayoutModal(false)}
        />
      )}
    </>
  );
};

export default PaymentActions;

import { Button, Card, Tab, Tabs } from '@mui/material';
import { useState } from 'react';

import { Payment as PaymentModel } from '../../../api/PaymentApi';
import { Payout } from '../../../api/PayoutApi';
import { useOfferContext } from '../Offer';

import OfferPayments from './OfferPayments';
import OfferPayouts from './OfferPayouts';
import AddIcon from "@mui/icons-material/Add";
import CreateManualPaymentModal
  from "../../../components/CreateManualPaymentModal";
import { useAuthContext } from "../../../context/AuthContext";
import { Offer } from "../../../api/OfferApi";
import { useQuery } from 'react-query';
import { MerchantApi } from '../../../api/MerchantApi';
import OfferShipping from './OfferShipping';


export type PaymentWithPayouts = PaymentModel & {
  payouts: Payout[];
};

const OfferTabs = ({ offer, isShippingEnabled }: { offer: Offer, isShippingEnabled: Boolean }) => {
  const [tab, setTab] = useState<string>('payments');
  const { loading, paymentsWithPayouts, payouts, shippings } = useOfferContext();
  const [createManualPaymentModal, setCreateManualPaymentModal] = useState(false);
  const authContext = useAuthContext();
  const merchantApi = new MerchantApi();
  
  return (
    <Card style={{ marginTop: 25 }}>
      <Tabs value={tab} onChange={(e, val) => setTab(val)}>
        <Tab label="Payments" value="payments" />
        {isShippingEnabled && <Tab label="Shipping" value="shipping" />}
        <Tab label="Payouts" value="payouts" />
      </Tabs>
      {tab === 'payments' && ((
        <OfferPayments payments={paymentsWithPayouts ?? []} {...{ loading }} />
      ))}
      {tab === 'shipping' && ((
        <OfferShipping shippings={shippings ?? []} {...{ loading }} />
      ))}
      {tab === 'payments' && authContext.can('CreateSwishWebPayment') && (
        <Button
          variant="contained"
          startIcon={<AddIcon />}
          disabled={offer.status != 'AVAILABLE'}
          sx={{ margin: 2 }}
          onClick={() => setCreateManualPaymentModal(true)}
        >
          Create manual payment
        </Button>
      )}
      {createManualPaymentModal && (
        <CreateManualPaymentModal
          offerId={offer.id}
          onSuccess={() => {
          }}
          close={() => setCreateManualPaymentModal(false)}
        />
      )}
      {tab === 'payouts' && (
        <OfferPayouts payouts={payouts ?? []} {...{ loading }} />)
      }
    </Card>
  );
};

export default OfferTabs;

import { useState, useEffect } from 'react';
import { useParams } from 'react-router';
import { useQuery } from 'react-query';
import { CircularProgress } from '@mui/material';
import { Customer as CustomerModel, CustomerApi } from '../../api/CustomerApi';
import CustomerDetails from './components/CustomerDetails';
import CustomerTabs from './components/CustomerTabs';
import CustomerHeader from './components/CustomerHeader';
import CustomerLogs from './components/CustomerLogs';

export default function Customer() {
  const { customerId } = useParams<{ customerId: string }>();
  const [customer, setCustomer] = useState<CustomerModel | null>(null);
  const customerApi = new CustomerApi();

  const fetchCustomer = async () => {
    setCustomer(null);
    if (customerApi) {
      const c = await customerApi.getCustomer(customerId);
      setCustomer(c);
    }
  };

  useEffect(() => {
    fetchCustomer();
  }, [customerId]);

  const { data: blockedUser } = useQuery(
    ['blockedUser', customer?.nationalIdNumber],
    () => customerApi.getBlockedUser(customer?.nationalIdNumber ?? ''),
    {
      enabled:
        customer?.userType === 'AUTHENTICATED_USER' &&
        Boolean(customer?.nationalIdNumber),
    },
  );

  return (
    <>
      {!customer && <CircularProgress />}
      {customer && (
        <>
          <CustomerHeader
            customer={customer}
            blockedUser={blockedUser}
            onCustomerUpdate={(customer) => setCustomer(customer)}
          />
          <CustomerDetails
            customer={customer}
            onCustomerUpdate={(customer) => setCustomer(customer)}
          />
          <CustomerTabs customer={customer} />
          <CustomerLogs customerId={customerId} />
        </>
      )}
    </>
  );
}

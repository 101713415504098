import { createClient } from './client';

export interface UserResponse {
  authorizedForMerchants: Array<string>;
  groups: Array<string>;
  email: string;
  isSuperAdmin: boolean;
  ownMerchantId: string;
  uid: string;
  properRoles: { merchantId?: string; $type: string }[];
  inactive: Boolean;
}

export interface UserResponse2 {
  id: string;
  created_at: string;
  email: string;
  own_merchant: string;
}
export interface UserMerchantsResponse {
  merchants: Array<{ id: string; name: string }>;
}

export interface CreateUserRequest {
  merchant: string;
  email: string;
  password: string;
  access: Array<string>;
  superAdmin: boolean;
}

export interface CreateAgentRequest {
  email: string;
  groupId: string;
}

export interface Group {
  id: string;
  name: string;
  description: string;
  created_at: string;
}

export interface Role {
  merchantId: string;
  $type: string;
}

export enum RoleType {
  SuperAdmin = 'SuperAdmin',
  RockerAdmin = 'RockerAdmin',
  RockerAgent = 'RockerAgent',
  MerchantAdmin = 'MerchantAdmin',
  MerchantAgent = 'MerchantAgent',
}

export type Permissions = {
  [key: string]: { merchantId: string; $type: string }[];
};

// TODO rename to AgentApi
export class UserApi {
  client;

  constructor() {
    this.client = createClient();
  }

  // TODO
  // {
  //  users: UserResponse[];
  //  pageInfo: PageInfo;
  // }
  async getUsers(merchantId: string): Promise<UserResponse2[]> {
    const resp = await this.client.get(`/v1/back-office/admin/agents`, {
      headers: { 'X-MerchantId-List': [merchantId] },
    });
    return resp.data;
  }

  async getUser(userId: String): Promise<UserResponse> {
    const resp = await this.client.get(
      `/v1/back-office/admin/agents/${userId}/details`,
    );
    return resp.data as UserResponse;
  }

  async updateUserAccess(
    userId: String,
    payload: { access: Array<string>; superAdmin: boolean },
  ): Promise<UserResponse> {
    const resp = await this.client.put(
      `/v1/back-office/admin/agents/${userId}/access`,
      payload,
    );
    return resp.data as UserResponse;
  }

  async createUser(payload: CreateUserRequest): Promise<UserResponse> {
    const resp = await this.client.post(
      `/v1/back-office/admin/agents`,
      payload,
    );
    return resp.data as UserResponse;
  }

  async deleteUser(userId: String): Promise<void> {
    const resp = await this.client.delete(
      `/v1/back-office/admin/agents/${userId}`,
    );
    return resp.data;
  }

  async getUserMerchants(): Promise<UserMerchantsResponse> {
    const resp = await this.client.get(`/v1/back-office/merchants`);
    return resp.data;
  }

  async getUserPermittedOperations(): Promise<{
    permittedOperations: Permissions;
  }> {
    const resp = await this.client.get(
      `/v1/back-office/admin/permitted-operations`,
    );
    return resp.data;
  }

  async getGroups(): Promise<Group[]> {
    const resp = await this.client.get(`/v1/back-office/admin/groups`);
    return resp.data;
  }

  async getGroupsForMerchant(): Promise<Group[]> {
    const resp = await this.client.get(`/v1/back-office/admin/merchant/groups`);
    return resp.data;
  }

  async setUserRole(userId: String, data: Role): Promise<void> {
    const resp = await this.client.put(
      `/v1/back-office/admin/agents/${userId}/roles`,
      data,
    );
    return resp.data;
  }

  async deleteUserRole(userId: String, data: Role): Promise<void> {
    const resp = await this.client.delete(
      `/v1/back-office/admin/agents/${userId}/roles`,
      { data },
    );
    return resp.data;
  }

  async activateUserForMerchant(userId: String): Promise<void> {
    const resp = await this.client.put(
      `v1/back-office/admin/merchant/agents/${userId}/activate`,
    );
    return resp.data;
  }

  async inactivateUserForMerchant(userId: String): Promise<void> {
    const resp = await this.client.put(
      `v1/back-office/admin/merchant/agents/${userId}/inactivate`,
    );
    return resp.data;
  }

  async activateUser(userId: String): Promise<void> {
    const resp = await this.client.put(
      `v1/back-office/admin/agents/${userId}/activate`,
    );
    return resp.data;
  }

  async inactivateUser(userId: String): Promise<void> {
    const resp = await this.client.put(
      `v1/back-office/admin/agents/${userId}/inactivate`,
    );
    return resp.data;
  }
}

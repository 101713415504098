import { Checkbox, FormControlLabel } from '@mui/material';
import { useMutation, useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';
import { GroupApi } from '../../../api/GroupApi';
import Modal from '../../../components/Modal';
import { useNotificationContext } from '../../../context/NotificationContext';

const AVAILABLE_ROLES = [
  'SUPER_ADMIN',
  'ROCKER_ADMIN',
  'ROCKER_AGENT',
  'MERCHANT_ADMIN',
  'MERCHANT_AGENT',
  'COMPANY_AGENT',
];

const ModifyGroupRoleModal = ({
  currentRoles,
  close,
}: {
  currentRoles: any;
  close: () => void;
}) => {
  const { groupId } = useParams<{ groupId: string }>();
  const groupApi = new GroupApi();
  const queryClient = useQueryClient();
  const { setNotification } = useNotificationContext();

  const mutation = useMutation(
    ({
      add,
      groupId,
      roleId,
    }: {
      add: boolean;
      groupId: string;
      roleId: string;
    }) => groupApi.updateGroupRole({ add, groupId, roleId }),
    {
      onError: () => {
        setNotification({
          severity: 'error',
          message: 'Something went wrong. please try again or contact support',
        });
      },
      onSettled: () => {
        queryClient.invalidateQueries(['group', groupId]);
      },
    },
  );

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { checked, name } = event.target;
    mutation.mutate({ add: checked, groupId, roleId: name });
  };

  return (
    <Modal onClose={close}>
      <p>Modify Roles:</p>
      {AVAILABLE_ROLES.map((role) => (
        <div key={role}>
          <FormControlLabel
            name={role}
            label={role}
            control={
              <Checkbox
                checked={currentRoles.includes(role)}
                onChange={handleChange}
              />
            }
          />
        </div>
      ))}
    </Modal>
  );
};

export default ModifyGroupRoleModal;

import { Checkbox, FormControlLabel } from '@mui/material';
import { useMutation, useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';
import { GroupApi } from '../../../api/GroupApi';
import Modal from '../../../components/Modal';
import { useAuthContext } from '../../../context/AuthContext';
import { useNotificationContext } from '../../../context/NotificationContext';

const ModifyGroupMerchants = ({
  currentMerchantIds,
  close,
}: {
  currentMerchantIds: String[];
  close: () => void;
}) => {
  const groupApi = new GroupApi();
  const { groupId } = useParams<{ groupId: string }>();
  const queryClient = useQueryClient();
  const { setNotification } = useNotificationContext();
  const {
    user: { merchants },
  } = useAuthContext();

  const mutation = useMutation(
    ({
      add,
      groupId,
      merchantId,
    }: {
      add: boolean;
      groupId: string;
      merchantId: string;
    }) => groupApi.updateGroupMerchant({ add, groupId, merchantId }),
    {
      onError: () => {
        setNotification({
          severity: 'error',
          message: 'Something went wrong. please try again or contact support',
        });
      },
      onSettled: () => {
        queryClient.invalidateQueries(['groupMerchants', groupId]);
      },
    },
  );

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { checked, name } = event.target;
    mutation.mutate({ add: checked, groupId, merchantId: name });
  };

  return (
    <Modal onClose={close}>
      <p>Modify Merchants:</p>
      {merchants.map(({ id, name }) => (
        <div key={id}>
          <FormControlLabel
            id={id}
            name={id}
            label={name}
            control={
              <Checkbox
                checked={currentMerchantIds.includes(id)}
                onChange={handleChange}
              />
            }
          />
        </div>
      ))}
    </Modal>
  );
};

export default ModifyGroupMerchants;

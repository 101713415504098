import * as React from 'react';
import { Modal as MuiModal, Box, IconButton, SxProps } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const defaultStyle: SxProps = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 500,
  bgcolor: 'background.paper',
  boxShadow: 12,
  borderRadius: 2,
  p: 2,
};

type Props = {
  cancelButton?: boolean;
  onClose: () => void;
  style?: SxProps;
};

const Modal: React.FC<Props> = ({
  children,
  cancelButton = true,
  onClose,
  style,
}) => (
  <MuiModal open={true} onClose={onClose}>
    <Box sx={{ ...defaultStyle, ...style }}>
      {cancelButton && (
        <IconButton
          aria-label="close"
          style={{ position: 'absolute', top: '10px', right: '10px' }}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      )}
      {children}
    </Box>
  </MuiModal>
);

export default Modal;

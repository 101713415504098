import { Route, Switch } from 'react-router';
import { Toolbar, Box, Container } from '@mui/material';
import Payment from '../scopes/payment/Payment';
import PaymentList from '../scopes/payment/PaymentList';
import Offer from '../scopes/offer/Offer';
import OfferList from '../scopes/offer/OfferList';
import Customer from '../scopes/customer/Customer';
import CustomerList from '../scopes/customer/CustomerList';
import Agent from '../scopes/agent/Agent';
import Agents from '../scopes/agent/Agents';
import PrivateRoute from './PrivateRoute';
import Merchants from '../scopes/merchant/Merchants';
import Merchant from '../scopes/merchant/Merchant';
import ApiDocs from './ApiDocs';
import Groups from '../scopes/group/Groups';
import Group from '../scopes/group/Group';
// import Dashboard from '../scopes/dashboard/Dashboard';

const DeveloperGuide = () => {
  return (
    <div style={{ width: '100%', height: '100vh' }}>
      <iframe
        src='https://pay-test.rocker.com/docs/'
        style={{ width: '100%', height: '100%', border: 'none' }}
        title="Rocker Pay Technical Documentation"
      />
    </div>
  );
};

const Main = () => (
  <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
    <Toolbar />
    <Container maxWidth="xl">
      <Switch>
        <Route path="/customers/:customerId">
          <Customer />
        </Route>
        <Route path="/customers">
          <CustomerList />
        </Route>
        <Route path="/payments/:paymentId">
          <Payment />
        </Route>
        <Route path="/payments">
          <PaymentList />
        </Route>
        <Route path="/api-docs/merchant">
          <ApiDocs api="merchant" />
        </Route>
        <Route path="/api-docs/frontend">
          <ApiDocs api="frontend" />
        </Route>
        <Route path="/api-docs/backoffice">
          <ApiDocs api="back-office" />
        </Route>
        <Route path="/api-docs/guide">
          <DeveloperGuide />
        </Route>
        <PrivateRoute path="/agents/:userId">
          <Agent />
        </PrivateRoute>
        <PrivateRoute path="/agents">
          <Agents />
        </PrivateRoute>
        <PrivateRoute path="/groups/:groupId">
          <Group />
        </PrivateRoute>
        <PrivateRoute path="/groups">
          <Groups />
        </PrivateRoute>
        <PrivateRoute path="/merchants/:merchantId">
          <Merchant />
        </PrivateRoute>
        <PrivateRoute path="/merchants">
          <Merchants />
        </PrivateRoute>
        <Route path="/offers/:offerId">
          <Offer />
        </Route>
        <Route path={['/', '/offers']}>
          <OfferList></OfferList>
        </Route>
        {/* <Route path="/">
          <Dashboard />
        </Route> */}
      </Switch>
    </Container>
  </Box>
);

export default Main;

import { createClient } from './client';
import { MoneyMinor } from './shared';

export interface GoodwillPayoutRequest {
  userId: string;
  amount: MoneyMinor;
  phone: string;
  reason: string;
  comment?: string;
}

export interface GoodwillPayoutResponse {
  id: string;
}

export interface RichGoodwillPayoutResponse {
  id: string;
  amount: MoneyMinor;
  payoutMethod: string;
  payoutAccountName: string;
  status: string;
  errorMessage?: string;
  createdAt: string;
  updatedAt: string;
  errorCode?: string;
  providerErrorCode?: string
  apiUserEmail?: string;
  apiUserId: string;
  apiUserType: string;
}

export class GoodwillPayoutApi {
  client;

  constructor() {
    this.client = createClient();
  }

  async initiateSwishGoodwillPayout(payload: GoodwillPayoutRequest): Promise<GoodwillPayoutResponse> {
    const resp = await this.client.post(
      `/v1/back-office/admin/merchant/goodwill-payouts/swish`,
      payload,
    );
    return resp.data as GoodwillPayoutResponse;
  }

  async getGoodwillPayouts(userId: string): Promise<{ payouts: RichGoodwillPayoutResponse[] }> {
    const resp = await this.client.get(
      `/v1/back-office/admin/users/${userId}/goodwill-payouts`
    );
    return resp.data;
  }
}

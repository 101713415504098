import { Card, CardHeader, CardContent, Grid } from '@mui/material';
import { Company } from '../../../api/MerchantApi';

const MerchantCompanyInfo = ({ company }: { company: Company }) => (
  <Card style={{ marginTop: 25 }}>
    <CardHeader
      title="Company details:"
      titleTypographyProps={{ fontSize: 16 }}
    />
    <CardContent>
      <Grid container spacing={0.5}>
        <Grid item xs={3}>
          <b>Name</b>
        </Grid>
        <Grid item xs={9}>
          <span>{company.name}</span>
        </Grid>
        <Grid item xs={3}>
          <b>Address</b>
        </Grid>
        <Grid item xs={9}>
          <span>{company.address}</span>
        </Grid>
        <Grid item xs={3}>
          <b>Organization number</b>
        </Grid>
        <Grid item xs={9}>
          <span>{company.organizationNumber}</span>
        </Grid>
        <Grid item xs={3}>
          <b>VAT registration number</b>
        </Grid>
        <Grid item xs={9}>
          <span>{company.vatRegistrationNumber}</span>
        </Grid>
      </Grid>
    </CardContent>
  </Card>
);

export default MerchantCompanyInfo;

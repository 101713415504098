import { useParams } from 'react-router-dom';
import { useQueries, useQuery } from 'react-query';
import { Box, Card, CardContent, CircularProgress, Grid, Tab, Tabs } from '@mui/material';
import { MerchantApi } from '../../api/MerchantApi';
import MerchantInfo from './components/MerchantInfo';
import MerchantOfferInfo from './components/MerchantOfferInfo';
import MerchantServiceFeeInfo from './components/MerchantServiceFeeInfo';
import MerchantPaymentMethods from './components/MerchantPaymentMethods';
import MerchantShippingMethods from './components/MerchantShippingMethods';
import MerchantHeader from './components/MerchantHeader';
import MerchantPayoutMethods from './components/MerchantPayoutMethods';
import MerchantGoodwillPayoutMethods from './components/MerchantGoodwillPayoutMethods';
import MerchantCompanyInfo from './components/MerchantCompanyInfo';
import MerchantAntiFraudInfo from './components/MerchantAntiFraudInfo';
import MerchantSettingsInfo from './components/MerchantSettingsInfo';
import MerchantEscrowInfo from './components/MerchantEscrowInfo';
import MerchantBackofficeCreateEntityPermission from './components/MerchantBackofficeCreateEntityPermission';
import MerchantAffiliates from './components/MerchantAffiliates';
import { useState } from 'react';

const LoadingCard = () => (
  <Card style={{ marginTop: 25, padding: 25 }}>
    <CircularProgress />
  </Card>
);

const Merchant = () => {
  const merchantApi = new MerchantApi();
  const { merchantId } = useParams<{ merchantId: string }>();
  const [selectedCurrencyTab, setSelectedCurrencyTab] = useState('SEK');

  const { data: merchantData, isLoading: isMerchantLoading } = useQuery(
    ['merchant', merchantId],
    () => merchantApi.getMerchant(merchantId),
  );

  const { data: merchantConfigData, isLoading: isMerchantConfigLoading } =
    useQuery(['merchantConfig', merchantId], () =>
      merchantApi.getMerchantConfig(merchantId),
    );
  
  const {
    data: merchantAffiliatesData,
    isLoading: isMerchantAffiliatesLoading,
  } = useQuery(
    ['merchantAffiliates', merchantId],
    () => merchantApi.getMerchantAffiliates(merchantId)
  );

  const {
    data: merchantShippingMethodsData,
    isLoading: isMerchantShippingMethodsLoading,
  } = useQuery(
    ['merchantShippingMethods', merchantId],
    () => merchantApi.getMerchantShippingMethods(merchantId)
    );
  
  const merchantCurrencies = merchantConfigData?.currencies ?? [];
  
  const paymentMethodsQueries = useQueries(
    merchantCurrencies.map(currency => ({
      queryKey: ['merchantPaymentMethods', merchantId, currency.offerMinPrice.currency],
      queryFn: () => merchantApi.getMerchantPaymentMethods(merchantId, currency.offerMinPrice.currency),
      enabled: !!merchantConfigData
    }))
  );
  const isPaymentMethodsQueriesLoading = isMerchantConfigLoading || paymentMethodsQueries.some(query => query.isLoading);

  const payoutMethodsQueries = useQueries(
    merchantCurrencies.map(currency => ({
      queryKey: ['merchantPayoutMethods', merchantId, currency.offerMinPrice.currency],
      queryFn: () => merchantApi.getMerchantPayoutMethods(merchantId, currency.offerMinPrice.currency),
      enabled: !!merchantConfigData
    }))
  );
  const isPayoutMethodsQueriesLoading = isMerchantConfigLoading || payoutMethodsQueries.some(query => query.isLoading);

  const goodwillPayoutMethodsQueries = useQueries(
    merchantCurrencies.map(currency => ({
      queryKey: ['merchantGoodwillPayoutMethods', merchantId, currency.offerMinPrice.currency],
      queryFn: () => merchantApi.getMerchantGoodwillPayoutMethods(merchantId, currency.offerMinPrice.currency),
      enabled: !!merchantConfigData
    }))
  );
  const isGoodwillPayoutMethodsQueriesLoading = isMerchantConfigLoading || goodwillPayoutMethodsQueries.some(query => query.isLoading);

  const selectedCurrencyIndex = merchantCurrencies.findIndex(x => x.offerMinPrice.currency === selectedCurrencyTab)

  return (
    <Box>
      {!merchantData || isMerchantLoading ? (
        <LoadingCard />
      ) : (
        <>
            <MerchantHeader />
            <MerchantInfo merchant={merchantData} />
            <MerchantCompanyInfo company={merchantData.company} />
            {isMerchantConfigLoading ? (
              <LoadingCard />
            ) : (
                <>
                  {merchantConfigData && (
                    <>
                      {console.log(`index = ${selectedCurrencyIndex}`) }
                      <MerchantSettingsInfo config={merchantConfigData} />
                      {merchantConfigData.antiFraud && <MerchantAntiFraudInfo antiFraud={merchantConfigData.antiFraud} />}
                      {merchantConfigData.escrow && <MerchantEscrowInfo escrow={merchantConfigData.escrow} />}
                      {merchantCurrencies.length > 0 && <Card style={{ marginTop: 25 }}>
                        <Tabs value={selectedCurrencyTab} onChange={(e, value) => setSelectedCurrencyTab(value)}>
                          {merchantCurrencies.map(currency => (
                            <Tab label={currency.offerMinPrice.currency} value={currency.offerMinPrice.currency} />
                          ))}
                        </Tabs>
                        <CardContent>
                          <Grid container spacing={0.5}>
                            <Grid item xs={6}> <MerchantOfferInfo merchantCurrency={merchantCurrencies[selectedCurrencyIndex]} /> </Grid>
                            <Grid item xs={6}> <MerchantServiceFeeInfo merchantCurrency={merchantCurrencies[selectedCurrencyIndex]} /> </Grid>
                          </Grid>
                          {isPaymentMethodsQueriesLoading ? <LoadingCard /> : <MerchantPaymentMethods paymentMethods={paymentMethodsQueries[selectedCurrencyIndex].data?.paymentMethods ?? []} />}
                          {isPayoutMethodsQueriesLoading ? <LoadingCard /> : <MerchantPayoutMethods payoutMethods={payoutMethodsQueries[selectedCurrencyIndex].data?.payoutMethods ?? []} />}
                          {isGoodwillPayoutMethodsQueriesLoading ? <LoadingCard /> : <MerchantGoodwillPayoutMethods payoutMethods={goodwillPayoutMethodsQueries[selectedCurrencyIndex].data?.payoutMethods ?? []} merchantCurrency={selectedCurrencyTab} />}
                        </CardContent>
                      </Card>}
                      <MerchantBackofficeCreateEntityPermission permission={merchantConfigData?.backofficeCreateEntityPermission} />
                    </>
                  )}
                </>
            )}
            {isMerchantShippingMethodsLoading ? <LoadingCard /> : <MerchantShippingMethods shippingMethods={merchantShippingMethodsData?.shippingMethods ?? []} />}
            {isMerchantAffiliatesLoading ? <LoadingCard /> : <MerchantAffiliates affiliates={merchantAffiliatesData?.affiliates ?? []} />}
        </>
      )}
    </Box>
  );
};

export default Merchant;

import { Card, CardContent, Grid } from '@mui/material';
import { Merchant } from '../../../api/MerchantApi';
import formatDate from '../../../utils/format-date';

const MerchantInfo = ({ merchant }: { merchant: Merchant }) => (
  <Card style={{ marginTop: 25 }}>
    <CardContent>
      <Grid container spacing={0.5}>
        <Grid item xs={3}>
          <b>Name</b>
        </Grid>
        <Grid item xs={9}>
          <span>{merchant.name}</span>
        </Grid>
        <Grid item xs={3}>
          <b>ID</b>
        </Grid>
        <Grid item xs={9}>
          <span>{merchant.id}</span>
        </Grid>
        <Grid item xs={3}>
          <b>Created At</b>
        </Grid>
        <Grid item xs={9}>
          <span>{formatDate(merchant.createdAt)}</span>
        </Grid>
      </Grid>
    </CardContent>
  </Card>
);

export default MerchantInfo;

import { useState } from 'react';
import { Button, Card, CardContent, Grid, Skeleton } from '@mui/material';
import { useQuery } from 'react-query';
import { GroupApi, GroupDetails } from '../../../api/GroupApi';
import { useParams } from 'react-router-dom';
import ModifyGroupRoleModal from './ModifyGroupRoleModal';

const GroupInfo = () => {
  const [modifyRoles, setModifyRoles] = useState(false);
  const groupApi = new GroupApi();
  const { groupId } = useParams<{ groupId: string }>();

  const { data: groupData, isLoading: isGroupLoading } = useQuery(
    ['group', groupId],
    () => groupApi.getGroup(groupId),
  );

  if (isGroupLoading)
    return <Skeleton variant="rectangular" width={'100%'} height={150} />;

  const group = groupData ?? ({} as GroupDetails);

  return (
    <>
      <Card style={{ marginTop: 25 }}>
        <CardContent>
          <Grid container spacing={0.5}>
            <Grid item xs={3} md={2}>
              <b>Name:</b>
            </Grid>
            <Grid item xs={9} md={10}>
              {group.name}
            </Grid>
            <Grid item xs={3} md={2}>
              <b>Members:</b>
            </Grid>
            <Grid item xs={9} md={10}>
              {group.members.length}
            </Grid>
            <Grid item xs={3} md={2}>
              <b>Roles:</b>
            </Grid>
            <Grid item xs={9} md={10}>
              {group.roles.map((role, i) => (
                <span key={role}>
                  {i > 0 ? ', ' : ''}
                  {role}
                </span>
              ))}
            </Grid>
            <Grid item xs={12}>
              <hr
                style={{
                  height: 1,
                  background: 'rgba(224, 224, 224, 1)',
                  border: 'none',
                }}
              />
              <Button
                variant="contained"
                size="small"
                onClick={() => setModifyRoles(true)}
              >
                Modify Roles
              </Button>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      {modifyRoles && (
        <ModifyGroupRoleModal
          currentRoles={group.roles}
          close={() => setModifyRoles(false)}
        />
      )}
    </>
  );
};

export default GroupInfo;

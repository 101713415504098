import {useState} from 'react';
import {
  Button,
  Typography,
  TextField,
} from '@mui/material';
import {useNotificationContext} from '../context/NotificationContext';
import Modal from './Modal';
import {LoadingButton} from "@mui/lab";
import {ManualPaymentRequest, Payment, PaymentApi} from "../api/PaymentApi";

type Props = {
  offerId: string;
  onSuccess: (payment: Payment) => void;
  close: () => void;
};

const emptyCreateRequestBody: ManualPaymentRequest = {
  offerId: "",
  buyerId: "",
  paymentMethod: "SWISH",
  paymentMethodData: {
    $type: "Swish",
    paymentType: "WEB"
  }
}

const CreateManualPaymentModal = ({offerId, onSuccess, close}: Props) => {

  const [createRequestBody, setCreateRequestBody] = useState<ManualPaymentRequest>({
    ...emptyCreateRequestBody,
    offerId: offerId,
  });
  const [loading, setLoading] = useState<boolean>(false);
  const {setNotification} = useNotificationContext();
  const paymentApi = new PaymentApi();

  const updateRequestBody = (evt: any) => {
    const {name, value} = evt.target;
    setCreateRequestBody((u: ManualPaymentRequest) => ({...u, [name]: value}));
  };

  const createPayment = async (evt: any) => {
    evt.preventDefault();
    setLoading(true);

    if (!evt.target.reportValidity()) {
      setLoading(false);
      return;
    }

    try {
      const payment = await paymentApi.createManualPayment(createRequestBody);

      setNotification({
        severity: 'success',
        message: 'Payment was started successfully - now the customer has to do their part (for example open swish app and accept the payment)',
      });
      onSuccess(payment);
      close();
    } catch (e: any) {
      const errorCode = e.response?.data?.errorCode;
      const errorMessage = e.response?.data?.message;
      const message = `Failed create payment manually due to: ${errorCode && "/" || ""}${errorMessage}`;

      setNotification({
        severity: 'error',
        message,
      });
    }
    setLoading(false);
  };

  return (
    <Modal onClose={close}>
      <form onSubmit={createPayment}>
        <Typography component="h2" fontSize={23}>
          Create manual swish payment
        </Typography>
        <Typography component="h2" fontSize={16}>
          Ask customer to open swish app before clicking 'Initiate'.
          WARNING: This feature pushes a payment request directly to the user's swish app,
          and should only be used if the customer has exhausted all other methods
          for making a payment.
        </Typography>
        <TextField
          id="buyerId"
          label="Buyer (copy the buyer ID here)"
          type="text"
          name="buyerId"
          required={true}
          fullWidth
          margin="dense"
          onChange={updateRequestBody}
          value={createRequestBody.buyerId}
        />
        <Typography component="h2" fontSize={16}>
          Delegate payer (if other than the user id above):
        </Typography>
        <TextField
          id="delegatePayerAlias"
          label="phone number"
          type="text"
          name="delegatePayerAlias"
          required={false}
          fullWidth
          margin="dense"
          onChange={updateRequestBody}
          value={createRequestBody.delegatePayerAlias}
        />
        <TextField
          id="delegatePayerSsn"
          label="national id number"
          type="text"
          name="delegatePayerSsn"
          required={false}
          fullWidth
          margin="dense"
          onChange={updateRequestBody}
          value={createRequestBody.delegatePayerSsn}
        />

        <LoadingButton
          type="submit"
          variant="contained"
          color="primary"
          loading={loading}
          fullWidth
          sx={{mt: 2}}
        >
          Initiate
        </LoadingButton>
        <Button variant="outlined" fullWidth sx={{mt: 1}} onClick={close}>
          Cancel
        </Button>
      </form>
    </Modal>
  );
};

export default CreateManualPaymentModal;

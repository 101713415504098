import { Link } from 'react-router-dom';
import { Alert, Grid, Card, CardHeader, CardContent } from '@mui/material';
import { styled } from '@mui/material/styles';
import formatMoney from '../../../utils/format-money';
import formatDate from '../../../utils/format-date';
import { Payment } from '../../../api/PaymentApi';

const GridTitle = styled(Grid)({
  display: 'flex',
  justifyContent: 'flex-end',
  textAlign: 'right',
});

const PaymentDetails = ({ payment }: { payment: Payment }) => (
  <Card style={{ marginTop: 25 }}>
    <CardHeader title="Payment Details" />
    <CardContent>
      {payment?.errorMessage && (
        <Alert severity="error" sx={{ mb: 2 }}>
          Payment failed: {payment.errorMessage}
        </Alert>
      )}
      <Grid container spacing={1} columnSpacing={2}>
        <GridTitle item xs={1}>
          Offer:
        </GridTitle>
        <Grid item xs={11}>
          <Link to={`/offers/${payment.offer.id}`}>
            {payment.offer.title} ({payment.offer.id})
          </Link>
        </Grid>
        <GridTitle item xs={1}>
          Seller:
        </GridTitle>
        <Grid item xs={11}>
          <Link to={`/customers/${payment.seller.id}`}>
            {payment.seller.name} ({payment.seller.email})
          </Link>
        </Grid>
        <GridTitle item xs={1}>
          Buyer:
        </GridTitle>
        <Grid item xs={11}>
          <Link to={`/customers/${payment.buyer.id}`}>
            {payment.buyer.name} ({payment.buyer.email})
          </Link>
        </Grid>
        <GridTitle item xs={1}>
          Price:
        </GridTitle>
        <Grid item xs={11}>
          {formatMoney(payment.amount)}
        </Grid>
        <GridTitle item xs={1}>
          Status:
        </GridTitle>
        <Grid item xs={11}>
          {payment.status}
        </Grid>
        <GridTitle item xs={1}>
          Payout Consent:
        </GridTitle>
        <Grid item xs={11}>
          {payment.payoutConsent}
        </Grid>
        <GridTitle item xs={1}>
          Payment method:
        </GridTitle>
        <Grid item xs={11}>
          {payment.paymentMethod}
        </Grid>
        <GridTitle item xs={1}>
          Default Refund Policy:
        </GridTitle>
        <Grid item xs={11}>
          {payment.serviceFeeRefundable ? 'Refund (including fees)' : 'Refund (without fees)'}
        </Grid>
        <GridTitle item xs={1}>
          Created:
        </GridTitle>
        <Grid item xs={11}>
          {formatDate(payment.createdAt)}
        </Grid>
        <GridTitle item xs={1}>
          Updated:
        </GridTitle>
        <Grid item xs={11}>
          {formatDate(payment.updatedAt)}
        </Grid>
      </Grid>
    </CardContent>
  </Card>
);

export default PaymentDetails;

import {
  AppBar as MuiAppBar,
  Toolbar,
  Typography,
  Select,
  SelectChangeEvent,
  MenuItem,
  IconButton,
} from '@mui/material';
import { ExitToApp } from '@mui/icons-material';
import { useAuthContext } from '../context/AuthContext';
import { ReactComponent as RLogo } from '../assets/icons/r-logo.svg';

const AppBar = () => {
  const {
    merchantId,
    user: { merchants },
    handleMerchantId,
    logout,
  } = useAuthContext();

  return (
    <MuiAppBar
      position="fixed"
      sx={{
        zIndex: (theme) => theme.zIndex.drawer + 1,
        backgroundColor: '#38355A',
      }}
    >
      <Toolbar>
        <RLogo />
        <Typography
          ml={'8px'}
          component="h1"
          variant="h6"
          color="#FFD5AF"
          noWrap
        >
          Pay Admin
        </Typography>
        <div style={{ marginLeft: 'auto' }}>
          {merchants.length > 1 && (
            <Select
              style={{ color: 'white' }}
              id="merchantId"
              value={merchantId}
              onChange={(e: SelectChangeEvent) =>
                handleMerchantId(e.target.value as string)
              }
            >
              {merchants.map(({ id, name }: { id: string; name: string }) => (
                <MenuItem key={id} value={id}>
                  {name}
                </MenuItem>
              ))}
            </Select>
          )}
          <IconButton aria-label="logout" onClick={logout} sx={{ ml: '24px' }}>
            <ExitToApp sx={{ color: '#C2C5E0' }} />
          </IconButton>
        </div>
      </Toolbar>
    </MuiAppBar>
  );
};

export default AppBar;

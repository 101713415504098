import { MoneyMinor } from '../api/shared';

const locale = 'sv-SE';

const formatMoney = (money: MoneyMinor) => {
  const formatter = new Intl.NumberFormat(locale, {
    style: 'currency',
    currency: money.currency,
    minimumFractionDigits: 0,
  });
  return formatter.format(money.amount / 100);
};

export default formatMoney;

import { useState } from 'react';
import {
  ListItemButton,
  ListItemText,
  Collapse,
  List,
  Button,
} from '@mui/material';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import formatDate from '../utils/format-date';
import { AuditLogUser } from '../api/shared';
import { useAuthContext } from '../context/AuthContext';

type Props = {
  title: string;
  author: AuditLogUser;
  time: string;
  data: any;
  traceLogsExplorerUrl?: string;
};

const LogEntry = ({
  title,
  author,
  time,
  data,
  traceLogsExplorerUrl,
}: Props) => {
  const [open, setOpen] = useState(false);

  const {
    user: { isSuperAdmin },
  } = useAuthContext();

  return (
    <>
      <ListItemButton onClick={() => setOpen(!open)}>
        <ListItemText
          primary={title}
          secondary={`${author?.email ? `${author.email} ` : ''}(${
            author?.userIdType ? author?.userIdType : 'SYSTEM_JOB'
          }) - ${formatDate(time)}`}
        />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      <Collapse in={open} timeout="auto" unmountOnExit>
        {isSuperAdmin && (
          <a rel="noreferrer" href={traceLogsExplorerUrl ?? ''} target="_blank">
            <Button variant="text">Go to google logs explorer</Button>
          </a>
        )}
        <List component="div" disablePadding>
          <pre>{JSON.stringify(data, null, 2)}</pre>
        </List>
      </Collapse>
    </>
  );
};

export default LogEntry;

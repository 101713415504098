import { Offer } from '../../../api/OfferApi';
import { Grid } from '@mui/material';
import { styled } from '@mui/material/styles';
import * as React from 'react';

const GridTitle = styled('div')`
  display: flex;
  justify-content: flex-end;
  text-align: right;
`;

const ExternalDetailsSection = ({ offer }: { offer: Offer }) => {
  const formatExternalData = (value: any) => {
    if (value.startsWith('http'))
      return (
        <a href={value} rel="noopener noreferrer" target="_blank">
          {value}
        </a>
      );
    return value;
  };

  return (
    <>
      <Grid container xs={12} sx={{ mt: 3, mb: 3 }}>
        <hr
          style={{
            position: 'absolute',
            width: '100%',
            height: '1px',
            left: 0,
            margin: 0,
            background: '#D4D6EA',
            border: 'none',
          }}
        />
      </Grid>
      <Grid container xs={12} rowSpacing={0.5} columnSpacing={1}>
        {Object.entries(offer.externalData)
          .sort(([k, v], [k2, v2]) => k.localeCompare(k2))
          .map(([k, v]) => (
            <React.Fragment key={k}>
              <Grid item xs={2}>
                <GridTitle>{k}:</GridTitle>
              </Grid>
              <Grid item xs={10}>
                {formatExternalData(v)}
              </Grid>
            </React.Fragment>
          ))}
      </Grid>
    </>
  );
};

export default ExternalDetailsSection;
